import React from 'react';
// import QuikStaysLogo from '../../../assets/images/logo.png';
// import { withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import Snackbar from '@material-ui/core/Snackbar';
import Paper from "@material-ui/core/Paper";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import { connect } from 'react-redux';
import { changePassword, changePasswordReset } from '../../../store/actions/changePasswordActions';

function UpdatePassword(props){
	const [values, setValues] = React.useState({
		password: "",
		confirmPassword: "",
	});

	// const [authenticationToken, setAuthenticationToken] = useState(null);
	// const [isLoggedIn, setLoggedInStatus] = useState(false);

	function validateForm() {
		return values.password.length > 7 && values.confirmPassword.length > 7;
	}

	function handleSnackBarClose() {
		props.changePasswordReset()
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};
	const handleClickShowConfirmPassword = () => {
		setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const handleMouseDownConfirmPassword = (event) => {
		event.preventDefault();
	};

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let data = {
			password: values.password,
			password_confirmation: values.confirmPassword
		}

		// console.log('dataNew', data);

		if(data.password !== "" && data.confirmPassword !== ""){
			props.changePassword(data)
		}
	}

	// React.useEffect(() => {
	//    if(props.isRequestSuccess || props.isThereError || (props.isLoading === false) ){
	      
	//    } else {
	      
	//    }
	   
	//    // eslint-disable-next-line
	// }, [props.isRequestSuccess, props.isThereError]);

	return (
		<div>
			{/*<div className="text-center mb-2">
				<img src={QuikStaysLogo} className="img-fluid" style={{height: '60px'}} alt="quikstays-logo" />
			</div>*/}
			<Paper elevation={0} className="p-2 text-center mb-3">
				<Typography variant="h5">Update Password</Typography>
			</Paper>
			<form onSubmit={handleSubmit}>
				<div className="form-group mb-3">
					<FormControl variant="outlined" fullWidth>
						<InputLabel htmlFor="password">Password</InputLabel>
						<OutlinedInput
							id="password"
							type={values.showPassword ? 'text' : 'password'}
							value={values.password}
							onChange={handleChange('password')}
							required
							endAdornment={
								<InputAdornment position="end">
									<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
									>
									{values.showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							labelWidth={70}
						/>
					</FormControl>
				</div>
				<div className="form-group mb-3">
					<FormControl variant="outlined" fullWidth>
						<InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
						<OutlinedInput
							id="confirmPassword"
							type={values.showConfirmPassword ? 'text' : 'password'}
							value={values.confirmPassword}
							onChange={handleChange('confirmPassword')}
							required
							endAdornment={
								<InputAdornment position="end">
									<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowConfirmPassword}
									onMouseDown={handleMouseDownConfirmPassword}
									edge="end"
									>
									{values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							labelWidth={70}
						/>
					</FormControl>
				</div>
				<div className="form-group">
					{/* { props.isThereError && <Alert severity="error">{ props.errorMessage }</Alert> } */}
					<Button
						type="submit"
						className="mt-2"
						variant="contained"
						color="secondary"
						fullWidth
						size="large"
						disabled={!validateForm() || props.isLoading}
						>
							<Typography color="primary" variant="contained">{props.isLoading && <CircularProgress size={20} />} {props.isLoading ? "Updating..." : "Update" }</Typography>
					</Button>
				</div>
			</form>
			 <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handleSnackBarClose}
                    open={props.isRequestSuccess && (props.isLoading === false)}
                    autoHideDuration={2000}
                    // onClose={()=> this.setState({ isRequestLoading: false })}
                    >
                    <Alert onClose={handleSnackBarClose} variant="filled" severity="success">{ 'Password changed successfully' }</Alert>
			</Snackbar>
			 <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={props.isThereError }
                    autoHideDuration={2000}
                    onClose={handleSnackBarClose}
                    >
                    <Alert onClose={handleSnackBarClose} variant="filled" severity="error">{ props.errorMessage  }</Alert>
                </Snackbar>
		</div>
	);
}
// }

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (data) => dispatch(changePassword(data)),
        changePasswordReset: (data) => dispatch(changePasswordReset()),
    }
}

const mapStateToProps  = (state) => {
    return {
      token: state.changePassword.token,
      isThereError: state.changePassword.error,
      isLoading: state.changePassword.loading,
      isRequestSuccess: state.changePassword.success,
      successMessage: state.changePassword.successMessage,
      errorMessage: state.changePassword.errorMessage
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);