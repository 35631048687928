import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_RESET } from '../types/hotelTypes';

const initState = {
    token: "",
    loading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: ""
}

const changePasswordReducer = (state = initState, action) => {
    switch(action.type){
        case CHANGE_PASSWORD_REQUEST:
            // console.log('LOGIN REQUEST ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        case CHANGE_PASSWORD_SUCCESS:
            // console.log('LOGIN SUCCESS ', action.payload);
            return {
                ...state,
                token: action.payload,
                loading: false,
                success: true,
                error: false,
                successMessage:  action.payload.message,
            }
        case CHANGE_PASSWORD_FAILURE:

            // console.log('LOGIN FAILURE ', action.payload);
            // console.log('LOGIN FAILURE ', action.payload.response.data.validations);
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                errorMessage: action.payload.response.data.validations.password[0]
            }
        case CHANGE_PASSWORD_RESET:
            // console.log('LOGIN FAILURE ', action.payload);
            // console.log('LOGIN FAILURE ', action.payload.response.data.validations);
            return {
                ...state,
                loading: false,
                success: false,
                error: false,
            }
        default:
            return state;
    }
}

export default changePasswordReducer;