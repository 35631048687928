import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
// import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import QuikStaysLogo from '../../assets/images/logo.png';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import BankDetails from './BankDetails';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CreateIcon from '@material-ui/icons/Create';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HotelInformation from './HotelInformation';
import Graphs from './Graphs';
import Availability from './availability/Availability';
import RoomsAndRates from './rooms-and-rates/RoomsAndRates';
import Bookings from './Bookings';
import AccountSettings from './account-settings/AccountSettings';
import { connect } from 'react-redux';
import { logout } from '../../store/actions/authActions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function DashboardIndex(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isDialogOpen, setDialogOpenState] = React.useState(false);

  const handleDialogOpen=()=>{
    setDialogOpenState(true);
  }
  const handleDialogClose=()=>{
    setDialogOpenState(false);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleLogout = () => {
    props.logout();
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Link to='/dashboard'>
            <div className="text-center pt-2">
              <img src={QuikStaysLogo} className="img-fluid" style={{width: '80%'}} alt="quikstays-logo" />
            </div>
        </Link>
      </div>
      <Divider />
      <List>
        {[{ icon: DashboardIcon, title: 'Dashboard', link: '/dashboard'}, { icon: CreateIcon, title: 'Hotel Information', link: '/dashboard/hotel-information'}].map((text, index) => (
          <Link to={text.link} key={index} className="text-decoration-none text-dark">
            <ListItem button>
              <text.icon className="mr-2" />
              <ListItemText primary={text.title} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        {[{ icon: MeetingRoomIcon, title: 'Room & Rates', link: '/dashboard/room-and-rates'}, { icon: EventIcon, title: 'Bookings', link: '/dashboard/bookings'}, { icon: EventAvailableIcon, title: 'Update room details', link: '/dashboard/availability'}].map((text, index) => (
          <Link to={text.link} key={index} className="text-decoration-none text-dark">
            <ListItem button>
              <text.icon className="mr-2" />
              <ListItemText primary={text.title} />
            </ListItem>
          </Link>
        ))}
        <ListItem button onClick={handleDialogOpen}>
          <AccountBalanceIcon className="mr-2" />
          <ListItemText primary="Bank Details" />
        </ListItem>
      </List>
      <Divider />
      <List>
        {[{icon: SettingsIcon, title:'Account Settings', link: '/dashboard/account-settings'}].map((text, index) => (
          <Link to={text.link} key={index} className="text-decoration-none text-dark">
            <ListItem button>
              <text.icon className="mr-2" />
              <ListItemText primary={text.title} />
            </ListItem>
          </Link>
        ))}
        <ListItem button onClick={handleLogout}>
          <ExitToAppIcon className="mr-2" color="secondary" />
          <ListItemText primary="Logout" className="secondaryTextColor" />
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            // color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            
          </Typography>
          <div className="ml-auto">
            <Button onClick={handleLogout} color="secondary">
              <ExitToAppIcon /> Logout
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
            <Switch>
                {/*<Route exact path="/" component={Graphs}></Route>*/}
                
                <Route exact path="/dashboard" component={Graphs}></Route>
                <Route path="/dashboard/hotel-information" component={HotelInformation}></Route>
                <Route path="/dashboard/room-and-rates" component={RoomsAndRates}></Route>
                <Route path="/dashboard/availability" component={Availability}></Route>
                <Route path="/dashboard/bookings" component={Bookings}></Route>
                <Route path="/dashboard/account-settings" component={AccountSettings}></Route>
            </Switch>
      </main>
          <Dialog open={isDialogOpen} onClose={handleDialogClose} aria-labelledby="Booking-Confirmation-Dialog">
              <DialogTitle className="primaryBgColor text-white">
                <div className="d-flex justify-content-between align-items-center my-0">
                  <Typography variant="h6">Hotel Bank Details</Typography>
                  <IconButton onClick={handleDialogClose} color="secondary">
                      <CloseIcon />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                  <BankDetails />
              </DialogContent>
          </Dialog>

    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      logout: () => dispatch(logout())
    }
}
export default connect(null,mapDispatchToProps)(DashboardIndex);