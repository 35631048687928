import { GET_HOTEL_REQUEST, GET_HOTEL_SUCCESS, GET_HOTEL_FAILURE } from '../types/hotelTypes';

const initState = {
    hotelInfo: [],
    hotelId: "",
    loading: false,
    error: false,
    errorMessage: ""
}

const hotelReducer = (state = initState, action) => {
    switch(action.type){
        case GET_HOTEL_REQUEST:
            // console.log('GET HOTEL ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        case GET_HOTEL_SUCCESS:
            // console.log('GET HOTEL SUCCESS ', action.payload);
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                hotelInfo: action.payload,
            }
        case GET_HOTEL_FAILURE:
            // console.log('GET HOTEL FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default hotelReducer;