import React, { useState } from 'react';
import QuikStaysLogo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Paper from "@material-ui/core/Paper";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import { connect } from 'react-redux';
import { setPassword, checkToken } from '../../store/actions/setPasswordActions';

function SetPassword(props){
	const [values, setValues] = useState({
		password: "",
		confirmPassword: "",
	});
	const [isPasswordDoesNotMatch, setPasswordDoesNotMatch] = useState(null);

	// const [isRequestLoading, setRequestLoadingState] = React.useState(null);
	// const [authenticationToken, setAuthenticationToken] = useState(null);
	// const [isLoggedIn, setLoggedInStatus] = useState(false);

	function validateForm() {
		return values.password.length > 7 && values.confirmPassword.length > 7;
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};
	const handleClickShowConfirmPassword = () => {
		setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
	};

	const handleMouseDownPassword = (event) => {
		// console.log(event)
		event.preventDefault();
	};
	const handleMouseDownConfirmPassword = (event) => {
		// console.log(event)
		event.preventDefault();
	};

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	React.useEffect(() => {
		props.checkToken(props.match.params.token);
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		if(values.password !== values.confirmPassword){
			setPasswordDoesNotMatch(true);
		} else {
			setPasswordDoesNotMatch(false);
		}
	}, [values.password, values.confirmPassword]);


	React.useEffect(() => {
	   if(props.isRequestSuccess || props.isThereError || (props.isLoading === false) ){

	   } else {
	   	
	   }
	}, [props.isRequestSuccess, props.isThereError, props.isLoading]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		let data = {
			password: values.password,
			password_confirmation: values.confirmPassword
		}

		if(data.password !== "" && data.password_confirmation !== ""){
			if(data.password !== data.password_confirmation){
				setPasswordDoesNotMatch(true);
			} else {
				props.setPassword(props.hotelId, data, props.match.params.token)
			}
		}
	}


	if(props.isTokenValid){
		return (
			<div className="container">
				<div className="row" style={{height:'100vh'}}>
					<div className="col-sm-12 col-md-2 col-lg-4 col-xl-4"></div>
					<div className="col-sm-12 col-md-8 col-lg-4 col-xl-4 my-auto">
						<div className="text-center mb-2">
							<img src={QuikStaysLogo} className="img-fluid" style={{height: '60px'}} alt="quikstays-logo" />
						</div>
						{
							(props.isTokenValid && !props.isRequestSuccess) &&
							<React.Fragment>
								<Paper elevation={0} className="p-2 text-center mb-3">
									<Typography variant="h5">Partners Password Setup</Typography>
								</Paper>
								<form onSubmit={handleSubmit}>
									<div className="form-group mb-3">
										<FormControl variant="outlined" fullWidth>
											<InputLabel htmlFor="password">Password</InputLabel>
											<OutlinedInput
												id="password"
												type={values.showPassword ? 'text' : 'password'}
												value={values.password}
												onChange={handleChange('password')}
												required
												endAdornment={
													<InputAdornment position="end">
														<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
														>
														{values.showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
												labelWidth={70}
											/>
										</FormControl>
									</div>
									<div className="form-group mb-3">
										<FormControl variant="outlined" fullWidth>
											<InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
											<OutlinedInput
												id="confirmPassword"
												type={values.showConfirmPassword ? 'text' : 'password'}
												value={values.confirmPassword}
												onChange={handleChange('confirmPassword')}
												required
												autoComplete="on"
												endAdornment={
													<InputAdornment position="end">
														<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowConfirmPassword}
														onMouseDown={handleMouseDownConfirmPassword}
														edge="end"
														>
														{values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
												labelWidth={70}
											/>
										</FormControl>
									</div>
									<div className="form-group">
										{ props.isThereError && <Alert severity="error">{ props.errorMessage }</Alert> }
										{ isPasswordDoesNotMatch && <Alert severity="warning">Password and Confirm Password doesn't match</Alert> }
										{ props.isRequestSuccess && <Alert severity="success">{ props.successMessage }</Alert> }
										<Button
											type="submit"
											className="mt-2"
											variant="contained"
											color="secondary"
											fullWidth
											size="large"
											disabled={!validateForm() || props.isLoading}
											>
												<Typography component="span" color="primary">{ props.isLoading && <CircularProgress size={20} />} { props.isLoading ? "Setting up password..." : "Setup Password" }</Typography>
										</Button>
									</div>
								</form>
							</React.Fragment>
						}

						{
							props.isRequestSuccess &&
							<React.Fragment>
								<Paper elevation={0} className="p-2 text-center mb-3">
									<Typography variant="h5">Password Setup Successful!</Typography>
								</Paper>
								<Link to="/login" className="text-decoration-none">
									<Button
										type="submit"
										className="mt-1"
										variant="contained"
										color="primary"
										fullWidth
										size="large"
										disableElevation
										>
											Proceed to Login
									</Button>
								</Link>
							</React.Fragment>
						}
					</div>
					<div className="col-sm-12 col-md-2 col-lg-4 col-xl-4"></div>
				</div>
			</div>
		);
	}
	else if(props.isCheckTokenLoading){
		return (
			<div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
				<div className="text-center">
					<CircularProgress />
					<Typography variant="h5" color="primary">Checking Validity...</Typography>
				</div>
			</div>
		)
	}
	else {
		return (
			<div className="d-flex justify-content-center align-items-center px-3" style={{ height: '100vh' }}>
				<div className="text-center">
					<Typography variant="h5" className="mb-3">Sorry, you are unauthorized to view this page.</Typography>
					<Typography variant="h5" className="mb-3">If this issue persists, kindly report to our team via <Typography color="primary" variant="h5" component="span">admin@quikstays.com</Typography></Typography>
					<Link to="/login">
						<Button variant="contained" disableElevation>Login Here</Button>
					</Link>
				</div>
			</div>
		)
	}
}
// }

const mapDispatchToProps = (dispatch) => {
    return {
        setPassword: (hotelId, data, token) => dispatch(setPassword(hotelId, data, token)),
        checkToken: (token) => dispatch(checkToken(token)),
    }
}

const mapStateToProps  = (state) => {
    return {
      isTokenValid: state.setPassword.isTokenValid,
      isCheckTokenLoading: state.setPassword.isCheckTokenLoading,
      isCheckTokenError: state.setPassword.isCheckTokenError,
      token: state.setPassword.token,
      isThereError: state.setPassword.error,
      isLoading: state.setPassword.loading,
      isRequestSuccess: state.setPassword.success,
      successMessage: state.setPassword.successMessage,
      errorMessage: state.setPassword.errorMessage
      // .response.data.validations
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);