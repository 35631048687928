import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS } from '../types/hotelTypes';

const initState = {
    hotelInfo: [],
    hotelId: "",
    loading: false,
    error: false,
    isLoggedIn: !!window.localStorage.getItem("user_token"),
    token: JSON.parse(window.localStorage.getItem("user_token")) || {},
    success: false,
    successMessage: "",
    errorMessage: ""
}

const authReducer = (state = initState, action) => {
    switch(action.type){
        case LOGIN_REQUEST:
            // console.log('LOGIN REQUEST ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        case LOGIN_SUCCESS:
            // console.log('LOGIN SUCCESS ', action.payload);
            window.localStorage.setItem("user_token", JSON.stringify(action.payload.data.token));
            window.localStorage.setItem("QuickstayhotelId", JSON.stringify(action.payload.data.partner.hotel));
            return {
                ...state,
                hotelInfo: action.payload.data,
                hotelId: action.payload.data._id,
                token: action.payload.data.token,
                success: true,
                isLoggedIn: true,
                error: false,
                successMessage:  action.payload.message,
            }
        case LOGIN_FAILURE:
            // console.log('LOGIN FAILURE ', action.payload);
            // console.log('LOGIN FAILURE ', action.payload.response.data.validations);
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                errorMessage: action.payload
            }
        case LOGOUT_SUCCESS:
            // console.log('LOGIN SUCCESS ', action.payload);
            window.localStorage.removeItem("user_token"); //remove one item
            window.localStorage.removeItem("QuickstayhotelId"); //remove one item
            return {
                ...state,
                isLoggedIn: false,
                hotelInfo: null,
                hotelId: null,
                loading: false,
                success: false,
                error: false,
                successMessage: "",
                token: "",
                errorMessage: "",
            }
        default:
            return state;
    }
}

export default authReducer;