import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
// import CircularProgress from '@material-ui/core/CircularProgress';
import AvailabilityDialog from './AvailabilityDialog';
import { connect } from 'react-redux';
import { updateRoomInfo } from '../../../store/actions/updateRoomInfoActions';
import { getHotel } from '../../../store/actions/hotelActions';
// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'

class Availability extends Component {
    state = {
        isDialogOpen: false,
        selectedRoom: "",
        selectedRoomData: "",
        selectedRoomId: "",
        dailyPrice: "",
        threeHoursPrice: "",
        sixHoursPrice: "",
        nineHoursPrice: "",
        twelveHoursPrice: "",
        isEditing: false,
    }
    componentDidMount(){
        this.props.getHotel(this.props.hotelId);
    }
    // componentDidUpdate=(prevProps)=>{
    //     if(this.props.isRequestSuccess || this.props.isThereError) {
    //         this.setState({ isRequestLoading: false });
    //     }
    // }

    changeRoom = (data) => {
        if(data !== ""){
            this.setState({ selectedRoom: data.type, selectedRoomData: data, isEditing: false });
        }
    }

    handleRatesChange = (e) =>{
        this.setState({ isEditing: true, [e.target.id]: e.target.value });
    }

    handleSubmit = async (e)=> {
        e.preventDefault();
        // this.setState({ isRequestLoading: true });


        let arrayOfRates = [];
        // console.log(this.props.hotelRooms)
        let selectedRoomId;
        let hoursPerDay;

        // get the id of the selected hotel room
        for(let i = 0; i <= this.props.hotelRooms.length; i++){
            if(this.props.hotelRooms[i].type === this.state.selectedRoom){
                selectedRoomId = this.props.hotelRooms[i]._id
                hoursPerDay = this.props.hotelRooms[i].hours_per_day
                // this.setState({ selectedRoomId: this.props.hotelRooms[i]._id });
                break;
            }
        }
        let roomRateToUpload = {
            id: selectedRoomId,
            daily_price: this.state.dailyPrice,
            total_hours: hoursPerDay,
            three_hrs_markup: this.state.threeHoursPrice,
            six_hrs_markup: this.state.sixHoursPrice,
            nine_hrs_markup: this.state.nineHoursPrice
        }
        arrayOfRates.push(roomRateToUpload);

        // let data = {
        //     hotel_id: this.props.hotelId,
        //     data: arrayOfRates,
        // };

        // await this.props.updateRoomInfo(data);
        
        // this.setState({ isRequestLoading: false });
    }

    handleDialogOpen=()=>{
        this.setState({ isDialogOpen: true }, () => {
            // this.resetRoomsStateInStore();
        });
    }
    handleDialogClose=()=>{
        this.setState({ isDialogOpen: false }, () => {
            // this.resetRoomsStateInStore();
        });
    }


    render() {
        const { hotel, hotelRooms } = this.props;
        console.log(this.state)
        const { isDialogOpen, selectedRoomData, selectedRoom } = this.state;

        return (
            <div className="container-fluid px-lg-5">
                <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <Typography variant="h5">Availability</Typography>
                        <Button variant="contained" onClick={this.handleDialogOpen} color="secondary" disableElevation>Update Availability</Button>
                    </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="row primaryBgColor ">
                        <Rooms hotel={hotel} rooms={hotelRooms} onChangeRoom={this.changeRoom} />
                        {
                            hotelRooms.map((item, index) => (
                                item.type === selectedRoom &&
                                <RoomRates key={index} roomDetail={item} handleRatesChange={ this.handleRatesChange } values = {this.state} />
                        ))}
                        {/*<div className="col-12 mb-3">
                            { isRequestSuccess && <div className="alert alert-success text-center">{ successMessage }</div> }
                            { isThereError && <div className="alert alert-danger text-center">{ errorMessage }</div> }
                            <Button variant="contained" fullWidth type="submit" disabled={isRequestLoading} color="secondary" disableElevation>{isRequestLoading && <CircularProgress size={24} />} {(isRequestLoading || isLoading) ? "Updating" : "Update Availability" }</Button>
                        </div>*/}
                    </div>
                </form>
                <div className="row">
                    {
                        hotelRooms.map((item, index) => (
                            item.type === selectedRoom &&
                            <AvailableTime key={index} roomDetail={item} />
                    ))}
                </div>
                <AvailabilityDialog selectedRoom={selectedRoomData} isOpen={isDialogOpen} handleDialogClose={this.handleDialogClose} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHotel: (id) => dispatch(getHotel(id)),
        updateRoomInfo: (id, data) => dispatch(updateRoomInfo(id, data)),
    }
}
  
const mapStateToProps  = (state) => {
    return {
      hotel: state.hotel.success && state.hotel.hotelInfo.data,
      hotelRooms: (state.hotel.hotelInfo.data && state.hotel.hotelInfo.data.rooms) || [],
      hotelId: state.hotel.success && state.hotel.hotelInfo.data._id,
      isThereError: state.updateRoomInfo.error,
      isLoading: state.updateRoomInfo.loading,
      isRequestSuccess: state.updateRoomInfo.success,
      successMessage: state.updateRoomInfo.successMessage,
      errorMessage: state.updateRoomInfo.errorMessage.message
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Availability);

// class CalenderList extends Component {
//     state = {  }
//     render() { 
//         return (
//             <div className="col-12">
//                 <div className="row mb-3">
//                     <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-between align-items-center">
//                         <div className="form-group">
//                             <label>Select a date</label>
//                             <input type="date" className="form-control" />
//                         </div>
//                         <div>
//                             <Button variant="outlined" color="primary">Check Availability</Button>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="row">
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
//                         <FullCalendar
//                             plugins={[ dayGridPlugin ]}
//                             initialView="dayGridMonth"
//                         />
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }




const timeList= ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];
class Rooms extends Component {
    state ={
        // roomTypes: ['Single Room', 'Double Room'],
    }

    handleChange = () => {

    }

    handleChangeRoom = (e)=> {
        this.props.onChangeRoom(e.target.value);
    }
    render() { 
        // const { roomTypes } = this.state;
        const { rooms } = this.props;
        // console.log(rooms)
        return (
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <Typography variant="h5" className="mb-2 text-white">Room</Typography>
                <div className="row mb-3">
                    <div className="col-12">
                        <FormControl size="small" fullWidth variant="outlined" className="rounded bg-light">
                            <TextField 
                                select
                                variant="outlined"
                                size="small" 										
                                label="Select Room Type"										
                                id="roomType"
                                name="roomType"
                                defaultValue=""
                                onChange={this.handleChangeRoom}
                                >
                                {rooms && rooms.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item.type}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </div>
                </div>
                {/*<div className="row">
                    <div className="col-6 mb-3">
                        <FormControl size="small" fullWidth variant="outlined" className="rounded bg-light">
                            <TextField 
                                select
                                variant="outlined"
                                size="small" 										
                                label="Loaded Rooms"										
                                id="loadedRoomType"
                                defaultValue=""
                                name="roomType"
                                onChange={this.handleChange}
                            >
                                {roomTypes.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </div>
                    <div className="col-6 mb-3">
                        <FormControl size="small" fullWidth variant="outlined" className="rounded bg-light">
                            <TextField 
                                select
                                variant="outlined"
                                size="small" 										
                                label="Sold Rooms"										
                                id="soldRoom"
                                defaultValue=""
                                name="soldRoom"
                                onChange={this.handleChange}
                            >
                                {roomTypes.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </div>
                    <div className="col-6 mb-3">
                        <FormControl size="small" fullWidth variant="outlined" className="rounded bg-light">
                            <TextField 
                                select
                                variant="outlined"
                                size="small" 										
                                label="Left to be sold"
                                id="leftToBeSold"
                                name="leftToBeSold"
                                defaultValue=""
                                onChange={this.handleChange}
                            >
                                {roomTypes.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </div>
                </div>*/}
            </div>
        );
    }
}


const RoomRates=({ roomDetail, handleRatesChange, values })=>{

    return (
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <Typography variant="h5" className="mb-2 text-white">Room Rates</Typography>
            <div className="row mb-3">
                <div className="col-12">
                <label className="text-white">Price per day</label>
                    <TextField
                        variant="outlined"
                        className="bg-light rounded"
                        type="number"
                        fullWidth
                        size="small"
                        // label="Per Day"
                        id="dailyPrice"
                        name="dailyPrice"
                        disabled
                        value={values.dailyPrice || roomDetail.daily_price  || 0}
                        onChange={handleRatesChange}
                        >
                    </TextField>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6 mb-3">
                    <label className="text-white">For 3 hours</label>
                    <TextField
                        variant="outlined"
                        className="bg-light rounded"
                        type="number"
                        fullWidth
                        size="small"                                        
                        // label="For 3 hours"
                        id="threeHoursPrice"
                        name="threeHoursPrice"
                        disabled
                        value={values.threeHoursPrice || roomDetail.prices.three_hrs.price || 0 }
                        onChange={handleRatesChange}
                        >
                    </TextField>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6 mb-3">
                    <label className="text-white">For 6 hours</label>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        className="bg-light rounded"
                        type="number"
                        size="small"                                        
                        // label="For 6 hours"
                        id="sixHoursPrice"
                        name="sixHoursPrice"
                        disabled
                        value={values.sixHoursPrice || roomDetail.prices.six_hrs.price || 0 }
                        onChange={handleRatesChange}
                    >
                    </TextField>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6 mb-3">
                    <label className="text-white">For 9 hours</label>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        type="number"
                        className="bg-light rounded"
                        size="small"                                        
                        // label="For 9 hours"
                        id="nineHoursPrice"
                        name="nineHoursPrice"
                        disabled
                        value={values.nineHoursPrice || roomDetail.prices.nine_hrs.price || 0 }
                        onChange={handleRatesChange}
                    >
                    </TextField>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6 mb-3">
                    <label className="text-white">For 12 hours</label>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        type="number"
                        className="bg-light rounded"
                        size="small"
                        // label="For 12 hours"
                        id="twelveHoursPrice"
                        name="twelveHoursPrice"
                        disabled
                        value={values.twelveHoursPrice || roomDetail.prices.twelve_hrs.price || 0 }
                        onChange={handleRatesChange}
                    >
                    </TextField>
                </div>
            </div>
        </div>
    )
}


class AvailableTime extends Component {
    render(){
        const { availability_start_time, availability_end_time } = this.props.roomDetail;

        return (
            <div className="col-12">
                <div className="row mb-3 mt-2">
                    <div className="col-8">
                        <Typography variant="h5">Times Availability</Typography>
                        {/*<Typography variant="caption">Set the time in the day to make your room types available for 2, 3, 6 or 9 respectively.</Typography>*/}
                    </div>
                </div>
                <Divider />
                <div className="row mt-2">
                    <div className="col-12 pt-3">
                        <div className="btn-group-toggle" data-toggle="buttons">
                            { timeList.map((item, index) => (
                                <label className={`btn ${availability_start_time === item && 'secondaryBgColor'} ${availability_end_time === item && 'secondaryBgColor'} btn-secondary mr-1 mb-1 active`} key={index}>
                                {item}
                                    <input type="checkbox" />
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}