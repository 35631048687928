import React, { useState } from 'react';
import axios from 'axios';
import QuikStaysLogo from '../../assets/images/logo.png';
// import { Redirect } from 'react-router-dom';
import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
// import Paper from "@material-ui/core/Paper";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { connect } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { getHotel } from '../../store/actions/hotelActions';

axios.defaults.headers.post['Content-Type'] = 'text/plain';

// let AUTH_TOKEN = localStorage.getItem("user_token");
// console.log(AUTH_TOKEN)
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

function SignIn(props){
	const [values, setValues] = useState({
		email: "",
		password: "",
	});

	const [isRequestLoading, setRequestLoadingState] = React.useState(null);
	// const [authenticationToken, setAuthenticationToken] = useState(null);
	// const [isLoggedIn, setLoggedInStatus] = useState(false);

	function validateForm() {
		return values.email.length > 3 && values.password.length > 7;
	}

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let data = {
			email:values.email,
			password: values.password
		}

		if(data.email !== "" && data.password !== ""){
			setRequestLoadingState(true);
			props.login(data);

			if(props.isThereError){
	        setRequestLoadingState(false);
	      }
		}
	}

	const fetchHotel = async () => {
		return await props.getHotel(props.response.partner.hotel);
	}

	React.useEffect(() => {
	   if(props.isLoggedIn){
	   	// console.log(props)
	      setRequestLoadingState(false);
	   	if(props.response && props.response.partner && props.response.partner.hotel) {
	   		(async function fireFetchHotel(){
	   			await fetchHotel();
	   			props.history.push('/dashboard');
	   		})();
	   		// return <Redirect to="/dashboard" />
	   	} else {
	   		props.history.push('/add-hotel');
	   		// return <Redirect to="/add-hotel" />
	   	}
	   } else if(props.isThereError) {
	      setRequestLoadingState(false);
	   } else if(props.isLoading === false){
	   	setRequestLoadingState(false);
	   }
	   setRequestLoadingState(false);
	   // eslint-disable-next-line
	}, [props.isLoggedIn, props.isThereError, props.isLoading]);

	return (
		<div className="container">
			<div className="row" style={{height:'100vh'}}>
				<div className="col-sm-12 col-md-2 col-lg-4 col-xl-4"></div>
				<div className="col-sm-12 col-md-8 col-lg-4 col-xl-4 my-auto">
					<div className="text-center mb-2">
						<img src={QuikStaysLogo} className="img-fluid" style={{height: '60px'}} alt="quikstays-logo" />
					</div>
					{/*<Paper elevation={0} className="p-2 text-center mb-3">
						<Typography variant="h5">Sign In</Typography>
					</Paper>*/}
					<form action="" onSubmit={handleSubmit}>
						<div className="form-group mb-3">
							<TextField
								onChange={handleChange('email')}
								variant="outlined"
								label="Email"
								id="email"
								value={values.email}
								className="d-block"
								fullWidth
								required
								InputProps={{
									startAdornment: (
									  <InputAdornment position="start">
										<AccountCircle />
									  </InputAdornment>
									),
								}}
							/>
						</div>
						<div className="form-group mb-3">
							<FormControl variant="outlined" fullWidth>
								<InputLabel htmlFor="password">Password</InputLabel>
								<OutlinedInput
									id="password"
									type={values.showPassword ? 'text' : 'password'}
									value={values.password}
									onChange={handleChange('password')}
									required
									endAdornment={
										<InputAdornment position="end">
											<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
											>
											{values.showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									}
									labelWidth={70}
								/>
							</FormControl>
						</div>
						<div className="form-group">
							{ props.isThereError && <Alert severity="error">{ props.errorMessage.response?.data.validations }</Alert> }
							<Button
								type="submit"
								className="mt-2 primaryColor"
								variant="contained"
								color="secondary"
								fullWidth
								size="large"
								disabled={!validateForm() || isRequestLoading || props.isLoading}
								>
								{isRequestLoading && <CircularProgress size={20} />} {(isRequestLoading || props.isLoading) ? "Signing In..." : "Sign In" }
							</Button>
						</div>
					</form>
				</div>
				<div className="col-sm-12 col-md-2 col-lg-4 col-xl-4"></div>
			</div>
		</div>
	);
}
// }

const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => dispatch(login(data)),
        getHotel: (id) => dispatch(getHotel(id)),
    }
}

const mapStateToProps  = (state) => {
   return {
      response: state.auth.hotelInfo,
      // hotelRooms: state.hotel.hotelInfo.data && state.hotel.hotelInfo.data.rooms,
      // hotelId: state.hotel.hotelInfo.data._id,
      isThereError: state.auth.error,
      isLoading: state.auth.loading,
      isLoggedIn: state.auth.isLoggedIn,
      successMessage: state.auth.successMessage,
      errorMessage: state.auth.error && state.auth.errorMessage
      // state.auth.errorMessage.response.data.validations
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);