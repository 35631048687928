import axios from 'axios';
import { GET_BOOKING_REQUEST, GET_BOOKING_SUCCESS, GET_BOOKING_FAILURE } from '../types/hotelTypes';

const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const getBookingRequest = () => {
    return {
        type: GET_BOOKING_REQUEST
    }
}

const getBookingSuccess = hotel => {
    return {
        type: GET_BOOKING_SUCCESS,
        payload: hotel
    }
}
const getBookingFailure = error => {
    return {
        type: GET_BOOKING_FAILURE,
        payload: error
    }
}

export const getBookings = (hotelId) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    return async (dispatch, getState) => {
        dispatch(getBookingRequest());
        return axios.get(`${BASE_URL}/partner`, {
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(getBookingSuccess(res.data));
            })
            .catch(error => {
                dispatch(getBookingFailure(error));
            })
    }
}