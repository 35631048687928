import React from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core';
import _ from 'lodash';
// import FormControl from '@material-ui/core/FormControl';
// import TextField from '@material-ui/core/TextField';
// import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
// import InputLabel from '@material-ui/core/InputLabel';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import Paper from '@material-ui/core/Paper';
// import MenuItem from '@material-ui/core/MenuItem';
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import UploadImage from './UploadImage';
import CircularProgress from '@material-ui/core/CircularProgress';
import RoomInfo from './RoomInfo';
// import RoomsList from './RoomsList';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import { getHotel } from '../../../../store/actions/hotelActions';
import { addRoom } from '../../../../store/actions/addRoomActions';
import { addCurrentRoomBeingEdited } from '../../../../store/actions/addRoomActions';

// function TabContainer(props) {
//     return (
//       <Typography component="div" className="p-2">
//         {props.children}
//       </Typography>
//     );
//   }


class AddRooms extends React.Component {
    state = {
        activeTabIndex: 1,
        roomType: "Single",
        noOfRooms: 1,
        roomImage: [],
        isRequestLoading: null,
        isRequestError: null,
        isRequestSuccess: null,
        roomsList: [],
        isTheSameRoomFound: false,
        isNoOfRoomsEmpty: false,
        isImageEmpty: false,
    }

    handleClearForm=()=> {
        this.setState({roomType: "", noOfRooms: 0, roomImage: [], isTheSameRoomFound: false })
    }

    componentDidMount(){
        this.props.getHotel(this.props.hotelId);
    }

    componentDidUpdate(prevProps){
        if(prevProps.hotelRooms !== this.props.hotelRooms){
            if(this.props.isRequestSuccess || this.props.isThereError){
                // console.log('new room found', this.props.hotelRooms)
                this.setState({
                    roomsList: this.props.hotelRooms,
                    isRequestLoading: false
                });
            }
        }
        if(prevProps.isRequestSuccess !== this.props.isRequestSuccess){
            if(this.props.isRequestSuccess || this.props.isThereError){
                this.props.getHotel(this.props.hotelId);
                this.setState({
                    isRequestLoading: false
                });
            }
        }
    }

    removeImage = (index) => {
        console.log(index);
        let newImages = _.cloneDeep(this.state.roomImage);
        if (newImages[index]) {
          newImages.splice(index, 1);
          this.setState({ roomImage: newImages}, ()=> {
            this.setState({ isImageEmpty: true });
          });
        }
    };

    handleSnackBarClose = () => {
        this.setState({
            isRequestSuccess: false, 
            isTheSameRoomFound: false, 
            isRequestError: false, 
            isNoOfRoomsEmpty: false,
            isRequestLoading: false, 
        })

    }
    
   
    handleRoomData = (e) => {
        if(e.target.id === "noOfRooms" && e.target.value >= 1){
            this.setState({ [e.target.name]: e.target.value });
            this.setState({ isNoOfRoomsEmpty: false });
        }
        this.setState({ [e.target.name]: e.target.value })
    }

    uploadRoomImages = (e) => {
        const  { roomImage } = this.state;
        const newImages = _.cloneDeep(roomImage);

         for (let i = 0; i < e.target.files.length; i++) {
            newImages.push(e.target.files[i])
        }
       
        this.setState({ roomImage: newImages}, ()=> {
          this.setState({ isImageEmpty: false });
        });
    }

    handleOnDragOver = e => {
        e.preventDefault();
    }

    handleOnDrop = e => {
          //prevent the browser from opening the image
          const  { roomImage } = this.state;
          const newImages = _.cloneDeep(roomImage);

      
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            newImages.push(e.dataTransfer.files[i])
        }
          
          e.preventDefault();
          e.stopPropagation();
          this.setState({ roomImage: newImages }, ()=> {
            this.setState({ isImageEmpty: false });
          });
    }

    handleDisplayRoom = (roomType) => {
        // console.log(roomType);
    }
    
    handleSubmit = async (e) =>{
        e.preventDefault();
        if (this.state.roomImage.length === 0){
          this.setState({ isImageEmpty: true });
          return;
        }

        if(this.state.noOfRooms <= 0 || this.state.noOfRooms === ""){
            this.setState({ isNoOfRoomsEmpty: true });
            return;
        }

        this.setState({ isRequestLoading: true });

        await this.props.getHotel(this.props.hotelId);
        
        for(let i = 0; i < this.props.hotel.rooms.length; i++) {
            if(this.props.hotel.rooms[i].type === this.state.roomType){
                this.setState({ isTheSameRoomFound: true })
                return;
            }
        }
        this.setState({ isTheSameRoomFound: false })
        const formData = new FormData();
        formData.append('type', this.state.roomType);
        formData.append('count', this.state.noOfRooms);
        formData.append('hotel_id', this.props.hotelId);
        for (let i = 0; i < this.state.roomImage.length; i++) {
            formData.append(`images[${i}]`, this.state.roomImage[i])
        }

        await this.props.addRoom(formData);
        this.props.addCurrentRoomBeingEdited(this.state.roomType);

        if(this.props.isThereError){
            this.setState({isRequestLoading: false, isRequestError: true})
         
        }

        if(this.props.isRequestSuccess) {
            this.setState({
            isRequestLoading: false, 
            isRequestSuccess: true, 
          })
        }
       
    }

    render(){
        const { roomImage, roomType, noOfRooms, roomsList, isTheSameRoomFound, isRequestLoading, isNoOfRoomsEmpty, isImageEmpty, isRequestSuccess, isRequestError } = this.state;
        const { errorMessage, successMessage } = this.props;

        return (
            <div className="container px-lg-5">
                <form onSubmit={this.handleSubmit}>
                    <div className="row align-items-center mb-3">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <Typography color="secondary" variant="h5">Add Rooms</Typography>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                            <Button variant="contained" onClick={this.handleClearForm} color="secondary" size="small" disableElevation>Reset Form</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <RoomInfo handleRoomData = { this.handleRoomData } roomType={ roomType } noOfRooms = { noOfRooms } roomsList = { roomsList } isTheSameRoomFound = { isTheSameRoomFound } isNoOfRoomsEmpty = { isNoOfRoomsEmpty } />
                            <UploadImage deleteHotelImage={this.removeImage} uploadRoomImages = { this.uploadRoomImages } chosenImages = { roomImage } handleOnDragOver = { this.handleOnDragOver } handleOnDrop={ this.handleOnDrop } isImageEmpty = {isImageEmpty} />
                            <Button variant="contained" disabled={isRequestLoading && !isTheSameRoomFound} fullWidth color="secondary" type="submit" disableElevation>{(isRequestLoading && !isTheSameRoomFound) && <CircularProgress size={24} />} { (isRequestLoading && !isTheSameRoomFound) ? "Saving" : "Save Room" }</Button>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            { /*hotelRooms &&
                                <React.Fragment>
                                    <RoomsList rooms = { hotelRooms } handleDisplayRoom = { this.handleDisplayRoom } />
                                </React.Fragment>
                            */}
                        </div>
                    </div>
                </form>
                <div className="text-right pt-3 pb-5">
                    {/*<Link to="/add-hotel">
                        <Button variant="contained" className="mr-2" disableElevation>Back</Button>
                    </Link>*/}
                    {/* this.props.isRequestSuccess &&
                        <Link to="/add-hotel/add-rates">
                            <Button variant="contained" color="primary" disableElevation>Continue</Button>
                        </Link>
                    */}
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={isRequestError}
                    autoHideDuration={2000}
                    onClose={this.handleSnackBarClose}
                    >
                    <Alert onClose={this.handleSnackBarClose} variant="filled" severity="error">{ errorMessage }</Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={isTheSameRoomFound}
                    autoHideDuration={2000}
                    onClose={this.handleSnackBarClose}
                    >
                    <Alert  onClose={this.handleSnackBarClose} variant="filled" severity="warning">You have already added this same room type.</Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={isNoOfRoomsEmpty}
                    onClose={this.handleSnackBarClose}
                    autoHideDuration={2000}
                    // onClose={this.handleSnackbarClose}
                    >
                    <Alert  onClose={this.handleSnackBarClose} variant="filled" severity="warning">No of Rooms must be at least 1</Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={this.handleSnackBarClose}
                    open={isRequestSuccess && (isRequestLoading === false)}
                    autoHideDuration={2000}
                    // onClose={()=> this.setState({ isRequestLoading: false })}
                    >
                    <Alert onClose={this.handleSnackBarClose} variant="filled" severity="success">{ successMessage }</Alert>
                </Snackbar>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addRoom: (formData) => dispatch(addRoom(formData)),
        addCurrentRoomBeingEdited: (roomType) => dispatch(addCurrentRoomBeingEdited(roomType)),
        getHotel: (id) => dispatch(getHotel(id)),
    }
}
  
const mapStateToProps  = (state) => {
    return {
      hotel: state.hotel.hotelInfo.data,
      hotelRooms: state.hotel.hotelInfo.data && state.hotel.hotelInfo.data.rooms,
      hotelId: state.hotel.hotelInfo && state.hotel.hotelInfo.data._id,
      isThereError: state.addRoomInfo.error,
      isLoading: state.addRoomInfo.loading,
      isRequestSuccess: state.addRoomInfo.success,
      successMessage: state.addRoomInfo.successMessage,
      errorMessage: state.addRoomInfo.errorMessage.message
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddRooms);