import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const amenitiesList = ['Air Conditioning', 'Swimming Pool', 'On-site parking', 'Family Room', 'Gym', 'Free Wifi', 'Smoking Room', 'Television', 'Restaurant', 'Spa & Wellness Center', 'Bar Lounge'];

function Amenities({ handleChangeAmenities, selectedAmenities, isAmenitiesEmpty }) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <Typography variant="h6" className="mb-2">Please select all the amenities available for this room.</Typography>
                    <div className="row">
                        {
                            amenitiesList.map((item, index)=> (
                                <div className="col-sm-12 col-md-4 col-lg-6 col-xl-6" key={index}>
                                    <Checkbox
                                        checked={selectedAmenities.includes(item)}
                                        color="primary"
                                        onChange={(e)=>handleChangeAmenities(e, item)}
                                        value={item}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    {item}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="col-12 mt-3">
                    { isAmenitiesEmpty &&
                        <Typography variant="caption" className="text-danger">Please select available amenities in your hotel.</Typography>
                    }
                </div>
            </div>
        </div>
    );
}
 
export default Amenities;