import axios from 'axios';
import { UPDATE_ROOM_INFO_REQUEST, UPDATE_ROOM_INFO_SUCCESS, UPDATE_ROOM_INFO_FAILURE } from '../types/hotelTypes';
const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const updateRoomInfoRequest = () => {
    return {
        type: UPDATE_ROOM_INFO_REQUEST
    }
}

const updateRoomInfoSuccess = hotel => {
    return {
        type: UPDATE_ROOM_INFO_SUCCESS,
        payload: hotel
    }
}
export const updateRoomInfoFailure = error => {
    return {
        type: UPDATE_ROOM_INFO_FAILURE,
        payload: error
    }
}

export const updateRoomInfo = (formData, roomId) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    return async (dispatch, getState) => {
        dispatch(updateRoomInfoRequest());
        // console.log(formData.entries());
        return axios.post(`${BASE_URL}/rooms/${roomId}`, formData, {
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(updateRoomInfoSuccess(res.data));
            })
            .catch(error => {
                dispatch(updateRoomInfoFailure(error));
            })
    }
}