import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

function RoomInfo({
    handleRoomData,
    roomType,
    noOfRooms,
    // roomDescription,
    isTheSameRoomFound,
    // minimumDescriptionCount,
    isNoOfRoomsEmpty,
    // isFormValidationActivated,
    maximumOccupancy,
    possibleOccupancyList
}) {
    return (
        <div className="row py-2">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <FormControl fullWidth variant="outlined" required>
                    <InputLabel id="for-roomType-label">Enter Room Type</InputLabel>
                    <Select
                        onChange={handleRoomData}
                        labelId="for-roomType-label"
                        id="roomType"
                        name="roomType"
                        label="Enter Room Type"
                        value={roomType}
                        error={isTheSameRoomFound}
                        helperText={isTheSameRoomFound && "This room type has already been registered for this hotel. Enter a new room type and hit the save button."}
                    >
                        <MenuItem value={"single"}>Single Room</MenuItem>
                        <MenuItem value={"double"}>Double Room</MenuItem>
                        <MenuItem value={"triple"}>Triple Room</MenuItem>
                        <MenuItem value={"deluxe"}>Deluxe Room</MenuItem>
                        <MenuItem value={"executive"}>Executive Room</MenuItem>
                        <MenuItem value={"quad"}>Quad Room</MenuItem>
                        <MenuItem value={"queen"}>Queen Room</MenuItem>
                        <MenuItem value={"hollywood"}>Hollywood twin Room</MenuItem>
                        <MenuItem value={"twin"}>Twin Room</MenuItem>
                        <MenuItem value={"double-double"}>Double-double Room</MenuItem>
                        <MenuItem value={"studio"}>Studio Room</MenuItem>
                        <MenuItem value={"master-suite"}>Master Suite</MenuItem>
                        <MenuItem value={"mini-suite"}>Mini Suite</MenuItem>
                        <MenuItem value={"junior-suite"}>Junior Suite</MenuItem>
                        <MenuItem value={"presidential-suite"}>Presidential suite</MenuItem>
                        <MenuItem value={"connecting-room"}>Connecting Room</MenuItem>
                        <MenuItem value={"adjoining-room"}>Adjoining Room</MenuItem>
                        <MenuItem value={"adjacent-room"}>Adjacent Room</MenuItem>
                        <MenuItem value={"1-bed-apartment"}>1 Bed Apartment</MenuItem>
                        <MenuItem value={"2-bed-apartment"}>2 Bed Apartment</MenuItem>
                        <MenuItem value={"3-bed-apartment"}>3 Bed Apartment</MenuItem>
                        <MenuItem value={"cabana"}>Cabana</MenuItem>
                        <MenuItem value={"villa"}>Villa</MenuItem>
                        <MenuItem value={"penthouse"}>Penthouse</MenuItem>
                        <MenuItem value={"accessible-room"}>Accessible Room</MenuItem>
                        <MenuItem value={"beach-house-1-bedroom"}>Beach House with 1 bedroom</MenuItem>
                        <MenuItem value={"beach-house-2-bedroom"}>Beach House with 2 bedroom</MenuItem>
                        <MenuItem value={"conference-room-small"}>Conference room - Small</MenuItem>
                        <MenuItem value={"conference-room-medium"}>Conference room - Medium</MenuItem>
                        <MenuItem value={"conference-room-large"}>Conference room - Large</MenuItem>
                        <MenuItem value={"meeting-room-small"}>Meeting room - Small</MenuItem>
                        <MenuItem value={"meeting-room-medium"}>Meeting room - Medium</MenuItem>
                        <MenuItem value={"meeting-room-large"}>Meeting room - Large</MenuItem>
                        <MenuItem value={"event-room-small"}>Event room - Small</MenuItem>
                        <MenuItem value={"event-room-medium"}>Event room - Medium</MenuItem>
                        <MenuItem value={"event-room-large"}>Event room - Large</MenuItem>
                    </Select>
                </FormControl>
                {/*<TextField
                    onChange={handleRoomData}
                    variant="outlined"
                    label="Enter Room Type"
                    id="roomType"
                    error={isTheSameRoomFound}
                    helperText={isTheSameRoomFound && "This room type has already been registered for this hotel. Enter a new room type and hit the save button."}
                    value={roomType}
                    fullWidth
                    required
                />*/}
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <TextField
                    onChange={handleRoomData}
                    variant="outlined"
                    label="No Of Room"
                    id="noOfRooms"
                    name="noOfRooms"
                    value={noOfRooms}
                    error={isNoOfRoomsEmpty}
                    helperText={isNoOfRoomsEmpty && 'Number of rooms should be at least 1.'}
                    fullWidth
                    required
                />
            </div>
            <div className="col-12">
                <FormControl fullWidth variant="outlined" required>
                    <InputLabel id="for-roomType-label">Maximum Occupancy</InputLabel>
                    <Select
                        onChange={handleRoomData}
                        labelId="for-roomType-label"
                        id="maximumOccupancy"
                        name="maximumOccupancy"
                        label="Max Occupancy"
                        value={maximumOccupancy}
                        // error={isTheSameRoomFound}
                        // helperText={isTheSameRoomFound && "This room type has already been registered for this hotel. Enter a new room type and hit the save button."}
                    >
                    {
                        possibleOccupancyList.map((item, index) => (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                        ))
                    }
                    </Select>
                </FormControl>
                {/*<TextField
                    onChange={handleRoomData}
                    value={roomDescription}
                    variant="outlined"
                    label="Room Description"
                    id="roomDescription"
                    name="roomDescription"
                    fullWidth
                    multiline
                    error={ isFormValidationActivated && (roomDescription === "" || roomDescription.length < minimumDescriptionCount) }
                    helperText={ roomDescription.length < minimumDescriptionCount ? `Must be at least ${minimumDescriptionCount} characters` : roomDescription === "" ? "Required" : " " }
                    rows={4}
                    required
                />*/}
            </div>
        </div>
    );
}

 
export default RoomInfo;