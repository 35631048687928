import { ADD_COMPLETED_STEP } from '../types/hotelTypes';

const initState = [];

const completedStepReducer = (state = initState, action) => {
    console.log(action, 'action');
    switch(action.type){
        case ADD_COMPLETED_STEP:
        		if(state.length <= 5){     			
                    return [
                        ...state,
                        action.payload
                    ]
                } else {
                    return state;
                }
        default:
            return state;
    }
}

export default completedStepReducer;