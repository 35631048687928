// import axios from 'axios';
import { ADD_COMPLETED_STEP } from '../types/hotelTypes';

// const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const addCompletedStep = (step) => {
    return {
        type: ADD_COMPLETED_STEP,
        payload: step
    }
}

export const addCompleted = (data) => {
    return (dispatch, getState) => {
        dispatch(addCompletedStep(data));
    }
}