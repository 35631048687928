import axios from 'axios';
import { ADD_HOTEL_BASIC_INFO_REQUEST, ADD_HOTEL_BASIC_INFO_SUCCESS, ADD_HOTEL_BASIC_INFO_FAILURE, ADD_HOTEL_BASIC_INFO_RESET } from '../types/hotelTypes';
import { getHotel } from './hotelActions';

const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const addHotelBasicInfoRequest = () => {
    return {
        type: ADD_HOTEL_BASIC_INFO_REQUEST
    }
}

const addHotelBasicInfoSuccess = hotel => {
    return {
        type: ADD_HOTEL_BASIC_INFO_SUCCESS,
        payload: hotel
    }
}

const addHotelBasicInfoFailure = error => {
    return {
        type: ADD_HOTEL_BASIC_INFO_FAILURE,
        payload: error
    }
}

export const addHotelBasicInfoReset = () => {
    return {
        type: ADD_HOTEL_BASIC_INFO_RESET,
    }
}

export const addHotelBasicInfo = (formData) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    return async (dispatch, getState) => {
        dispatch(addHotelBasicInfoRequest());
        // console.log(formData.entries());
        return axios.post(`${BASE_URL}/hotels`, formData, {
            headers: {
                "Content-type": "multipart/form-data",
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(async res => {
                window.localStorage.setItem("QuickstayhotelId", JSON.stringify(res.data.data._id));
                await dispatch(getHotel(res.data.data._id));
                dispatch(addHotelBasicInfoSuccess(res.data));
            })
            .catch(error => {
                dispatch(addHotelBasicInfoFailure(error));
            })
    }
}




// export const addHotelBasicInfo = (formData) => {
//     return async (dispatch, getState) => {
//         //async call
//         try {
//             const res = await axios.post(`${BASE_URL}/hotels`, formData, {headers: {"Content-type": "multipart/form-data"}} )
//             console.log(formData)

//             dispatch({
//                 type: 'ADD_HOTEL_BASIC_INFO',
//                 payload: res.data
//             });
//         } catch (error) {
//             dispatch( {
//                 type: 'ADD_HOTEL_BASIC_INFO_ERROR',
//                 payload: console.log(error),
//             })
//         }

//     }
// };