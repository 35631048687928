import axios from 'axios';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS } from '../types/hotelTypes';
const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const loginRequest = () => {
    return {
        type: LOGIN_REQUEST,
    }
}
const loginSuccess = loginDetails => {

    return {
        type: LOGIN_SUCCESS,
        payload: loginDetails
    }
}
const loginFailure = loginDetails => {
    return {
        type: LOGIN_FAILURE,
        payload: loginDetails
    }
}


const logoutSuccess = loginDetails => {
    return {
        type: LOGOUT_SUCCESS,
        payload: loginDetails
    }
}

export const login = (data) => {
    return async (dispatch, getState) => {
        dispatch(loginRequest());
        return axios.post(`${BASE_URL}/login`, data)
            .then(res => {
                // console.log(res)
                dispatch(loginSuccess(res));
                // dispatch(getHotel(data));
            })
            .catch(error => {
                // console.log(error)
                dispatch(loginFailure(error));
            })
    }
}

export const logout = (data) => {
    return async (dispatch, getState) => {
        dispatch(logoutSuccess());
    }
}