import React from 'react';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import { addRates } from '../../../../store/actions/addRatesActions';
import { getHotel } from '../../../../store/actions/hotelActions';
import { addCompleted } from '../../../../store/actions/addCompletedStepActions';

class RoomRates extends React.Component {
    state = {
        markupList: [0,1, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90],
        firstHours: 3,
        secondHours: 6,
        thirdHours: 9,
        fourthHours: 12,
        roomPrice: this.props.roomData.daily_price || '' ,
        hoursPerDay: this.props.roomData.hours_per_day || 20,
        firstHoursPrice: this.props.roomData.prices.three_hrs.price || 0,
        firstHoursMarkup: this.props.roomData.prices.three_hrs.rate || 1,
        secondHoursPrice: this.props.roomData.prices.six_hrs.price || 0,
        secondHoursMarkup: this.props.roomData.prices.six_hrs.rate || 1,
        thirdHoursPrice: this.props.roomData.prices.nine_hrs.price || 0,
        thirdHoursMarkup: this.props.roomData.prices.nine_hrs.rate || 1,
        fourthHoursPrice: this.props.roomData.prices.twelve_hrs.price || 0,
        fourthHoursMarkup: this.props.roomData.prices.twelve_hrs.rate || 1,
        percentageForRates: 0.05,
        data: null,
        isEditing: true,
        isRequestLoading: null,
        isRequestSuccess: false,
        isRequestError: false
    }

    handlefirstHoursMarkup=(markup)=> {
        let mark = markup/100;
        this.setState({ firstHoursMarkup: markup }, ()=> {
            this.calculatefirstHoursPrice(mark);
        });
    }
    handlesecondHoursMarkup=(markup)=> {
        let mark = markup/100;
        this.setState({ secondHoursMarkup: markup }, ()=> {
            this.calculatesecondHoursPrice(mark);
        });
    }
    handlethirdHoursMarkup=(markup)=> {
        let mark = markup/100;
        this.setState({ thirdHoursMarkup: markup }, ()=> {
            this.calculatethirdHoursPrice(mark);
        });
    }
    handlefourthHoursMarkup=(markup)=> {
        let mark = markup/100;
        this.setState({ fourthHoursMarkup: markup }, ()=> {
            this.calculatefourthHoursPrice(mark);
        });
    }
    
    calculatefirstHoursPrice = (markup) => {
        let totalPriceForSelectedHours = ((this.state.roomPrice || this.props.roomData.daily_price) / this.state.hoursPerDay) * this.state.firstHours;
        let newPrice = (totalPriceForSelectedHours * markup) + totalPriceForSelectedHours;
        this.setState({ firstHoursPrice: Math.round(newPrice) });
    }
    calculatesecondHoursPrice = (markup)=> {
        let totalPriceForSelectedHours = (this.state.roomPrice / this.state.hoursPerDay) * this.state.secondHours;
        let newPrice = (totalPriceForSelectedHours * markup) + totalPriceForSelectedHours;
        this.setState({ secondHoursPrice: Math.round(newPrice) });
    }
    calculatethirdHoursPrice = (markup)=> {
        let totalPriceForSelectedHours = (this.state.roomPrice / this.state.hoursPerDay) * this.state.thirdHours;
        let newPrice = (totalPriceForSelectedHours * markup) + totalPriceForSelectedHours;
        this.setState({ thirdHoursPrice: Math.round(newPrice) });
    }
    calculatefourthHoursPrice = (markup)=> {
        let totalPriceForSelectedHours = (this.state.roomPrice / this.state.hoursPerDay) * this.state.fourthHours;
        let newPrice = (totalPriceForSelectedHours * markup) + totalPriceForSelectedHours;
        this.setState({ fourthHoursPrice: Math.round(newPrice) });
    }

    handleChange = (e) => {
        this.setState({ isEditing: true });
        this.setState({ [e.target.id]: Number(e.target.value) }, ()=> {
            // console.log(this.state)
        })
    }

    handleCloseSnackbar = () => {
         this.setState({isRequestSuccess: false, isRequestError: false })
    }

    handleChangeRoomPrice = (e) => {
        this.setState({ isEditing: true });
        this.setState({ [e.target.id]: Number(e.target.value) }, ()=> {
            this.calculatefirstHoursPrice(this.state.firstHoursMarkup/100);
            this.calculatesecondHoursPrice(this.state.secondHoursMarkup/100);
            this.calculatethirdHoursPrice(this.state.thirdHoursMarkup/100);
            this.calculatefourthHoursPrice(this.state.fourthHoursMarkup/100);
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isRequestLoading: true });

        let arrayOfRates = [];

        let roomRateToUpload = {
            id: this.props.roomData._id,
            daily_price: this.state.roomPrice,
            total_hours: this.state.hoursPerDay,
            three_hrs_markup: this.state.firstHoursMarkup,
            six_hrs_markup: this.state.secondHoursMarkup,
            nine_hrs_markup: this.state.thirdHoursMarkup,
            twelve_hrs_markup: this.state.fourthHoursMarkup
        }
        arrayOfRates.push(roomRateToUpload);

        let data = {
            hotel_id: this.props.hotelId,
            data: arrayOfRates,
        };

        await this.props.addRates(data);

        if(this.props.isRequestSuccess){
            this.props.addCompleted(3);
        }

        if(this.props.isThereError ){
            this.setState({isRequestLoading: false, isRequestError: true})
        }

        if (this.props.isRequestSuccess) {
             this.setState({isRequestLoading: false, isRequestSuccess: true})
        }
    }
    render() {

        const { roomData, errorMessage, successMessage } = this.props;
        const {
            markupList,
            hoursPerDay,
            firstHoursPrice,
            firstHoursMarkup,
            secondHoursPrice,
            secondHoursMarkup,
            thirdHoursPrice,
            thirdHoursMarkup,
            fourthHoursPrice,
            fourthHoursMarkup,
            isEditing,
            isRequestLoading,
            isRequestSuccess,
            isRequestError
        } = this.state;
        
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="container-fluid p-0">
                    <div className="row mb-4">
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2">
                            <Typography variant="body1">Enter your daily price/rate</Typography>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
                            <TextField
                                onChange={this.handleChangeRoomPrice}
                                variant="outlined"
                                label="Room Price"
                                id="roomPrice"
                                value={isEditing ? this.state.roomPrice : roomData.daily_price }
                                fullWidth
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2">
                            <TextField
                                onChange={this.handleChange}
                                variant="outlined"
                                label="Hours per day"
                                value={hoursPerDay}
                                id="hoursPerDay"
                                fullWidth
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <Alert severity="info">
                                <Typography variant="caption">
                                    This is the total hours from Check in to Check out time
                                </Typography>
                            </Alert>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2">
                                    <Typography variant="body1" className="font-weight-bold">For 3 hours</Typography>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2">
                                    <TextField
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        label="Room Price"
                                        id="firstHoursPrice"
                                        disabled
                                        value={isEditing ? firstHoursPrice : roomData.prices.three_hrs.price }
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        required
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <Alert severity="info">
                                        <Typography variant="caption">
                                            This does not include our transaction fee
                                        </Typography>
                                    </Alert>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <Typography variant="caption">Pick your markup % for 3 hours</Typography>
                                </div>
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8 text-right pt-1">
                                    {markupList.map((item, index) => (
                                        <button
                                            type="button"
                                            className={`btn btn-sm ${(firstHoursMarkup || roomData.prices.three_hrs.rate ) === item ? 'secondaryBgColor' : 'btn-secondary'} mr-1 mb-1`}
                                            key={index}
                                            onClick={()=>this.handlefirstHoursMarkup(item)}
                                            >
                                            { item }
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <Typography variant="body1" className="font-weight-bold mb-3">For 6 hours</Typography>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2">
                                    <TextField
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        label="Room Price"
                                        id="secondHoursPrice"
                                        disabled
                                        value={isEditing ? secondHoursPrice : roomData.prices.six_hrs.price}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        required
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <Alert severity="info">
                                        <Typography variant="caption">
                                            This does not include our transaction fee
                                        </Typography>
                                    </Alert>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <Typography variant="caption">Pick your markup % for 6 hours</Typography>
                                </div>
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8 text-right pt-1">
                                    {markupList.map((item, index) => (
                                        <button
                                            type="button"
                                            className={`btn btn-sm ${(secondHoursMarkup || roomData.prices.six_hrs.rate ) === item ? 'secondaryBgColor' : 'btn-secondary'} mr-1 mb-1`}
                                            key={index}
                                            onClick={()=>this.handlesecondHoursMarkup(item)}
                                            >
                                            { item }
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <Typography variant="body1" className="font-weight-bold mb-3">For 9 hours</Typography>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2">
                                    <TextField
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        label="Room Price"
                                        id="thirdHoursPrice"
                                        disabled
                                        value={isEditing ? thirdHoursPrice : roomData.prices.nine_hrs.price}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        required
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <Alert severity="info">
                                        <Typography variant="caption">
                                            This does not include our transaction fee
                                        </Typography>
                                    </Alert>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <Typography variant="caption">Pick your markup % for 9 hours</Typography>
                                </div>
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8 text-right pt-1">
                                    {markupList.map((item, index) => (
                                        <button type="button" className={`btn btn-sm ${(thirdHoursMarkup || roomData.prices.nine_hrs.rate ) === item ? 'secondaryBgColor' : 'btn-secondary'} mr-1 mb-1`} key={index} onClick={()=>this.handlethirdHoursMarkup(item)}>{ item }</button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <Typography variant="body1" className="font-weight-bold mb-3">For 12 hours</Typography>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2">
                                    <TextField
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        label="Room Price"
                                        id="thirdHoursPrice"
                                        disabled
                                        value={isEditing ? fourthHoursPrice : roomData.prices.twelve_hrs.price}
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        required
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <Alert severity="info">
                                        <Typography variant="caption">
                                            This does not include our transaction fee
                                        </Typography>
                                    </Alert>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                    <Typography variant="caption">Pick your markup % for 12 hours</Typography>
                                </div>
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8 text-right pt-1">
                                    {markupList.map((item, index) => (
                                        <button type="button" className={`btn btn-sm ${(fourthHoursMarkup || roomData.prices.twelve_hrs.rate ) === item ? 'secondaryBgColor' : 'btn-secondary'} mr-1 mb-1`} key={index} onClick={()=>this.handlefourthHoursMarkup(item)}>{ item }</button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <Typography variant="caption" color="primary">*Please save first before proceeding*</Typography>
                            <Button variant="contained" disabled={isRequestLoading} fullWidth color="secondary" disableElevation type="submit">{isRequestLoading && <CircularProgress size={24} />} {isRequestLoading ? "Saving" : "Save Rates" }</Button>
                        </div>
                    </div>
                </div>
                {
                  isRequestError &&
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={isRequestError}
                    autoHideDuration={2000}
                    onClose={this.handleCloseSnackbar}
                  >
                    <Alert variant="filled" severity="error">{ errorMessage }</Alert>
                  </Snackbar>
                }
                {
                    isRequestSuccess &&
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={isRequestSuccess}
                        autoHideDuration={2000}
                       onClose={this.handleCloseSnackbar}
                        >
                        <Alert variant="filled" severity="success">{ successMessage }</Alert>
                    </Snackbar>
                }
            </form>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addRates: (formData) => dispatch(addRates(formData)),
      getHotel: (id) => dispatch(getHotel(id)),
      addCompleted: (data) => dispatch(addCompleted(data)),
    }
}

const mapStateToProps  = (state) => {
    return {
      hotel: state.hotel.hotelInfo.data,
      hotelId: state.hotel.hotelInfo.data._id,
      hotelRooms: state.hotel.hotelInfo && state.hotel.hotelInfo.data.rooms,
      isThereError: state.addRatesInfo.error,
      isLoading: state.addRatesInfo.loading,
      isRequestSuccess: state.addRatesInfo.success,
      successMessage: state.addRatesInfo.successMessage,
      errorMessage: state.addRatesInfo.errorMessage.message
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomRates);