import React, { Component } from 'react';
// import Paper from '@material-ui/core/Paper';
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import moment from 'moment';
// import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getHotel } from '../../store/actions/hotelActions';
import { getBookings } from '../../store/actions/bookingActions';
import CircularProgress from '@material-ui/core/CircularProgress';

class Bookings extends Component {
    state = {
        // activeTabIndex: 0
    }

    componentDidMount = () => {
        this.props.getBookings();
    }

    handleTabChange = (event, value) => {
        this.setState({ activeTabIndex: value });
    }

    render() {
        // const { activeTabIndex } = this.state;
        const { bookings, isBookingsLoading, isBookingsLoaded, isBookingsError } = this.props;
        return (
            <div className="container-fluid px-lg-5">
                <div className="row mb-3">
                    <div className="col-12">
                        <Typography variant="h5">Bookings</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {/*<Paper square>
                            <Tabs
                                value={activeTabIndex}
                                onChange={this.handleTabChange}
                                aria-label="Add Rooms Tab"
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                >
                            <Tab label="Confirmed" />
                            <Tab label="Not Confirmed" />
                            </Tabs>
                        </Paper>
                        { activeTabIndex === 0 && <TabContainer><Confirmed /></TabContainer> }
                        { activeTabIndex === 1 && <TabContainer><NotConfirmed /></TabContainer> }*/}
                        <Confirmed bookings = { bookings } isLoading={isBookingsLoading} isLoaded={isBookingsLoaded} isError={isBookingsError} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      getHotel: (id) => dispatch(getHotel(id)),
      getBookings: () => dispatch(getBookings())
    }
}
  
const mapStateToProps  = (state) => {
    return {
        hotel: state.hotel.success && state.hotel.hotelInfo.data,
        hotelId: state.hotel.success && state.hotel.hotelInfo.data._id,
        bookings: (state.booking.success && state.booking.bookingsInfo.bookings) || [],
        isBookingsLoading: state.booking.loading,
        isBookingsLoaded: state.booking.success,
        isBookingsError: state.booking.error,
        // isHotelLoading: state.hotel.loading,
        // isHotelLoaded: state.hotel.success,
        // isHotelError: state.hotel.error,
    }
}

 
export default connect(mapStateToProps, mapDispatchToProps)(Bookings);

class Confirmed extends Component {
    state = {
        tableData: [
            { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "David Mark", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "Victor Wood", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "Tiger Brunch", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "David Mark", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "Victor Wood", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "Tiger Brunch", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "David Mark", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "Victor Wood", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "Tiger Brunch", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
            { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
        ]
    }
    render() {
        
        const { bookings, isLoading, isError } = this.props;
        return (
            <>
            {isLoading ? <div className='loadingSpinner'> <CircularProgress /> </div> :
            <div className="row">
  
                <div className="col-12">
                    { isError && <div className="alert alert-danger">Fetching Error</div> }
                    { bookings.length <= 0 && <div className="alert alert-warning">No Bookings found.</div> }
                    { bookings.length > 0 &&
                        <table className="table table-hover table-responsive">
                            <thead>
                                <tr>
                                   <th>Transaction ID</th>
                                   <th>Date Booked</th>
                                   <th>Checkout Date</th>
                                   <th>Guest Name</th>
                                   <th>No. Of Rooms Booked</th>
                                   <th>Room Type</th>
                                   <th>Duration (hrs)</th>
                                   <th>Price</th>
                                   <th>Payment Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                { bookings.map((item, index) => (
                                    <tr key={index}>
                                        <td>{ item.transaction_id }</td>
                                        <td>{ item.date_booked }</td>
                                        <td>{ item.date_checkout }</td>
                                        <td>{ item.guest_name }</td>
                                        <td>{ item.no_of_rooms }</td>
                                        <td>{ item.room_type }</td>
                                        <td>{ item.duration }</td>
                                        <td>{ item.payment_amount }</td>
                                        <td>{ item.payment_status }</td>
                                        {/* <td><Button variant="contained" color="primary" className="text-white" disableElevetion>{ item.paymentStatus }</Button></td>*/}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
                
            </div>
                }
                </>
        );
    }
}

// class NotConfirmed extends Component {
//     state = {
//         tableData: [
//             { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "David Mark", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "Victor Wood", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "Tiger Brunch", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "David Mark", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "Victor Wood", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "Tiger Brunch", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "David Mark", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "Victor Wood", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "Tiger Brunch", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//             { id: 55524461562, date: "25/10/2020", guestName: "John Doe", noOfRoomsBooked: 1, roomType: "Double Room", paymentStatus: "confirmed" },
//         ]
//     }
//     render() {
//         const { tableData } = this.state;
//         return (
//             <div className="row">
//                 <div className="col-12">
//                     <table className="table table-hover">
//                         <thead>
//                             <tr>
//                                <th>Transaction ID</th> 
//                                <th>Date Booked</th> 
//                                <th>Guest Name</th> 
//                                <th>No. Of Rooms Booked</th> 
//                                <th>Room Type</th> 
//                                <th>Payment Status</th> 
//                             </tr>
//                         </thead>
//                         <tbody>
//                             { tableData.map((item, index) => (
//                                 <tr key={index}>
//                                     <td>{ item.id }</td>
//                                     <td>{ item.date }</td>
//                                     <td>{ item.guestName }</td>
//                                     <td>{ item.roomType }</td>
//                                     <td>{ item.noOfRoomsBooked }</td>
//                                     <td><Button variant="contained" color="secondary" className="text-white" disableElevetion>Not Confirmed</Button></td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         );
//     }
// }