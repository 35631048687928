export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';

export const CHECK_TOKEN_REQUEST = 'CHECK_TOKEN_REQUEST';
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
export const CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_HOTEL_REQUEST = 'GET_HOTEL_REQUEST';
export const GET_HOTEL_SUCCESS = 'GET_HOTEL_SUCCESS';
export const GET_HOTEL_FAILURE = 'GET_HOTEL_FAILURE';

export const GET_BANKS_REQUEST = 'GET_BANKS_REQUEST';
export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS';
export const GET_BANKS_FAILURE = 'GET_BANKS_FAILURE';

export const GET_BOOKING_REQUEST = 'GET_BOOKING_REQUEST';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAILURE = 'GET_BOOKING_FAILURE';

export const ADD_HOTEL_BASIC_INFO_REQUEST = 'ADD_HOTEL_INFO_REQUEST';
export const ADD_HOTEL_BASIC_INFO_SUCCESS = 'ADD_HOTEL_INFO_SUCCESS';
export const ADD_HOTEL_BASIC_INFO_FAILURE = 'ADD_HOTEL_INFO_FAILURE';

export const UPDATE_HOTEL_BASIC_INFO_REQUEST = 'UPDATE_HOTEL_INFO_REQUEST';
export const UPDATE_HOTEL_BASIC_INFO_SUCCESS = 'UPDATE_HOTEL_INFO_SUCCESS';
export const UPDATE_HOTEL_BASIC_INFO_FAILURE = 'UPDATE_HOTEL_INFO_FAILURE';
export const UPDATE_HOTEL_BASIC_RESET = 'UPDATE_HOTEL_BASIC_RESET';

export const ADD_ROOM_REQUEST = 'ADD_ROOM_REQUEST';
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export const ADD_ROOM_FAILURE = 'ADD_ROOM_FAILURE';
export const RESET_ROOM_STATE = 'RESET_ROOM_STATE';
export const CURRENT_ROOM_BEING_EDITED = 'CURRENT_ROOM_BEING_EDITED';

export const UPDATE_ROOM_INFO_REQUEST = 'UPDATE_ROOM_INFO_REQUEST';
export const UPDATE_ROOM_INFO_SUCCESS = 'UPDATE_ROOM_INFO_SUCCESS';
export const UPDATE_ROOM_INFO_FAILURE = 'UPDATE_ROOM_INFO_FAILURE';

export const ADD_RATES_REQUEST = 'ADD_RATES_REQUEST';
export const ADD_RATES_SUCCESS = 'ADD_RATES_SUCCESS';
export const ADD_RATES_FAILURE = 'ADD_RATES_FAILURE';

export const ADD_AVAILABILITY_REQUEST = 'ADD_AVAILABILITY_REQUEST';
export const ADD_AVAILABILITY_SUCCESS = 'ADD_AVAILABILITY_SUCCESS';
export const ADD_AVAILABILITY_FAILURE = 'ADD_AVAILABILITY_FAILURE';

export const CONFIRM_DETAILS_REQUEST = 'CONFIRM_DETAILS_REQUEST';
export const CONFIRM_DETAILS_SUCCESS = 'CONFIRM_DETAILS_SUCCESS';
export const CONFIRM_DETAILS_FAILURE = 'CONFIRM_DETAILS_FAILURE';

export const ADD_COMPLETED_STEP = 'ADD_COMPLETED_STEP';

export const DESTROY_REQUEST_STATE = 'DESTROY_REQUEST_STATE';
export const ADD_HOTEL_BASIC_INFO_RESET = 'ADD_HOTEL_BASIC_INFO_RESET';
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';
export const RESET_AVAILABILITY = 'RESET_AVAILABILITY';
