import axios from 'axios';
import { getHotelSuccess } from './hotelActions';
import { UPDATE_HOTEL_BASIC_INFO_REQUEST, UPDATE_HOTEL_BASIC_INFO_SUCCESS, UPDATE_HOTEL_BASIC_INFO_FAILURE, UPDATE_HOTEL_BASIC_RESET } from '../types/hotelTypes';

const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const updateHotelBasicInfoRequest = () => {
    return {
        type: UPDATE_HOTEL_BASIC_INFO_REQUEST
    }
}

const updateHotelBasicInfoSuccess = hotel => {
    return {
        type: UPDATE_HOTEL_BASIC_INFO_SUCCESS,
        payload: hotel
    }
}

const updateHotelBasicInfoFailure = error => {
    return {
        type: UPDATE_HOTEL_BASIC_INFO_FAILURE,
        payload: error
    }
}

export const resetUpdateInfo = () => {
    return {
        type: UPDATE_HOTEL_BASIC_RESET,
    }
}

export const updateHotelBasicInfo = (hotelId, formData) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    return async (dispatch, getState) => {
        dispatch(updateHotelBasicInfoRequest());
        // console.log(formData.entries());
        axios.post(`${BASE_URL}/hotels/${hotelId}`, formData, {
            headers: {
                // "Content-type": "multipart/form-data",
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(getHotelSuccess(res.data));
                dispatch(updateHotelBasicInfoSuccess(res.data));
            })
            .catch(error => {
                dispatch(updateHotelBasicInfoFailure(error));
            })
    }
}




// export const addHotelBasicInfo = (formData) => {
//     return async (dispatch, getState) => {
//         //async call
//         try {
//             const res = await axios.post(`${BASE_URL}/hotels`, formData, {headers: {"Content-type": "multipart/form-data"}} )
//             console.log(formData)

//             dispatch({
//                 type: 'ADD_HOTEL_BASIC_INFO',
//                 payload: res.data
//             });
//         } catch (error) {
//             dispatch( {
//                 type: 'ADD_HOTEL_BASIC_INFO_ERROR',
//                 payload: console.log(error),
//             })
//         }

//     }
// };