import { UPDATE_ROOM_INFO_REQUEST, UPDATE_ROOM_INFO_SUCCESS, UPDATE_ROOM_INFO_FAILURE } from '../types/hotelTypes';

const initState = {
    hotelInfo: [],
    hotelId: "",
    loading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: ""
}

const updateRoomReducer = (state = initState, action) => {
    switch(action.type){
        case UPDATE_ROOM_INFO_REQUEST:
            // console.log('UPDATE ROOM ', action.payload);
            return {
                ...state,
                lRoomg: true,
                success: false,
                error: false
            }
        case UPDATE_ROOM_INFO_SUCCESS:
            // console.log('UPDATE ROOM SUCCESS ', action.payload);
            return {
                ...state,
                hotelInfo: action.payload,
                hotelId: action.payload.data._id,
                success: true,
                error: false,
                successMessage:  action.payload.message,
            }
        case UPDATE_ROOM_INFO_FAILURE:
            // console.log('UPDATE ROOM FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default updateRoomReducer;