import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// console.log(JSON.parse(window.localStorage.getItem("user_token")));
// console.log(window.localStorage.getItem("user_token"));
// axios.defaults.headers.common['Authorization'] = window.localStorage.getItem("user_token");
 
const ProtectedRoute = (props) => {
	const Component = props.component;
	
     	// const isAuthenticated = props.isLoggedIn;
    
	if (props.isLoggedIn) {
		return <Component />
	}
	else {
		return <Redirect to='/login' />
	}

}

const mapStateToProps = (state) => {
	return {
		isLoggedIn: state.auth.isLoggedIn,
	}
}
export default connect(mapStateToProps, null)(ProtectedRoute);