import React from 'react';
// import axios from 'axios';
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
// import CloudUploadIcon from '@material-ui/icon/CloudUploadIcon';
import Paper from '@material-ui/core/Paper';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Alert from '@material-ui/lab/Alert';
import RoomRates from './RoomRates';
import { connect } from 'react-redux';
import { addRates } from '../../../../store/actions/addRatesActions';
import { getHotel } from '../../../../store/actions/hotelActions';



function TabContainer(props) {
    return (
      <Typography component="div" className="py-2">
        {props.children}
      </Typography>
    );
}

class Rates extends React.Component {
    state = {
        activeTabIndex: 0,
        singleRoomData: null,
        doubleRoomData: null,
        data: [],
        // isRequestLoading: null
    }

    componentDidMount(){
        this.props.getHotel(this.props.hotelId);
    }
    componentDidUpdate(prevProps){
        if(prevProps.isRequestSuccess !== this.props.isRequestSuccess || prevProps.isThereError !== this.props.isThereError){
            if(this.props.isRequestSuccess || this.props.isThereError){
                // this.props.getHotel(this.props.hotelId);
                this.setState({
                    // isRequestLoading: false
                });
            }
        }
    }

    // handleSetRequestLoadingState=(data)=> {
    //     this.setState({ isRequestLoading: data });
    // }

    handleTabChange = (event, value) => {
        this.setState({ activeTabIndex: value });
    }
    singleRoomData = (data) => {
        this.setState({ singleRoomData: data });
        this.setState(prevState => ({ data: [...prevState.data, data ]}));
    }
    doubleRoomData = (data) => {
        this.setState({ doubleRoomData: data });
        this.setState(prevState => ({ data: [...prevState.data, data ]}));
    }
    handleSubmit = (e) =>{
        e.preventDefault();
        // this.setState({ isRequestLoading: true });

        this.props.history.push('/add-hotel/create-availability');
        // this.props.addRates(data);
    }

    render(){
        // this.props.isRequestSuccess && this.props.history.push('/create-availability')
        // console.log(this.props.hotel)
        const { activeTabIndex } = this.state;
        const { hotelRooms, hotelId } = this.props;
        return (
            <div className="container px-lg-5">
                <div className="row">
                    <div className="col-12 mb-2">
                        <Typography color="secondary" variant="h5">Add Prices/Rates for each room type</Typography>
                    </div>
                    <div className="col-12">
                        <Paper square elevation={0}>
                            <Tabs
                                value={activeTabIndex}
                                onChange={this.handleTabChange}
                                aria-label="Add Rates Tab"
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                className="mb-3"
                            >
                                {hotelRooms.map((item, index) => (
                                    <Tab key={index} label={item.type} />
                                ))}
                            </Tabs>
                        </Paper>
                        { hotelRooms.length === 0 && 
                            <div className="py-3">
                                <Alert variant="filled" severity="warning">No rooms found for this hotel. You need to add rooms first before setting rates. Please click <Link to="/add-rooms">here</Link> to add new rooms.</Alert>
                            </div>
                        }
                        { hotelRooms.map((item, index) => (
                            <div key={index}>
                                { activeTabIndex === index &&
                                    <TabContainer>
                                        <RoomRates hotelId={hotelId} roomData={item} onSubmitSingleRoomRates={this.singleRoomData} />
                                    </TabContainer> }
                                </div>
                            // { activeTabIndex === 1 && <TabContainer><DoubleRoomRate onSubmitDoubleRoomRates={this.doubleRoomData} /></TabContainer> }
                        ))}
                    </div>
                </div>
                {/*<div className="row mb-5">
                    <div className="col-12 pb-3">
                        <div className="d-flex justify-content-end">
                            <Link to="/add-hotel/add-rooms">
                                <Button color="primary">Back</Button>
                            </Link>
                            { this.props.isRequestSuccess &&
                                <Link to="/add-hotel/create-availability">
                                    <Button variant="contained" color="primary" disableElevation className="ml-3">Continue</Button>
                                </Link>
                            }
                        </div>
                    </div>
                </div>*/}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addRates: (formData) => dispatch(addRates(formData)),
      getHotel: (id) => dispatch(getHotel(id))
    }
}
  
const mapStateToProps  = (state) => {
    return {
      hotel: state.hotel.hotelInfo.data,
      hotelId: state.addHotelInfo.hotelId,
      hotelRooms: state.hotel.hotelInfo && state.hotel.hotelInfo.data.rooms,
      isThereError: state.addRatesInfo.error,
      isLoading: state.addRatesInfo.loading,
      isRequestSuccess: state.addRatesInfo.success,
      successMessage: state.addRatesInfo.successMessage,
      errorMessage: state.addRatesInfo.errorMessage.message
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rates);