import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import StarIcon from '@material-ui/icons/Star';
// import StarHalfIcon from '@material-ui/icons/StarHalf';
import CircularProgress from '@material-ui/core/CircularProgress';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ModalImage from "react-modal-image";
import { connect } from 'react-redux';
import { getHotel } from '../../store/actions/hotelActions';
import { getBookings } from '../../store/actions/bookingActions';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

function HotelInformation({hotelData, isHotelLoading, isHotelLoadingError, getHotel, hotelId, getBookings, isBookingsLoading, bookings}) { 
    useEffect(()=> {
        fetchData()
        
        // eslint-disable-next-line
    }, []);

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    let allRooms = hotelData ? hotelData.rooms.map((room) => room.no_of_units) : [];

    async function  fetchData () {
        await getHotel(hotelId);
        await getBookings();
    }

    return (
        <div>
           
            { isHotelLoading || isBookingsLoading ?  <div className='loadingSpinner'> <CircularProgress /> </div> :
                <div className="container-fluid px-lg-5">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <Typography variant="h5" color="primary">Hotel Information</Typography>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            {/*<Button variant="outlined" color="primary" className="mr-2">Add Rooms</Button>
                            <Button variant="contained" color="secondary" disableElevation>See Public View</Button>*/}
                        </div>
                    </div>
                    <div className="row align-items-center mb-3">
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <ModalImage
                                className="img-fluid img-thumbnail fitImageLarge mb-2"
                                style={{ borderRadius: '18px', maxHeight: '150px' }}
                                small={hotelData?.images[0].url}
                                large={hotelData?.images[0].url}
                                alt={hotelData?.images[0].url}
                            />
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <Typography variant="h5" className="font-weight-bold">{ hotelData?.name }</Typography>
                            <div className="d-flex">
                                {
                                    hotelData?.star_rating === 5 &&
                                    <div>
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                    </div>
                                }
                                {
                                    hotelData?.star_rating === 4 &&
                                    <div>
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                    </div>
                                }
                                {
                                    hotelData?.star_rating === 3 &&
                                    <div>
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                    </div>
                                }
                                {
                                    hotelData?.star_rating === 2 &&
                                    <div>
                                        <StarIcon color="secondary" />
                                        <StarIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                    </div>
                                }
                                {
                                    hotelData?.star_rating === 1 &&
                                    <div>
                                        <StarIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                        <StarBorderIcon color="secondary" />
                                    </div>
                                }
                                {
                                    (hotelData?.star_rating === 0 || hotelData?.star_rating === "") &&
                                    <div>
                                        No Rating
                                    </div>
                                }
                            </div>
                            <div className="row mt-3">
                                { [{title: 'Bookings', count: bookings.length}, {title: 'Rooms', count:  allRooms.length ? allRooms.reduce(reducer) : 0 }, {title: 'Reviews', count: 0}, {title: 'Ratings', count: hotelData && hotelData.star_rating ? hotelData.star_rating : '-' }].map((item, index) => (
                                <div className="col-md-3 col-lg-3 col-xl-3 col-6 text-center" key={index}>
                                    <Typography variant="h5" className="font-weight-bold">{ item.count }</Typography>
                                    <Typography variant="body1">{ item.title }</Typography>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <Card variant="outlined">
                                <CardContent className="p-3">
                                    <Typography variant="h5" className="mb-2">Basic Information</Typography>
                                    <Divider />
                                    <Typography variant="body1" className="text-uppercase small mt-3 mb-1 font-weight-bold">Hotel Name</Typography>
                                    <Typography variant="body1" color="primary" className="text-uppercase mb-4">{ hotelData?.name }</Typography>
                                    
                                    <Typography variant="body1" className="text-uppercase small mb-1 font-weight-bold">Hotel Description</Typography>
                                    <Typography paragraph className="mb-4">
                                        { hotelData?.description }
                                    </Typography>
                                    <Typography variant="body1" className="text-uppercase small mb-1 font-weight-bold">Hotel Address</Typography>
                                    <Typography paragraph>
                                        { hotelData?.input_address }
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <Card variant="outlined">
                                <CardContent className="p-3">
                                    <Typography variant="h5" className="mb-2 ">Amenities</Typography>
                                    <Divider />
                                    <div className="row mt-3">
                                        { hotelData &&
                                            hotelData.amenities.map((item, index)=> (
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-flex" key={index}>
                                                    <FiberManualRecordIcon /><Typography variant="body1">{item}</Typography>
                                                </div>
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        getHotel: (id) => dispatch(getHotel(id)),
        getBookings: () => dispatch(getBookings())
    }
}

const mapStateToProps = (state) => {
    return {
        hotelId: state.hotel.success && state.hotel.hotelInfo.data._id,
        hotelData: state.hotel.hotelInfo.data,
        isHotelLoading: state.hotel.loading,
        isHotelLoadingError: state.hotel.error,
        bookings: (state.booking.success && state.booking.bookingsInfo.bookings) || [],
        isBookingsLoading: state.booking.loading,
        isBookingsLoaded: state.booking.success,
        isBookingsError: state.booking.error,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HotelInformation);