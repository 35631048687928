import React, { useEffect } from 'react';
import { /*useHistory,*/ Link } from 'react-router-dom';
// import { Link } from 'react-router-dom'
import UploadImage from './UploadImage';
import _ from 'lodash';
import GeneralAmenities from './GeneralAmenities';
import BasicInformation from './BasicInformation';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { addHotelBasicInfo, addHotelBasicInfoReset } from '../../../store/actions/addHotelInfoActions';
import { addCompleted } from '../../../store/actions/addCompletedStepActions';

const minimunDescriptionCount = 50; //This is the minimun length of the description box in the BasicInformation section

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  wrapper: {
    margin: theme.spacing(1),
    paddingLeft: '3rem',
    paddingRight: '3rem',
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Basic Information', 'Upload Image', 'General Amenities'];
}

function AddHotelData(props) {
  // const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isFormValidationActivated, setFormValidationState] = React.useState(false);
  const [isRequestLoading, setRequestLoadingState] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [isImageEmpty, setImageEmptinessState] = React.useState(false);
  const [isAmenitiesEmpty, setAmenitiesEmptinessState] = React.useState(false);
  const [amenities, setAmenities] = React.useState([]);
  // const [address, setAddress] = React.useState("");
  const [values, setValues] = React.useState({
    name: '',
    starRating: "",
    streetAddress: "",
    url: "",
    latitude: "",
    longitude: "",
    address: "",
    placeId: "",
    city: "",
    state: "",
    country: "",
    bankName: "",
    accountName: "",
    accountNumber: "",
    availableRooms: 4,
    phoneNo: "",
    description: "",
    
  });

  useEffect(() => {
    setValues({
    name: props.hotel?.name || props.hotelInfo?.name || '',
    starRating: Number(props.hotel?.star_rating) || Number(props.hotelInfo?.star_rating) || '',
    streetAddress: "",
    url: props.hotel?.url || props.hotelInfo?.url || '',
    latitude: "",
    longitude: "",
    address: "",
    placeId: "",
    city: "",
    state: "",
    country: '',
    bankName: "",
    accountName: "",
    accountNumber: "",
    availableRooms: 4,
    phoneNo: props.hotel?.phoneNo || props.hotelInfo?.phoneNo || '',
    description: props.hotel?.description || props.hotelInfo?.description || '',
    
    })
    //eslint-disable-next-line
  }, [props.hotelInfo])

  const steps = getSteps();

  const handleNext = () => {
    if(activeStep === 0 && (values.name === "" || values.phoneNo === "" || values.address === "" || values.description === "" || values.description.length < minimunDescriptionCount)){
          setFormValidationState(true);
    }
    else if (activeStep === 1 && images === ""){
      setImageEmptinessState(true);
    } 
    else {
        setImageEmptinessState(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSnackbarClose = () => {
    setHasError(false);
    setIsSuccess(false)
  }

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const uploadHotelImages = (e) => {
    const newImages = _.cloneDeep(images);
    for (let i = 0; i < e.target.files.length; i++) {
            newImages.push(e.target.files[i])
    }
    setImages(newImages);
  }

  const removeImage = (index) => {
        let newImages = _.cloneDeep(images);
        if (newImages[index]) {
          newImages.splice(index, 1);
          setImages(newImages);
        }
  };
  
  const handleOnDragOver = e => {
    e.preventDefault();
  }

  const handleOnDrop = e => {
      e.preventDefault();
      //prevent the browser from opening the image
    e.stopPropagation();
    const newImages = _.cloneDeep(images);
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
            newImages.push(e.dataTransfer.files[i])
    }
      setImages(newImages);
  }

  const handleChangeAmenities = (e, val) => {
    if (e.target.checked){
      //append to array
        setAmenities(
          [...amenities, val]
        );
      setAmenitiesEmptinessState(false);
    } else {
      //remove from array
      setAmenities(
        amenities.filter(function(v) {return v!==val})
      );
      if(amenities.length <= 0){
        setAmenitiesEmptinessState(true);
      }
    }
  }

  const handleChangeAddress =(e)=> {
    // console.log(e)
    setValues({ ...values, address: e.address, latitude: e.latitude, longitude: e.longitude, placeId: e.placeId });
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(amenities.length <= 0){
      setAmenitiesEmptinessState(true);
    }
    else {    
      setRequestLoadingState(true);

      // console.log(amenities)
      // console.log(images)
      const formData = new FormData();
      formData.append('name', values.name);
      if (values.url) {
         formData.append('url', values.url);
      }
     
      values.starRating !== "" && formData.append('star_rating', values.starRating);

      values.address !== "" && formData.append('input_address', values.address);
      values.latitude !== "" && formData.append('latitude', values.latitude);
      values.longitude !== "" && formData.append('longitude', values.longitude);

      (values.latitude === "" && values.longitude === "") && formData.append('streetAddress', values.streetAddress);
      (values.latitude === "" && values.longitude === "") && formData.append('city', values.city);
      (values.latitude === "" && values.longitude === "" && values.state !== "") && formData.append('state', values.state);
      (values.latitude === "" && values.longitude === "") && formData.append('country', values.country);

      // formData.append('bankName', values.bankName);
      // formData.append('accountName', values.accountName);
      // formData.append('accountNumber', values.accountNumber);

      formData.append('phoneNo', values.phoneNo);
      formData.append('place_id', values.placeId);
      formData.append('description', values.description);
      formData.append('amenities', amenities.toString());
      for (let i = 0; i < images.length; i++) {
        formData.append(`images[${i}]`, images[i])
      }

      await props.addHotelBasicInfo(formData)

      if(props.isThereError){
        setRequestLoadingState(false);
        setHasError(true);
      }

      if (props.isRequestSuccess) {
         setRequestLoadingState(false);
         setIsSuccess(true);
      }

      // setTimeout(() => props.addHotelBasicInfoReset(), 2000);
    }
  }

  // React.useEffect(() =>{
  //   props.getHotel(this.props.hotelId);
  // }, []);
  React.useEffect(() => {
    // console.log("SUCCESS:", props.isRequestSuccess)
    // console.log("LOADING:", props.isLoading)
    // console.log("ERROR:", props.isThereError)

    if(props.isRequestSuccess || props.isThereError || (props.isLoading === false) ){
      // console.log("SUCCESS2:", props.isRequestSuccess)
      // console.log("LOADING2:", props.isLoading)
      // console.log("ERROR2:", props.isThereError)
      setRequestLoadingState(false);
      if (props.isRequestSuccess) {
         setIsSuccess(true);
          props.addCompleted(1);
      }
      // routeForward();
    } else {
      setRequestLoadingState(false);
    }
      setRequestLoadingState(false);
    // eslint-disable-next-line
  }, [props.isRequestSuccess, props.isThereError]);

  return (
    <div className="container px-lg-5">
      <div className="row justify-content-between mb-2">
          <div className="col-12">
              <Typography variant="h5" color="secondary" className="mb-2">Let's set up your hotel quickly</Typography>
          </div>
      </div>
      <div className="row">
        <div className="col-12">    
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton
                    onClick={handleStep(index)}
                    // completed={isStepComplete(index)}
                    // {...buttonProps}
                  >
                    <StepLabel>{label}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 px-md-2 px-lg-2 px-0">
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed</Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="pt-2 mb-3">
                { activeStep === 0 &&
                  <BasicInformation handleChange = { handleChange } onChangeAddress = {handleChangeAddress} inputData = { values } isFormValidationActivated = { isFormValidationActivated } minimunDescriptionCount = { minimunDescriptionCount } />
                }

                { activeStep === 1 &&
                  <UploadImage deleteHotelImage = { removeImage } uploadHotelImages = { uploadHotelImages } chosenImages = { images } handleOnDragOver = { handleOnDragOver } handleOnDrop={ handleOnDrop } isImageEmpty = { isImageEmpty } />
                }

                { activeStep === 2 &&
                  <GeneralAmenities handleChangeAmenities = { handleChangeAmenities } selectedAmenities = { amenities } isAmenitiesEmpty = { isAmenitiesEmpty } />
                }
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12 text-right">
                    <div className="d-flex justify-content-end">
                        <Button
                          color="primary"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                          >
                          Back
                        </Button>
                        {
                          activeStep === steps.length - 1 && 
                          <div>
                            <Button className={classes.wrapper} variant="contained" disabled={isRequestLoading || props.isLoading} disableElevation color="secondary" type="submit">{isRequestLoading && <CircularProgress size={24} className={classes.buttonProgress} />} {(isRequestLoading || props.isLoading) ? "Saving" : "Save" }</Button>
                          </div>
                        }
                        {/* <Button variant="contained" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button> */}
                        {
                          activeStep < 2 &&
                          <Button variant="contained" disableElevation onClick={handleNext}>
                            <Typography color="primary">Next</Typography>
                          </Button>
                        }
                    </div>
                    <div className="text-right mt-3 mb-5 pb-3">
                      { props.isRequestSuccess &&
                          <Link to="/add-hotel/add-rooms">
                              <Button variant="contained" className="px-3" color="primary" disableElevation>Continue</Button>
                          </Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {
                hasError &&
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={hasError}
                  autoHideDuration={2000}
                  onClose={handleSnackbarClose}
                >
                  <Alert variant="filled" severity="error">{ props.errorMessage }</Alert>
                </Snackbar>
                }
                {console.log(isSuccess, 'isSuccess')}
              {
                isSuccess &&
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={isSuccess}
                  autoHideDuration={2000}
                  onClose={handleSnackbarClose}
                >
                  <Alert variant="filled" severity="success">{ props.successMessage }</Alert>
                </Snackbar>
              }
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addHotelBasicInfo: (formData) => dispatch(addHotelBasicInfo(formData)),
    addHotelBasicInfoReset: () => dispatch(addHotelBasicInfoReset()),
    addCompleted: (data) => dispatch(addCompleted(data)),
  }
}

const mapStateToProps  = (state) => {
  return {
    hotel: state.addHotelInfo.hotelInfo,
     hotelInfo: state.hotel.hotelInfo?.data,
    isThereError: state.addHotelInfo.error,
    isLoading: state.addHotelInfo.loading,
    isRequestSuccess: state.addHotelInfo.success,
    successMessage: state.addHotelInfo.successMessage,
    errorMessage: state.addHotelInfo.errorMessage.validations
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddHotelData);