import React, { useEffect } from "react";
import Card from '@material-ui/core/Card';
import moment from 'moment';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TodayIcon from '@material-ui/icons/Today';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { getHotel } from '../../store/actions/hotelActions';
import { getBookings } from '../../store/actions/bookingActions';
// import "./styles.css";

function Graphs(props) {
  useEffect(() => {
    
    fetchBookings();
    // eslint-disable-next-line
  }, []);

  let today = moment();
  let todayBookings = props.bookings.filter((book) => moment(book.createdAt).isSame(today, 'day'));
  let todaySales = todayBookings.map((book) => book.payment_status === 'successful' ? book.payment_amount : 0);

  let weekBookings = props.bookings.filter((book) => moment(book.createdAt).isSame(today, 'week'));
  let weekSales = weekBookings.map((book) => book.payment_status === 'successful' ? book.payment_amount : 0);
  
  let monthBookings = props.bookings.filter((book) => moment(book.createdAt).isSame(today, 'month'));
  let monthSales = monthBookings.map((book) => book.payment_status === 'successful' ? book.payment_amount : 0);

  let allRooms = props.hotel && props.hotel.rooms ?   props.hotel.rooms.map((room) => room.no_of_units) : [];


  const fetchBookings = async () => {
    await props.getHotel();
    await props.getBookings();
  }

  const reducer = (accumulator, currentValue) => accumulator + currentValue

  const handleContinueFromLastStep = () => {
    console.log(props.isHotelLoaded)
    if(props.isHotelLoaded){
      if(props.hotel.step === 2) {
        props.history.push("/add-hotel/add-rooms");
      }
      else if(props.hotel.step === 3) {
        props.history.push("/add-hotel/add-rates");
      }
      else if(props.hotel.step === 4) {
        props.history.push("/add-hotel/create-availability");
      }
      else if(props.hotel.step === 5) {
        props.history.push("/add-hotel/confirm-details");
      }
    }
  }

  return (
    <div>
      { props.isBookingsLoading || props.isHotelLoading ? <div className='loadingSpinner'> <CircularProgress /> </div> :
        <div className="container-fluid px-lg-5">
          {props.step < 6 &&
            <div className="row mb-3">
              <div className="col-12">
                <div className="alert-primary rounded p-2">
                  <Typography color="primary" variant="body1" className="font-weight-bold">Continue from the last saved step!</Typography>
                  <Typography variant="caption" className="mb-2 d-block">Kindly complete your onboarding process.</Typography>
                  <Button variant="contained" color="secondary" size="small" disableElevation onClick={handleContinueFromLastStep}>Continue from last step</Button>
                </div>
              </div>
            </div>
          }
          <div className="row mb-3">
            <div className="col-12">
              <Typography color="primary" variant="h5">Dashboard</Typography>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex align-content-stretch mb-2">
              <Card variant="outlined" className="w-100">
                <CardContent className="p-2">
                  <div className="d-flex align-items-center">
                    <TodayIcon color="secondary" />
                    <Typography variant="h6" color="secondary" className="mb-2 ml-2">Today Sales</Typography>
                  </div>
                  <Typography variant="h4" color="primary" className="mb-2 text-right">{  todaySales.length ? todaySales.reduce(reducer).toFixed(2) : 0.00}</Typography>
                  {/*<canvas id="salesChart" width="100" height="100" />*/}
                </CardContent>
              </Card>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex align-content-stretch mb-2">
              <Card variant="outlined" className="w-100">
                <CardContent className="p-2">
                  <div className="d-flex align-items-center">
                    <DateRangeIcon color="secondary" />
                    <Typography variant="h6" color="secondary" className="mb-2 ml-2">Weekly Sales</Typography>
                  </div>
                  <Typography variant="h4" color="primary" className="mb-2 text-right">{  weekSales.length ? weekSales.reduce(reducer).toFixed(2)  : 0.00}</Typography>
                  {/*<canvas id="pageViewChart" width="100" height="100" />*/}
                </CardContent>
              </Card>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex align-content-stretch mb-2">
              <Card variant="outlined" className="w-100">
                <CardContent className="p-2">
                  <div className="d-flex align-items-center">
                    <CalendarTodayIcon color="secondary" />
                    <Typography variant="h6" color="secondary" className="mb-2 ml-2">Monthly Sales</Typography>
                  </div>
                  <Typography variant="h4" color="primary" className="mb-2 text-right">{  monthSales.length ? monthSales.reduce(reducer).toFixed(2)  : 0.00}</Typography>
                  {/*<canvas id="pageViewChart" width="100" height="100" />*/}
                </CardContent>
              </Card>
            </div>
            {/*<div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <Card variant="outlined">
                    <CardContent className="p-2">
                        <Typography variant="h6" className="mb-2">Reviews</Typography>
                        <Typography variant="h4" className="mb-2 text-right">50</Typography>
                        {<canvas id="reviewsChart" width="100" height="100" />}
                    </CardContent>
                </Card>
            </div>*/}
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" color="primary" className="mb-2">Rooms Booked</Typography>
                  <Divider />
                  <div className="row mt-3">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-lg-3">
                      <Card variant="outlined">
                        <CardContent className="p-2">
                          <Typography variant="h6" color="secondary" className="mb-2">Today</Typography>
                          <div>
                            <Typography variant="body1" className="mb-2" component="span">Total No. of Rooms: </Typography>
                            <Typography variant="body1" className="mb-2 font-weight-bold" component="span">{ allRooms.length ? allRooms.reduce(reducer) : 0}</Typography>
                          </div>
                          <div>
                            <Typography variant="body1" className="mb-2" component="span">Total No. of Rooms Booked: </Typography>
                            <Typography variant="body1" className="mb-2 font-weight-bold" component="span">{ todayBookings.length }</Typography>
                          </div>
                          {/*<canvas id="pageViewChart" width="100" height="100" />*/}
                        </CardContent>
                      </Card>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-lg-3">
                      <Card variant="outlined">
                        <CardContent className="p-2">
                          <Typography variant="h6" color="secondary" className="mb-2">This Week</Typography>
                          <div>
                            <Typography variant="body1" className="mb-2" component="span">Total No. of Rooms: </Typography>
                            <Typography variant="body1" className="mb-2 font-weight-bold" component="span">{ allRooms.length ? allRooms.reduce(reducer) : 0}</Typography>
                          </div>
                          <div>
                            <Typography variant="body1" className="mb-2" component="span">Total No. of Rooms Booked: </Typography>
                            <Typography variant="body1" className="mb-2 font-weight-bold" component="span">{ weekBookings.length }</Typography>
                          </div>
                          {/*<canvas id="pageViewChart" width="100" height="100" />*/}
                        </CardContent>
                      </Card>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-lg-3">
                      <Card variant="outlined">
                        <CardContent className="p-2">
                          <Typography variant="h6" color="secondary" className="mb-2">This Month</Typography>
                          <div>
                            <Typography variant="body1" className="mb-2" component="span">Total No. of Rooms: </Typography>
                            <Typography variant="body1" className="mb-2 font-weight-bold" component="span">{ allRooms.length ? allRooms.reduce(reducer) : 0}</Typography>
                          </div>
                          <div>
                            <Typography variant="body1" className="mb-2" component="span">Total No. of Rooms Booked: </Typography>
                            <Typography variant="body1" className="mb-2 font-weight-bold" component="span">{ monthBookings.length }</Typography>
                          </div>
                          {/*<canvas id="pageViewChart" width="100" height="100" />*/}
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                  {/*<div className="d-flex py-2">
                            <button className="btn rounded-pill primaryBgColor text-white mr-2">Today</button>
                            <button className="btn rounded-pill mr-2">This Week</button>
                            <button className="btn rounded-pill mr-2">This Month</button>
                        </div>*/}
                  {/*<canvas id="myChart" width="400" height="400" />*/}
                </CardContent>
              </Card>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              {/*<Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" className="mb-2">Availability</Typography>
                        <Divider />
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Room Type</th>
                                    <th>No of Rooms Loaded</th>
                                    <th>No of Rooms Booked</th>
                                    <th>No of Rooms Available</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Single Rooms</td>
                                    <td>25</td>
                                    <td>22</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>Double Rooms</td>
                                    <td>25</td>
                                    <td>22</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>Family Rooms</td>
                                    <td>25</td>
                                    <td>22</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>Executive Rooms</td>
                                    <td>25</td>
                                    <td>22</td>
                                    <td>5</td>
                                </tr>
                            </tbody>
                        </table>
                    </CardContent>
                </Card>*/}
            </div>
          </div>
        </div>}
      </div>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      getHotel: (id) => dispatch(getHotel(id)),
      getBookings: () => dispatch(getBookings())
    }
}
  
const mapStateToProps  = (state) => {
  if(state.hotel.hotelInfo){
    return {
        hotel: state.hotel.hotelInfo.data,
        hotelId: state.hotel.success && state.hotel.hotelInfo.data._id,
        step: state.hotel.success && state.hotel.hotelInfo.data.step,
        isHotelLoading: state.hotel.loading,
        isHotelLoaded: state.hotel.success,
        isHotelLoadingError: state.hotel.error,
        bookings: (state.booking.success && state.booking.bookingsInfo.bookings) || [],
        isBookingsLoading: state.booking.loading,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Graphs);