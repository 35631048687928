import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const RoomsList = ({rooms, handleDisplayRoom}) => {
  	return (
    	<div className="row">
    		<div className="col-12 mb-3">
    			<Typography variant="h6" color="primary">Added Rooms</Typography>
    		</div>
		    { rooms.map((item, index) => (
		    	<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 h-100 mb-2" key={index}>
		    	<Card variant="outlined">
		        <CardMedia
		          component="img"
		          alt="Contemplative Reptile"
		          height="140"
		          image={item.images[0].url}
		          title="Contemplative Reptile"
		        />
		        <CardContent>
		          <Typography gutterBottom variant="h6">
		            Type: {item.type}
		          </Typography>
			       {/*<Button size="small" variant="contained" color="secondary" onClick={ handleDisplayRoom(item.type) } disableElevation>
			          Edit
			       </Button>*/}
		        </CardContent>
			   </Card>
		    	</div>
		    ))}
    	</div>
  	);
}

export default RoomsList;