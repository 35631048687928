import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

function BasicInformation({handleChange, onChangeAddress, inputData, isFormValidationActivated, minimunDescriptionCount }){
    const [address, setAddress] = React.useState("");

    const handleChangeAddress = address => {
        setAddress(address)
    };

    const handleSelect = address => {
        let data = {};
        geocodeByAddress(address)
            .then((results) => { 
                    // console.log(results);
                    data.address = results[0].formatted_address;
                    data.placeId = results[0].place_id;
                    setAddress(data.address)
                    return getLatLng(results[0])
                }
            )
            .then(latLng => {
                data.latitude = latLng.lat;
                data.longitude = latLng.lng;
                onChangeAddress(data);
                // console.log('Success', latLng)
            })
            .catch(error => console.error('Error', error));
    };
 
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-8 col-xl-8 col-lg-8 mb-3">
                    <TextField
                        onChange={handleChange('name')}
                        value={inputData.name}
                        variant="outlined"
                        label="Hotel Name"
                        id="hotelName"
                        error={ isFormValidationActivated && (inputData.name === "")}
                        helperText={isFormValidationActivated && (inputData.name === "" ? "Required" : " ")}
                        fullWidth
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-4 co-lg-4 col-xl-4 mb-3">
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="for-hotelRating-label">Hotel Rating</InputLabel>
                        <Select
                            labelId="for-hotelRating-label"
                            id="hotelRating"
                            name="hotelRating"
                            label="Hotel Rating"
                            value={inputData.starRating}
                            onChange={handleChange('starRating')}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={1}>One Star</MenuItem>
                            <MenuItem value={2}>Two Star</MenuItem>
                            <MenuItem value={3}>Three Star</MenuItem>
                            <MenuItem value={4}>Four Star</MenuItem>
                            <MenuItem value={5}>Five Star</MenuItem>
                            {/*<MenuItem value={6}>Six Star</MenuItem>
                            <MenuItem value={7}>Seven Star</MenuItem>*/}
                        </Select>
                    </FormControl>
                    <Typography variant="caption">Please ensure this rating is given by an authorized body.</Typography>
                </div>
                <div className="col-sm-12 col-md-7 col-xl-7 col-lg-7 mb-3">
                    <TextField
                        onChange={handleChange('url')}
                        variant="outlined"
                        value={inputData.url}
                        label="Hotel Website URL"
                        id="hotelWebsiteUrl"
                        fullWidth
                    />
                </div>
                <div className="col-sm-12 col-md-5 co-lg-5 col-xl-5 mb-3">
                    <TextField
                        onChange={handleChange('phoneNo')}
                        variant="outlined"
                        value={inputData.phoneNo}
                        label="Hotel Telephone Number"
                        id="hotelTelephoneNumber"
                        type="tel"
                        error={isFormValidationActivated && (inputData.phoneNo.length <= 3 || inputData.phoneNo === "")}
                        helperText={isFormValidationActivated && (inputData.phoneNo.length <= 3 ? "Phone No. should be longer than 3" : inputData.phoneNo === "" ? "Required" : " ")}
                        fullWidth
                        required
                    />
                     <Typography variant="caption">Please ensure to add your country code without the (+) sign. e.g 23483457823</Typography>

                </div>
                <div className="col-sm-12 col-md-12 col-xl-12 col-lg-12 mb-3">
                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChangeAddress}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <TextField
                                    {...getInputProps()}
                                    // onChange={handleChangeCity('city')}
                                    // value={inputData.city}
                                    variant="outlined"
                                    label="Hotel Full Address"
                                    error={isFormValidationActivated && (inputData.address === "")}
                                    helperText={isFormValidationActivated && (inputData.address === "" ? "Required" : " ")}
                                    // id="hotelCity"
                                    // error={ isFormValidationActivated && (inputData.city === "")}
                                    // helperText={isFormValidationActivated && (inputData.city === "" ? "Required" : " ")}
                                    fullWidth
                                    required
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={index}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>

                {/*<div className="col-sm-12 col-md-8 col-xl-8 col-lg-8 mb-3">
                    <TextField
                        onChange={handleChange('streetAddress')}
                        value={inputData.streetAddress}
                        variant="outlined"
                        label="Hotel Address"
                        id="hotelAddress"
                        multiline
                        rows={1}
                        error={ isFormValidationActivated && (inputData.streetAddress === "")}
                        helperText={isFormValidationActivated && (inputData.streetAddress === "" ? "Required" : " ")}
                        fullWidth
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-4 co-lg-4 col-xl-4 mb-3">
                    <TextField
                        onChange={handleChange('city')}
                        value={inputData.city}
                        variant="outlined"
                        label="City"
                        id="hotelCity"
                        error={ isFormValidationActivated && (inputData.city === "")}
                        helperText={isFormValidationActivated && (inputData.city === "" ? "Required" : " ")}
                        fullWidth
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 co-lg-6 col-xl-6 mb-3">
                    <TextField
                        onChange={handleChange('state')}
                        value={inputData.state}
                        variant="outlined"
                        label="State"
                        id="hotelState"
                        fullWidth
                    />
                </div>
                <div className="col-sm-12 col-md-6 co-lg-6 col-xl-6 mb-3">
                    <TextField
                        onChange={handleChange('country')}
                        value={inputData.country}
                        variant="outlined"
                        label="Country"
                        id="hotelCountry"
                        error={ isFormValidationActivated && (inputData.country === "")}
                        helperText={isFormValidationActivated && (inputData.country === "" ? "Required" : " ")}
                        fullWidth
                        required
                    />
                </div>*/}

                {/*<div className="col-sm-12 col-md-12 co-lg-4 col-xl-4 mb-3">
                    <TextField
                        onChange={handleChange('bankName')}
                        value={inputData.bankName}
                        variant="outlined"
                        label="Bank Name"
                        id="bankName"
                        error={ isFormValidationActivated && (inputData.bankName === "")}
                        helperText={isFormValidationActivated && (inputData.bankName === "" ? "Required" : " ")}
                        fullWidth
                    />
                </div>
                <div className="col-sm-12 col-md-6 co-lg-4 col-xl-4 mb-3">
                    <TextField
                        onChange={handleChange('accountName')}
                        value={inputData.accountName}
                        variant="outlined"
                        label="Account Name"
                        id="accountName"
                        error={ isFormValidationActivated && (inputData.accountName === "")}
                        helperText={isFormValidationActivated && (inputData.accountName === "" ? "Required" : " ")}
                        fullWidth
                    />
                </div>
                <div className="col-sm-12 col-md-6 co-lg-4 col-xl-4 mb-3">
                    <TextField
                        onChange={handleChange('accountNumber')}
                        value={inputData.accountNumber}
                        variant="outlined"
                        type="number"
                        label="Account Number"
                        id="accountNumber"
                        error={ isFormValidationActivated && (inputData.accountNumber === "")}
                        helperText={isFormValidationActivated && (inputData.accountNumber === "" ? "Required" : " ")}
                        fullWidth
                        required
                    />
                </div>*/}
                <div className="col-sm-12 col-md-12 co-lg-12 col-xl-12">
                    <TextField
                        onChange={handleChange('description')}
                        value={inputData.description}
                        variant="outlined"
                        label="Hotel Description"
                        id="hotelDescription"
                        fullWidth
                        multiline
                        error={ isFormValidationActivated && (inputData.description === "" || inputData.description.length < minimunDescriptionCount )}
                        helperText={inputData.description.length < minimunDescriptionCount ? `Must be at least ${minimunDescriptionCount} characters` : inputData.description === "" ? "Required" : " " }
                        rows={4}
                        required
                    />
                </div>
            </div>
        </div>
    )
}

export default BasicInformation;