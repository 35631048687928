import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from 'axios';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './store/reducers/rootReducer';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// const instance = axios.create({
//     baseURL: 'URL/api'
// });

// instance.interceptors.request.use(
//   function(config) {
//     const token = localStorage.getItem("token"); 
//     if (token) {
//     	console.log(token);
//       config.headers["Authorization"] = 'Bearer ' + token;
//     }
//     return config;
//   },
//   function(error) {
//     return Promise.reject(error);
//   }
// );

// let AUTH_TOKEN = localStorage.getItem("user_token");
// console.log(AUTH_TOKEN)
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

// devToolsEnhancer()
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1D1974"
    },
    secondary: {
      main: "#F1B633"
    }
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
