import { SET_PASSWORD_REQUEST, SET_PASSWORD_SUCCESS, SET_PASSWORD_FAILURE, CHECK_TOKEN_REQUEST, CHECK_TOKEN_SUCCESS, CHECK_TOKEN_FAILURE } from '../types/hotelTypes';

const initState = {
    isTokenValid: false,
    isCheckTokenLoading: false,
    isCheckTokenError: false,

    token: "",
    loading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: ""
}

const setPasswordReducer = (state = initState, action) => {
    switch(action.type){
        case CHECK_TOKEN_REQUEST:
            // console.log('LOGIN REQUEST ', action.payload);
            return {
                ...state,
                isTokenValid: false,
                isCheckTokenLoading: true,
                isCheckTokenError: false,
            }
        case CHECK_TOKEN_SUCCESS:
            // console.log('LOGIN SUCCESS ', action.payload);
            return {
                ...state,
                isTokenValid: true,
                isCheckTokenLoading: false,
                isCheckTokenError: false
            }
        case CHECK_TOKEN_FAILURE:
            // console.log('LOGIN FAILURE ', action.payload);
            // console.log('LOGIN FAILURE ', action.payload.response.data.validations);
            return {
                ...state,
                isTokenValid: false,
                isCheckTokenLoading: false,
                isCheckTokenError: true
            }
        case SET_PASSWORD_REQUEST:
            // console.log('LOGIN REQUEST ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        case SET_PASSWORD_SUCCESS:
            // console.log('LOGIN SUCCESS ', action.payload);
            return {
                ...state,
                isTokenValid: true,
                loading: false,
                success: true,
                error: false,
                successMessage:  action.payload.data,
            }
        case SET_PASSWORD_FAILURE:
            // console.log('LOGIN FAILURE ', action.payload);
            // console.log('LOGIN FAILURE ', action.payload.response.data.validations);
            return {
                ...state,
                isTokenValid: false,
                loading: false,
                success: false,
                error: true,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default setPasswordReducer;