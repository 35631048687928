import { GET_BOOKING_REQUEST, GET_BOOKING_SUCCESS, GET_BOOKING_FAILURE } from '../types/hotelTypes';

const initState = {
    bookingsInfo: {},
    hotelId: "",
    loading: false,
    success: false,
    error: false,
    errorMessage: "",
    successMessage: ""
}

const bookingReducer = (state = initState, action) => {
    switch(action.type){
        case GET_BOOKING_REQUEST:
            // console.log('GET HOTEL ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        case GET_BOOKING_SUCCESS:
            // console.log('GET HOTEL SUCCESS ', action.payload);
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                bookingsInfo: action.payload,
            }
        case GET_BOOKING_FAILURE:
            // console.log('GET HOTEL FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default bookingReducer;