import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import HotelInformation from './HotelInformation';
import RatesAndAvailability from './RatesAndAvailability';
import { connect } from 'react-redux';
import { getHotel } from '../../../store/actions/hotelActions';
import { addCompleted } from '../../../store/actions/addCompletedStepActions';
import { confirmDetails } from '../../../store/actions/confirmDetailsActions';
import CircularProgress from '@material-ui/core/CircularProgress';

class ConfirmDetails extends Component {
    state = {
		redirect: null,
        isTermsAccepted: false,
        loading: false
    }

    async componentDidMount() {
        if (!this.props.hotel) {
        this.setState({ loading: true });
         await this.props.getHotel(this.props.hotelId);
         this.setState({ loading: false });
    }
        }
         
    handleAcceptTerms=()=>{
        if(this.state.isTermsAccepted){
            this.setState({ isTermsAccepted: false });
        }
        else {
            this.setState({ isTermsAccepted: true });
        }
    }

    handleFinish= async ()=>{
        // return <Redirect to={"/dashboard"} />
        this.setState({ loading: true });
        await this.props.confirmDetails(this.props.hotelId);
        this.setState({ loading: false });

        if (this.props.isConfirmSuccess) {
            this.props.addCompleted(6);
            this.setState({ redirect: "/dashboard" });
        }
        // this.props.history.push("/dashboard");
        
        // this.setState({ redirect: "/dashboard" }, ()=> {
        //     console.log(this.state.redirect)
        //     if (this.state.redirect) {
        //         return <Redirect to={this.state.redirect} />
        //     }
        // });
    }

    // handleTabChange = (event, value) => {
    //     this.setState({ activeTabIndex: value });
    // }

    render(){
        if(this.state.redirect) return <Redirect to={this.state.redirect} />;
        const { isHotelLoading, isHotelLoadingError, hotel } = this.props;
        const { isTermsAccepted, loading } = this.state;
        return (
            <>
                {loading ? <div className='loadingSpinner'><CircularProgress />  </div>:
                <div className="container px-lg-5">
                { isHotelLoading && (<h2>Loading...</h2>)}
                { isHotelLoadingError && (<h5 className="text-danger">Network Error...</h5>)}
                <div className="row mb-4">
                    <div className="col-12">
                        <Typography variant="h5" color="secondary">Please confirm your details</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <HotelInformation hotel = {hotel} />
                        <RatesAndAvailability hotel = {hotel} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <input type="checkbox" checked={isTermsAccepted} onChange={this.handleAcceptTerms} /> <Typography component="span" onClick={this.handleAcceptTerms}>I have read and agreed to the <Link to='/terms'>terms and conditons</Link> provided by Quikstays.</Typography>
                	  
		</div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                        <Link to="/add-hotel/create-availability">
                            <Button variant="contained" color="secondary" className="mr-2" disableElevation>Back</Button>
                        </Link>
                        <Button variant="contained" onClick={this.handleFinish} color="primary" disabled={!isTermsAccepted} disableElevation>Save &amp; Continue</Button>
                    </div>
                </div>
                </div>}
            
                </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHotel: (id) => dispatch(getHotel(id)),
        addCompleted: (data) => dispatch(addCompleted(data)),
      confirmDetails: (id) => dispatch(confirmDetails(id)),
    }
}
  
const mapStateToProps  = (state) => {
    return {
        hotel: state.hotel.hotelInfo.data,
        hotelId: state.hotel.hotelInfo.data?._id,
        isHotelLoading: state.hotel.loading,
        isHotelLoadingError: state.hotel.error,
        isConfirmSuccess: state.confirmHotel.success
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDetails);
