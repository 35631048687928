import { combineReducers } from 'redux';
import authReducer from './authReducer';
import setPasswordReducer from './setPasswordReducer';
import changePasswordReducer from './changePasswordReducer';
import addHotelInfoReducer from './addHotelInfoReducer';
import updateHotelReducer from './updateHotelInfoReducer';
import updateRoomReducer from './updateRoomInfoReducer';
import addRoomReducer from './addRoomReducer';
import addRatesReducer from './addRatesReducer';
import addAvailabilityReducer from './addAvailabilityReducer';
import hotelReducer from './hotelReducer';
import bookingReducer from './bookingReducer';
import bankReducer from './bankReducer';
import completedStepReducer from './addCompletedStepReducer';
import confirmDetailsReducer from './confirmDetailsReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    setPassword: setPasswordReducer,
    changePassword: changePasswordReducer,
    addHotelInfo: addHotelInfoReducer,
    updateHotelInfo: updateHotelReducer,
    updateRoomInfo: updateRoomReducer,
    addRoomInfo: addRoomReducer,
    addRatesInfo: addRatesReducer,
    addAvailabilityInfo: addAvailabilityReducer,
    hotel: hotelReducer,
    booking: bookingReducer,
    bank: bankReducer,
    completedSteps: completedStepReducer,
    confirmHotel: confirmDetailsReducer,
})
export default rootReducer;