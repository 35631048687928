import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ModalImage from "react-modal-image";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';



function UploadImage({uploadHotelImages, chosenImages, handleOnDragOver, handleOnDrop, isImageEmpty, deleteHotelImage}) {
    let imagesObj = [];
    let imagesArray = [];
    console.log(uploadHotelImages, 'uploadHotelImages')

    imagesObj.push(chosenImages)
    
    for (let i = 0; i < imagesObj[0].length; i++) {
        imagesArray.push(URL.createObjectURL(imagesObj[0][i]))
    }
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <Typography variant="h6" className="mb-2">Upload your Images of hotel and facilities</Typography>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        onChange={(e) => uploadHotelImages(e)}
                        type="file"
                    />
                    <label htmlFor="raised-button-file" className="d-block">
                        <Button variant="outlined" component="span" style={{ background: 'white', border: '2px dashed'}} fullWidth onDragOver={handleOnDragOver} onDrop={handleOnDrop}>
                            <div className="w-100 py-5 text-center">
                                <CloudUploadIcon fontSize="large" />
                                <Typography variant="body2" className="my-2">Drag and drop your hotel image or BROWSE</Typography>
                                <Typography variant="caption">Supported format HD picture in PNG, JPG or JPEG</Typography>
                            </div>
                        </Button>
                    </label>
                    { isImageEmpty &&
                        <Typography variant="caption" className="text-danger">Please upload at least one image of your hotel.</Typography>
                    }
                </div>
            </div>
            <div>
                <div className="col-12 py-2">
                    <div className="row">
                        {(imagesArray || []).map((url, index) => (
                            <div className="col-md-3 col-lg-3 col-xl-2 col-6 mb-3" key={index}>
                                <div className="position-relative">
                                    <ModalImage
                                        className="img-fluid img-thumbnail fitImage"
                                        small={url}
                                        large={url}
                                        alt={`hotel-image ${index}`}
                                    />
                                    {/* <img
                                        src={deleteIcon}
                                        alt='delete upload'
                                        className='deleteIcon'
                                        onClick={deleteUpload}
                                    /> */}
                                    {/* <img className="img-fluid fitImage" src={url} onClick alt={"upload"+index}/> */}
                                    <div style={{position: 'absolute', right: 0, top: 0, marginRight: '5px', marginTop: '5px' }}>
                                        <IconButton color="primary" className="secondaryBgColor" size="small" aria-label="delete" onClick={() => deleteHotelImage(index)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default UploadImage;