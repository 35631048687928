import axios from 'axios';
import { SET_PASSWORD_REQUEST, SET_PASSWORD_SUCCESS, SET_PASSWORD_FAILURE, CHECK_TOKEN_REQUEST, CHECK_TOKEN_SUCCESS, CHECK_TOKEN_FAILURE } from '../types/hotelTypes';

const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";


const checkTokenRequest = () => {
    return {
        type: CHECK_TOKEN_REQUEST,
    }
}
const checkTokenSuccess = () => {
    return {
        type: CHECK_TOKEN_SUCCESS,
        // payload: data
    }
}
const checkTokenFailure = () => {
    return {
        type: CHECK_TOKEN_FAILURE,
        // payload: data
    }
}

const setPasswordRequest = () => {
    return {
        type: SET_PASSWORD_REQUEST,
    }
}
const setPasswordSuccess = data => {
    return {
        type: SET_PASSWORD_SUCCESS,
        payload: data
    }
}
const setPasswordFailure = data => {
    return {
        type: SET_PASSWORD_FAILURE,
        payload: data
    }
}

export const checkToken = (token) => {
    return async (dispatch, getState) => {
        dispatch(checkTokenRequest());

        return axios.post(`${BASE_URL}/update/${token}?check=true`)
            .then(res => {
                dispatch(checkTokenSuccess());
            })
            .catch(error => {
                dispatch(checkTokenFailure());
            })
    }
}

export const setPassword = (hotelId, data, token) => {
    // let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));
    // {
    //     headers: {
    //         'Authorization': `Bearer ${AUTH_TOKEN}`
    //     }
    // }

    return async (dispatch, getState) => {
        dispatch(setPasswordRequest());

        return axios.post(`${BASE_URL}/update/${token}`, data)
            .then(res => {
                dispatch(setPasswordSuccess(res));
            })
            .catch(error => {
                dispatch(setPasswordFailure(error));
            })
    }
}