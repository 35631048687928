import axios from 'axios';
import { getHotel } from './hotelActions';
import {
    ADD_ROOM_REQUEST,
    ADD_ROOM_SUCCESS,
    ADD_ROOM_FAILURE,
    RESET_ROOM_STATE,
    CURRENT_ROOM_BEING_EDITED,
} from '../types/hotelTypes';

const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const addRoomRequest = () => {
    return {
        type: ADD_ROOM_REQUEST
    }
}

const addRoomSuccess = room => {
    return {
        type: ADD_ROOM_SUCCESS,
        payload: room
    }
}
const addRoomFailure = error => {
    return {
        type: ADD_ROOM_FAILURE,
        payload: error
    }
}
const resetRoomState = () => {
    return {
        type: RESET_ROOM_STATE,
        payload: null
    }
}

const addCurrentRoomBeingEditedState = roomType => {
    return {
        type: CURRENT_ROOM_BEING_EDITED,
        payload: roomType
    }
}

export const addRoom = (formData) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    return async (dispatch, getState) => {
        dispatch(addRoomRequest());
        return axios.post(`${BASE_URL}/rooms`, formData, {
            headers: {
                "Content-type": "multipart/form-data",
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(getHotel(formData.get("hotel_id")));
                dispatch(addRoomSuccess(res.data));

            })
            .catch(error => {
                dispatch(addRoomFailure(error));
            })
    }
}

export const addCurrentRoomBeingEdited = (roomType) => {
    return (dispatch, getState) => {
        dispatch(addCurrentRoomBeingEditedState(roomType));
    }
}


export const resetRoom = () => (dispatch) => dispatch(resetRoomState);
// export const addCurrentRoomBeingEdited = (roomType) => (dispatch, getState) => dispatch(addCurrentRoomBeingEditedState(roomType));