import { ADD_AVAILABILITY_REQUEST, ADD_AVAILABILITY_SUCCESS, ADD_AVAILABILITY_FAILURE, RESET_AVAILABILITY } from '../types/hotelTypes';

const initState = {
    hotelInfo: [],
    loading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: ""
}

const addAvailabilityReducer = (state = initState, action) => {
    switch(action.type){
        case ADD_AVAILABILITY_REQUEST:
            // console.log('ADD AVAILABILITY ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            }
        case ADD_AVAILABILITY_SUCCESS:
            // console.log('ADD AVAILABILITY SUCCESS ', action.payload);
            return {
                ...state,
                hotelInfo: action.payload,
                success: true,
                error: false,
                successMessage:  action.payload.message,
            }
        case ADD_AVAILABILITY_FAILURE:
            // console.log('ADD AVAILABILITY FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                errorMessage: action.payload
            }
        case RESET_AVAILABILITY:
            // console.log('ADD AVAILABILITY FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                error: false,
                success: false,
            }
        default:
            return state;
    }
}

export default addAvailabilityReducer;