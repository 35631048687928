import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { connect } from 'react-redux';
import { updateHotelBasicInfo, resetUpdateInfo } from '../../store/actions/updateHotelInfoActions';
import { getHotel } from '../../store/actions/hotelActions';
import { getBanks } from '../../store/actions/bankActions';

const BankDetails = (props) => {
  	const [defaultValue, setDefaultValue] = React.useState(props.bankList && props.accountInfo ? props.bankList.find((list) => list.code === props.accountInfo.bankCode) : null);
    const [loading, setLoading] = React.useState(false);
  
  	const [values, setValues] = React.useState({
      accountName: props.accountInfo?.accountName,
      accountNumber: props.accountInfo?.accountNumber,
      bankName: props.accountInfo?.bankName,
      bankCode: props.accountInfo?.bankCode,
    });
  
   React.useEffect(() => {
    if (!props.hotel || !props.hotel.accountInfo || !props.bankList.length) {
      handleInit();
     } 
      	// eslint-disable-next-line
    }, []);
  
  const handleSnackBarClose = () => {
    props.resetUpdateInfo();  
  }

  	const handleChange = (e) => {
      // setEditingStatus(true);
     	setValues({ ...values, [e.target.id]: e.target.value });
    };

    const handleChangeBankName = (e, val) => {
  		// setEditingStatus(true);
      setValues({ bankCode: val.code, bankName: val.name });
    };

  	const handleSubmit = async (e)=> {
  		e.preventDefault();

  		let data = {
  			accountName: values.accountName,
  			accountNumber: values.accountNumber,
  		  bankName: values.bankName,
        bankCode: values.bankCode
  		}

      await props.updateHotelBasicInfo(props.hotelId, data);  
      
  	}

 
    
  const handleInit = async () => {
    setLoading(true)

     const list = await props.getBanks();
    
      if (!props.hotel || !props.hotel.accountInfo) {
        const hotel = await props.getHotel(props.hotelId);
        if (hotel.data.accountInfo) {
          for (let i = 0; i < list.data.data.length; i++){
              if(list.data.data[i].code === hotel.data.accountInfo.bankCode){
                setDefaultValue(list.data.data[i]);

                setValues({
                  bankName: list.data.data[i].name,
                  accountName: hotel.data.accountInfo.accountName,
                  accountNumber: hotel.data.accountInfo.accountNumber,
                  bankCode: hotel.data.accountInfo.bankCode
                });
              }
          }
        }
        
      } else {
         for (let i = 0; i < list.data.data.length; i++){
            if(list.data.data[i].code === props.accountInfo.bankCode){
              setDefaultValue(list.data.data[i]);

              setValues({
                bankName: list.data.data[i].name,
                accountName: props.hotel.accountInfo.accountName,
                accountNumber: props.hotel.accountInfo.accountNumber,
                bankCode: props.hotel.accountInfo.bankCode
              });
            }
        }
      }
      
      setLoading(false);
    }

  	return (
      <div className="col-12 py-3">
      {loading ? <CircularProgress /> : <div className="row">
      <div className="col-12 py-3">
        <form onSubmit={handleSubmit}>
          <Autocomplete
            onChange={handleChangeBankName}
            id="bankName"
            options={ props.bankList }
            value={defaultValue}
            getOptionLabel={(option) => option.name}
            className="mb-3"
            required
            renderInput={ (params) => (
              <TextField
                {...params}
                fullWidth
                label="Bank Name"
                variant="outlined"
              />
            ) }
          />
          {/*<TextField
               onChange={handleChange}
               value={values.bankName}
               variant="outlined"
               label="Bank Name"
               id="bankName"
               className="mb-3"
               fullWidth
               required
             />*/}
           <TextField
               onChange={handleChange}
               value={ values.accountName }
               variant="outlined"
               label="Account Name"
               className="mb-3"
               id="accountName"
               name="accountName"
               // error={ isFormValidationActivated && (inputData.name === "")}
               // helperText={isFormValidationActivated && (inputData.name === "" ? "Required" : " ")}
               fullWidth
               required
             />
             <TextField
               onChange={handleChange}
               type="number"
               value={ values.accountNumber }
               variant="outlined"
               label="Account Number"
               id="accountNumber"
               name="accountNumber"
               className="mb-3"
               // error={ isFormValidationActivated && (inputData.name === "")}
               // helperText={isFormValidationActivated && (inputData.name === "" ? "Required" : " ")}
               fullWidth
               required
              />
              <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handleSnackBarClose}
                    open={props.isRequestSuccess}
                    autoHideDuration={2000}
                    // onClose={()=> this.setState({ isRequestLoading: false })}
                    >
                    <Alert onClose={handleSnackBarClose} variant="filled" severity="success">Bank Details Updated!</Alert>
                </Snackbar>
             {/* { props.isRequestSuccess && <div className="alert alert-success text-center">Bank Details Updated!</div> } */}
          <Button type="submit" variant="contained" disabled={props.isLoading} fullWidth color="primary" disableElevation>{props.isLoading && <CircularProgress size={24} />} {props.isLoading ? "Saving" : "Save Bank Details" }</Button>
        </form>
      </div>
    </div>}
    </div>
    	
  	);
}

const mapDispatchToProps = (dispatch) => {
  return {
  		updateHotelBasicInfo: (id, data) => dispatch(updateHotelBasicInfo(id, data)),
  		resetUpdateInfo: () => dispatch(resetUpdateInfo()),
      getHotel: (id) => dispatch(getHotel(id)),
   	  getBanks: () => dispatch(getBanks()),
  }
}

const mapStateToProps  = (state) => {
  return {
    hotel: state.hotel.success && state.hotel.hotelInfo.data,
    accountInfo: ((state.hotel.success && state.hotel.hotelInfo.data) && state.hotel.hotelInfo.data.accountInfo) || [],
    hotelId: state.hotel.success && state.hotel.hotelInfo.data._id,
    bankList: (state.bank.success && state.bank.bankInfo.data) || [],
    isThereError: state.updateHotelInfo.error,
    isLoading: state.updateHotelInfo.loading,
    isRequestSuccess: state.updateHotelInfo.success,
    successMessage: state.updateHotelInfo.successMessage,
    errorMessage: state.updateHotelInfo.errorMessage.message
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);