import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Typography, Button } from '@material-ui/core';
import RoomsAndRatesDialog from './RoomsAndRatesDialog';
import { connect } from 'react-redux';
import { getHotel } from '../../../store/actions/hotelActions';
import { resetRoom } from '../../../store/actions/addRoomActions';

class RoomsAndRates extends Component {
    state = {
        isDialogOpen: false,
        loading: false
    }

    async componentDidMount () {
         this.setState({ loading: true });
        await this.props.getHotel(this.props.hotelId);
        this.setState({ loading: false });
    }
    componentDidUpdate = (prevProps) => {
        // console.log(this.props.hotelRooms !== prevProps.hotelRooms);
        if(prevProps.hotelRooms !== this.props.hotelRooms){
            // console.log(prevProps.hotelRooms, this.props.hotelRooms);
            // this.props.getHotel(this.props.hotelId);
        }
    }

    resetRoomsStateInStore=()=>{
        this.props.resetRoom();
    }

    handleDialogOpen = () => {
        this.setState({ isDialogOpen: true }, () => {
            this.resetRoomsStateInStore();
        });
    }

    handleDialogClose = () => {
        this.setState({ isDialogOpen: false }, () => {
            this.resetRoomsStateInStore();
        });
    }

    render() {
        const { isDialogOpen, loading } = this.state;
        const { hotelRooms, currentRoomBeingEdited } = this.props;

        return (
            <div className="container-fluid px-lg-5">
                <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <Typography variant="h5">Rooms &amp; Rates</Typography>
                        <Button variant="contained" onClick={this.handleDialogOpen} size="small" color="secondary" disableElevation>Add New Room</Button>
                    </div>
                </div>
                {loading ? <div className='loadingSpinner'> <CircularProgress /> </div> :   <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2">
                        <Card className="h-100">
                            <CardActionArea className="h-100" onClick={this.handleDialogOpen}>
                                <CardContent className="d-flex justify-content-center align-items-center h-100">
                                    <div className="d-flex flex-column justify-content-center text-center">
                                        {!hotelRooms && <Typography variant="button" color="secondary" className="mb-5"> No Rooms Found</Typography>}
                                        <div className="text-center"><AddIcon fontSize="large" /></div>
                                        <Typography variant="button">Add New Room</Typography>
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    { hotelRooms && hotelRooms.map((item, index) => (
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2" key={index}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        style={{height: 150}}
                                        image={item.images[0].url}
                                        title={`${item.type}-room`}
                                    />
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        { item.type }
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        { item.units.length.toString() } {item.units.length > 1 ? 'units.' : 'unit.'}
                                        {/*50 units, each N500 per hour*/}
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    ))}
                    
                </div>}
              
                <RoomsAndRatesDialog isOpen={isDialogOpen} handleDialogClose={this.handleDialogClose} currentRoomBeingEdited={currentRoomBeingEdited}/>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHotel: (id) => dispatch(getHotel(id)),
        resetRoom: () => dispatch(resetRoom())
    }
}
  
const mapStateToProps  = (state) => {
    return {
      hotel: state.hotel.success && state.hotel.hotelInfo.data,
      hotelLoading: state.hotel.loading,
      hotelRooms: state.hotel.hotelInfo.data && state.hotel.hotelInfo.data.rooms,
        hotelId: state.hotel.success && state.hotel.hotelInfo.data._id,
        currentRoomBeingEdited: state.addRoomInfo.currentRoomBeingEdited,
      isThereError: state.addRoomInfo.error,
      isLoading: state.addRoomInfo.loading,
      isRequestSuccess: state.addRoomInfo.success,
      successMessage: state.addRoomInfo.successMessage,
      errorMessage: state.addRoomInfo.errorMessage.message
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoomsAndRates);