import React, { Component } from 'react';
// import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ModalImage from "react-modal-image";

function TabContainer(props) {
    return (
      <Typography component="div" className="py-2">
        {props.children}
      </Typography>
    );
}

class RatesAndAvailability extends React.Component {
    state = {
        activeTabIndex: 0,
    }

    handleTabChange = (event, value) => {
        this.setState({ activeTabIndex: value });
    }

    render(){
        const { activeTabIndex } = this.state;
        const { hotel } = this.props;
        return (
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-12 p-0 mb-2">
                        <Typography variant="body1" className="font-weight-bold">Confirm Rates &amp; Availability for each room.</Typography>
                    </div>
                    <div className="col-12 p-0">
                        <Paper square elevation={0}>
                            <Tabs
                                value={activeTabIndex}
                                onChange={this.handleTabChange}
                                aria-label="Hotel-Information-Tab"
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                            { hotel && hotel.rooms.map((item, index) => (
                                <Tab key={index} label={item.type} />
                            ))}
                            </Tabs>
                        </Paper>
                        { hotel && hotel.rooms.map((item, index) => (
                            <div key={index}>{ activeTabIndex === index && 
                                <TabContainer><Room hotel={hotel} room = {item} /></TabContainer> }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesAndAvailability;

class Room extends Component {
    render() { 
        const { room, hotel } = this.props;
        const serviceChargePercent = hotel.serviceCharge.split(' ')[0] ? Number(hotel.serviceCharge.split(' ')[0]) : 0;

        // console.log(room)\
        return (
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="row mb-4">
                            {/*<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Typography variant="caption" color="textSecondary" className="text-uppercase">Daily Price:</Typography>
                                <Typography variant="body1" className="font-weight-bold">N20,000</Typography>
                            </div>*/}
                            <div className="col-12 mt-2">
                                <Typography variant="body1" color="textSecondary" component="span" className="text-uppercase mr-2">No. Of Rooms Loaded:</Typography>
                                <Typography variant="body1" component="span" className="text-uppercase font-weight-bold">{ room && room.units.length.toString() } Rooms</Typography>
                            </div>
                            <div className="col-12 mt-2">
                                <Typography variant="body1" color="textSecondary" component="span" className="text-uppercase mr-2">Daily Price:</Typography>
                                <Typography variant="body1" component="span" className="text-uppercase font-weight-bold">{ (room && room.daily_price) || "not set"}</Typography>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <table className="table text-center">
                                    <thead>
                                        <tr>
                                            <th>Hours</th>
                                            <th>Markup %</th>
                                            <th>Service Charge</th>
                                            <th>Hotel Price</th>
                                            <th>Total Price Including Service charge</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // room.prices.map((item, index) => (
                                                // { Object.keys(value).map((item, key) => (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td>3 hours</td>
                                                            <td>{ room.prices && room.prices.three_hrs.rate }%</td>
                                                            <td>{ serviceChargePercent }%</td>
                                                            <td>{room.prices && room.prices.three_hrs.price}</td>
                                                            <td>{room.prices && room.prices.three_hrs.price && (Number(room.prices.three_hrs.price) + (Number(room.prices.three_hrs.price) * (Number(serviceChargePercent) / 100) ))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6 hours</td>
                                                            <td>{ room.prices && room.prices.six_hrs.rate }%</td>
                                                            <td>{ serviceChargePercent }%</td>
                                                             <td>{room.prices && room.prices.six_hrs.price}</td>
                                                            <td>{room.prices && room.prices.six_hrs.price && (Number(room.prices.six_hrs.price) + (Number(room.prices.six_hrs.price) * (Number(serviceChargePercent) / 100) ))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>9 hours</td>
                                                            <td>{room.prices && room.prices.nine_hrs.rate}%</td>
                                                            <td>{ serviceChargePercent }%</td>
                                                     
                                                    <td>{room.prices && room.prices.nine_hrs.price}</td>
                                                        <td>{room.prices && room.prices.nine_hrs.price && (Number(room.prices.nine_hrs.price) + (Number(room.prices.nine_hrs.price) * (Number(serviceChargePercent) / 100) ))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>12 hours</td>
                                                            <td>{room.prices && room.prices.twelve_hrs.rate}%</td>
                                                            <td>{ serviceChargePercent }%</td>
                                                        <td>{room.prices && room.prices.twelve_hrs.price}</td>
                                                        <td>{room.prices && room.prices.twelve_hrs.price && (Number(room.prices.twelve_hrs.price) + (Number(room.prices.twelve_hrs.price) * (Number(serviceChargePercent) / 100) ))}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                    // ))}
                                        // ))
                                    }
                                    </tbody>
                                </table>
                                <Typography variant="body1" color="primary">Service charge of <Typography className="font-weight-bold" component="span">{hotel.serviceCharge }</Typography> applies.</Typography>
                            </div>
                            <div className="col-12">
                                <div className="mb-4">
                                    <Typography variant="body1" color="textSecondary" component="span" className="text-uppercase mr-2">Period of availability:</Typography>
                                    <span>
                                        <Typography variant="body1" component="span" className="font-weight-bold">{moment(room.period_start_date).format('DD/MM/YYYY')}</Typography>&nbsp;to&nbsp;
                                        <Typography variant="body1" component="span" className="font-weight-bold">{ moment(room.period_end_date).format('DD/MM/YYYY') }</Typography>
                                    </span>
                                </div>

                                { room.days_excluded.length !== 0 &&
                                    <div className="mb-3">
                                        <Typography variant="body1" color="textSecondary" className="text-uppercase">All Days Except:</Typography>
                                        <div>
                                            { room.days_excluded && room.days_excluded.map((item, index) => (
                                                <Typography variant="body1" component="span" className="font-weight-bold mr-2" key={index}>{ item },</Typography>
                                            )) }
                                        </div>
                                    </div>
                                }

                                { room.amenities.length !== 0 &&
                                    <div >
                                        <Typography variant="body1">Amenities:</Typography>
                                        <div>
                                            { room.amenities && room.amenities.map((item, index) => (
                                                <Typography variant="body1" className="font-weight-bold mr-2" key={index}>{ item }</Typography>
                                            )) }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="row">
                            {room.images.map((item, index) => (
                                <div className="col-md-6 col-lg-6 col-xl-6 col-12 mb-2" key={index}>
                                    <ModalImage
                                        className="img-fluid img-thumbnail fitImage"
                                        small={item.url}
                                        large={item.url}
                                        alt={"room"+(++index)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}