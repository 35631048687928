import axios from 'axios';
import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_RESET } from '../types/hotelTypes';

const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const changePasswordRequest = () => {
    return {
        type: CHANGE_PASSWORD_REQUEST,
    }
}
const changePasswordSuccess = data => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload: data
    }
}

const changePasswordFailure = data => {
    return {
        type: CHANGE_PASSWORD_FAILURE,
        payload: data
    }
}

export const changePasswordReset = () => {
    return {
        type: CHANGE_PASSWORD_RESET,
    }
}


export const changePassword = (data) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));
    console.log(data)

    return async (dispatch, getState) => {
        dispatch(changePasswordRequest());
        return axios.post(`${BASE_URL}/change-password`, data, {
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(changePasswordSuccess(data));
            })
            .catch(error => {
                dispatch(changePasswordFailure(error));
            })
    }
}