import React, { useEffect } from 'react';
import { Route, Switch, Link, useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import QuikStaysLogo from '../../assets/images/logo-white.png';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import MailIcon from '@material-ui/icons/Mail';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import AddBasicInfo from './add-hotel-data/AddHotelData';
import AddRooms from './add-rooms/AddRooms';
import AddRates from './add-rates/AddRates';
import CreateAvailability from './add-availability/CreateAvailability';
import ConfirmDetails from './confirm-details/ConfirmDetails';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { getHotel } from '../../store/actions/hotelActions';
import { connect } from 'react-redux';
import { logout } from '../../store/actions/authActions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    background: '#1d1974',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

function AddHotel(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchHotel();
    //eslint-disable-next-line
  }, [])

  const fetchHotel = async () => {
      const hotelId = localStorage.getItem('QuickstayhotelId');
      const hotel = await props.getHotel();
      console.log(hotel)

      if (JSON.parse(hotelId) !== null && hotel && hotel.data?.step === 6) {
        history.push('/dashboard');
      }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const handleLogout = () => {
    props.logout();
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <div className="text-center pt-2">
          <img src={QuikStaysLogo} className="img-fluid" style={{width: '75%'}} alt="quikstays-logo" />
        </div>
      </div>
      <Divider />
      <List>
        {[
          {name: 'Add Hotel', link: "/add-hotel"},
          {name: 'Add Rooms', link: "/add-hotel/add-rooms"},
          {name: 'Add Rates', link: "/add-hotel/add-rates"},
          {name: 'Create Availability', link: "/add-hotel/create-availability"},
          {name: 'Confirm Details', link: "/add-hotel/confirm-details"}
        ].map((item, index) => (
          <ListItem key={index} button>
            { props.completedSteps.includes(++index) ?
              <Link to={item.link} key={index} className="text-decoration-none text-white">
                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                  <ListItemText primary={item.name} />
              </Link>
              :
              <ListItemText className="text-decoration-none text-muted" primary={item.name} />
            }
          </ListItem>
        ))}
      </List>
      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Partners Onboarding
            </Typography>
            <div className="ml-auto">
              <Button onClick={handleLogout} color="secondary">
                <ExitToAppIcon /> Logout
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>          
            <Route exact path="/" component={AddBasicInfo} />
            <Route exact path="/add-hotel" component={AddBasicInfo} />
            <Route path="/add-hotel/add-rooms" component={AddRooms} />
            <Route path="/add-hotel/add-rates" component={AddRates} />
            <Route path="/add-hotel/create-availability" component={CreateAvailability} />
            <Route path="/add-hotel/confirm-details" component={ConfirmDetails} />
          </Switch>
        </main>
      </div>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      logout: () => dispatch(logout()),
      getHotel: (id) => dispatch(getHotel(id)),
    }
}
  
const mapStateToProps  = (state) => {
    return {
        completedSteps: state.completedSteps,
        hotel: state.hotel.hotelInfo.data,
        hotelId: state.addHotelInfo.hotelId,
        isHotelLoading: state.hotel.loading,
        isHotelLoadingError: state.hotel.error
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddHotel);
