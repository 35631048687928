import { ADD_ROOM_REQUEST, ADD_ROOM_SUCCESS, ADD_ROOM_FAILURE, RESET_ROOM_STATE, CURRENT_ROOM_BEING_EDITED, DESTROY_REQUEST_STATE } from '../types/hotelTypes';

const initState = {
    hotelInfo: [],
    loading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: "",
    currentRoomBeingEdited: "",
}

const addRoomReducer = (state = initState, action) => {
    switch(action.type){
        case ADD_ROOM_REQUEST:
            // console.log('ADD ROOM ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
            }
        case ADD_ROOM_SUCCESS:
            // console.log('ADD ROOM SUCCESS ', action.payload);
            return {
                ...state,
                hotelInfo: action.payload,
                loading: false,
                success: true,
                error: false,
                successMessage:  action.payload.message,
            }
        case ADD_ROOM_FAILURE:
            // console.log('ADD ROOM FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                errorMessage: action.payload
            }
        case RESET_ROOM_STATE:
            // console.log('dispatched in reducer')
            return {
                hotelInfo: [],
                loading: false,
                error: false,
                success: false,
                successMessage: "",
                errorMessage: "",
                currentRoomBeingEdited: "",
            }
        case CURRENT_ROOM_BEING_EDITED:
            // console.log('CURRENT ROOM BEING EDITED ', action.payload);
            return {
                ...state,
                currentRoomBeingEdited: action.payload,
            }
        case DESTROY_REQUEST_STATE:
            return {
                hotelInfo: [],
                loading: false,
                error: false,
                success: false,
                successMessage: "",
                errorMessage: "",
                currentRoomBeingEdited: "",
            }
        default:
            return state;
    }
}

export default addRoomReducer;