import axios from 'axios';
import { GET_BANKS_REQUEST, GET_BANKS_SUCCESS, GET_BANKS_FAILURE } from '../types/hotelTypes';

// const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";
const BANK_API_URL = "https://api.paystack.co/bank";

const getBanksRequest = () => {
    return {
        type: GET_BANKS_REQUEST
    }
}

const getBanksSuccess = hotel => {
    return {
        type: GET_BANKS_SUCCESS,
        payload: hotel
    }
}
const getBanksFailure = error => {
    return {
        type: GET_BANKS_FAILURE,
        payload: error
    }
}

export const getBanks = () => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    return async (dispatch, getState) => {
        dispatch(getBanksRequest());
        return axios.get(BANK_API_URL, {
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(getBanksSuccess(res.data));
                return res;
            })
            .catch(error => {
                dispatch(getBanksFailure(error));
            })
    }
}