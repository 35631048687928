import React from 'react';
import ChangePassword from './ChangePassword'

const AccountSettings = (props) => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-sm-12 col-md-3 col-lg-4 col-xl-4"></div>
				<div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
					<ChangePassword />
				</div>
				<div className="col-sm-12 col-md-3 col-lg-4 col-xl-4"></div>
			</div>
		</div>
	)
}

export default AccountSettings;