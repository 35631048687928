import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ModalImage from "react-modal-image";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

function TabContainer(props) {
    return (
      <Typography component="div" className="py-2">
        {props.children}
      </Typography>
    );
}

class HotelInformation extends React.Component {
    state = {
        activeTabIndex: 0,
        basicInfo: {
                name: 'Eko Hotel',
                rating: '5 star',
                url: 'www.eko.com',
                address: 'Ikota, Lagos',
                description: 'sdsfsdfsfs',
        },
        amenities: ['air conditioning', 'single room', 'swimming pool', 'on-site parking', 'family room', 'gym', 'free wifi', 'smoking room'],
        hotelImages: ['one', 'two', 'three'],

    }

    handleTabChange = (event, value) => {
        this.setState({ activeTabIndex: value });
    }

    render(){
        const { activeTabIndex } = this.state;
        const { hotel } = this.props;
        return (
            <div className="container-fluid mb-3">
                <div className="row">
                    <div className="col-12 p-0 mb-2">
                        <Typography variant="body1" className="font-weight-bold">Confirm Hotel Information</Typography>
                    </div>
                    <div className="col-12 p-0">
                        <Paper square elevation={0}>
                            <Tabs
                                value={activeTabIndex}
                                onChange={this.handleTabChange}
                                aria-label="Hotel-Information-Tab"
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                            <Tab label="Basic Info" />
                            <Tab label="Hotel Images" />
                            <Tab label="Amenities" />
                            </Tabs>
                        </Paper>
                        { activeTabIndex === 0 && <TabContainer><BasicInfo basicInfo={hotel} /> </TabContainer> }
                        { activeTabIndex === 1 && <TabContainer><HotelImages hotelImages={hotel.images} /></TabContainer> }
                        { activeTabIndex === 2 && <TabContainer><Amenities amenities={hotel.amenities} /></TabContainer> }
                    </div>
                </div>
            </div>
        )
    }
}

export default HotelInformation;

class BasicInfo extends Component {
    state = {  }
    render() { 
        const { basicInfo } = this.props;
        return (
            <div className="container-fluid p-0 mt-2">
                <div className="row mb-2">
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                        <Typography variant="body1" color="textSecondary" className="text-uppercase">Hotel Name:</Typography>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9">
                        <Typography variant="body1" className="font-weight-bold">{ basicInfo?.name }</Typography>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                        <Typography variant="body1" color="textSecondary" className="text-uppercase">Hotel Rating:</Typography>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9">
                        <Typography variant="body1" className="font-weight-bold">{basicInfo?.star_rating} star</Typography>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                        <Typography variant="body1" color="textSecondary" className="text-uppercase">Hotel Url:</Typography>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9">
                        <Typography variant="body1" className="font-weight-bold">{ basicInfo?.url }</Typography>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                        <Typography variant="body1" color="textSecondary" className="text-uppercase">Hotel Address:</Typography>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9">
                        <Typography variant="body1"  className="font-weight-bold">{ basicInfo?.input_address }</Typography>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                        <Typography variant="body1" color="textSecondary" className="text-uppercase">Hotel Description:</Typography>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9">
                        <Typography variant="body1"  className="font-weight-bold">{ basicInfo?.description }</Typography>
                    </div>
                </div>
            </div>
        );
    }
}

class HotelImages extends Component {
    state = {  }
    render() { 
        return (
            <div className="container-fluid mt-2">
                <div className="row">
                    {/* <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <img src={HotelImage} className="img-fluid" alt="hotel" />
                    </div> */}
                    {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="row"> */}
                            {this.props.hotelImages.map((item, index) => (
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                                    <ModalImage
                                        className="img-fluid img-thumbnail fitImage mb-2"
                                        small={item.url}
                                        large={item.url}
                                        alt={"hotel"+(++index)}
                                    />
                                </div>
                            ))}
                            {/* <div className="col-md-6 col-lg-4 col-xl-4 col-6">
                                <img src={HotelImage} className="img-fluid mb-2" alt="hotel" />
                                <img src={HotelImage} className="img-fluid" alt="hotel" />
                            </div> */}
                        {/* </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

class Amenities extends Component {
    render() { 
        return (
            <div className="container-fluid mt-2">
                {/* <div className="row">
                    <div className="col-12"> */}
                        <div className="row">
                            {
                                this.props.amenities.map((item, index)=> (
                                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-2 mb-2" key={index}>
                                        {/* <Checkbox
                                            // checked={amenities.item}
                                            color="primary"
                                            onChange={(e)=>handleChangeAmenities(e, item)}
                                            value={item}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        /> */}
                                        <FiberManualRecordIcon />
                                        {item}
                                    </div>
                                ))
                            }
                        </div>
                    {/* </div>
                </div> */}
            </div>
        );
    }
}