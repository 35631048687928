import axios from 'axios';
import { ADD_AVAILABILITY_REQUEST, ADD_AVAILABILITY_SUCCESS, ADD_AVAILABILITY_FAILURE, RESET_AVAILABILITY } from '../types/hotelTypes';

// const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";
const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const addAvailabilityRequest = () => {
    return {
        type: ADD_AVAILABILITY_REQUEST
    }
}

const addAvailabilitySuccess = rates => {
    return {
        type: ADD_AVAILABILITY_SUCCESS,
        payload: rates
    }
}

const addAvailabilityFailure = error => {
    return {
        type: ADD_AVAILABILITY_FAILURE,
        payload: error
    }
}

export const resetAvailability = () => {
    return {
        type: RESET_AVAILABILITY
    }
}

export const addAvailability = (formData) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    return async (dispatch, getState) => {
        dispatch(addAvailabilityRequest());
        return axios.post(`${BASE_URL}/add-availability`, formData, {
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(addAvailabilitySuccess(res.data));
            })
            .catch(error => {
                dispatch(addAvailabilityFailure(error));
            })
    }
}