import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 40px 49px;
  font-size: 12pt;
  font-family: Avenir, sans-serif;
  @media screen and (max-width: 831px) {
    padding: 21px;
  }
`;

const TermsAndConditions = () => {
  return (
    <Container>
      <div
        dangerouslySetInnerHTML={{
          __html: `<p style="text-align: center;"><strong>TERMS AND CONDITIONS</strong></p>
          <p style="text-align: justify;"><strong>Content:</strong></p>
          <p style="text-align: justify;">Introduction</p>
          <p style="text-align: justify;">Services</p>
          <p style="text-align: justify;">Acceptable/Prohibited Uses</p>
          <p style="text-align: justify;">Payment</p>
          <p style="text-align: justify;">Intellectual Property</p>
          <p style="text-align: justify;">Applicable law, jurisdiction and dispute resolution</p>
          <p style="text-align: justify;">Disclaimer</p>
          <p><span style="color: #353535;">Cancellation, No-Use and Refund Policy</span></p>
          <p style="text-align: justify;">Miscellaneous</p>
          <p style="text-align: justify;"><strong>INTRODUCTION</strong></p>
          <p style="text-align: justify;">Quikstays, a duly registered corporate entity, is a marketplace service provider for short let accommodations. It does this by displaying the services of entities that are willing to offer quick stays and short let accommodations (&ldquo;Vendor&rdquo;) on the website and grants individuals or organizations who have need for the accommodation services (&ldquo;Users&rdquo;).</p>
          <p style="text-align: justify;">These terms and conditions are applicable to the marketplace services rendered by Quikstays to both accommodation Vendors and Users. Reference to certain words in these terms and conditions mean the following:</p>
          <p dir="ltr" style="line-height:1.2;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
          <div align="left" dir="ltr">
              <table style="border:none;border-collapse:collapse; display: block;
              overflow-x: auto;
              white-space: nowrap;">
                  <colgroup>
                      <col width="52">
                      <col width="133">
                      <col width="354">
                  </colgroup>
                  <tbody>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><strong>S/N</strong></span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><strong>Word</strong></span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><strong>Meaning</strong></span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">User</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Any individual or corporate organization that accesses this website using electronic devices such as computers, mobile phones, tablets, etc., for the purposes including but not limited to bookings and making inquiries about online accommodation bookings for short lets and other services facilitated by the marketplace provided by Quikstays. Users shall not include such persons as employees of Quikstays whilst acting in course of their employment or such persons engaged as consultants or otherwise providing services to Quikstays.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Website</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The domain name:&nbsp;</span><a href="http://www.quickstays.com" style="text-decoration:none;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#0563c1;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">www.Quikstays.com</span></a><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Accommodation service</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The provision of properly furnished properties for habitation by Users.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Intellectual property</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">All Content included on the Website, unless uploaded by Users, is the property of Quikstays, our affiliates or other relevant third parties. Such content includes texts, graphics, images, audio, video, software, data compilations, page layout, underlying code and software and any other form of information capable of being stored in a computer that appears on or forms part of this Website.&nbsp;</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bookings</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The reservation of accommodation in advance on the website.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="6" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Service</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Accommodation service.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="7" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Party</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Either a User or a Vendor in relevant context.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="8" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Vendor</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">A provider of accommodation services advertising on the website.</span></p>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>


          <p dir="ltr" style="line-height:1.2;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
          <p style="text-align: justify;"><strong>SERVICES</strong></p>
          <p style="text-align: justify;">Quikstays provides an online/virtual marketplace where willing Users in need of accommodation services can connect with Vendors or persons who are willing and able to render accommodation services. Quikstays is not an accommodation service provider and is not responsible for the representations made by Vendors or fulfillment of any agreement between Users and Vendors concluded on the website for the provision of accommodation services or any other services.</p>
          <p style="text-align: justify;">Being an online/virtual marketplace for both Vendors and Users, the website may contain domain links to Vendors&rsquo; online (third party) platforms for further enquiries or engagements similar to the nature of services rendered by Quikstays provided that Quikstays shall not be responsible for the content of such platforms including any loss or damage that may arise as a result of visit to such platforms, except it is expressly stated to the contrary on the Website.</p>
          <p style="text-align: justify;"><strong>ACCEPTABLE/PROHIBITED USES</strong></p>
          <p style="text-align: justify;">Users of the Website shall only be able to do the following:</p>
          <p>For personal and non-commercial use only, to:&nbsp;</p>
          <ol>
          <li>retrieve, display and view the Content on a computer screen.</li>
          </ol>
          <ol>
          <li>download and store the Content in electronic form on a disk (but not on any server or other storage device connected to a network).</li>
          </ol>
          <ol>
          <li>print one copy of the Content.</li>
          </ol>
          <p style="text-align: justify;">You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any Content without first obtaining the prior written permission of Quikstays.</p>
          <p style="text-align: justify;">You acknowledge that you are responsible for any Content you may submit via the Website, including the legality, reliability, appropriateness, originality and copyright of any such Content. You may not upload to, distribute or otherwise publish through the Website any Content that:</p>
          <ol>
          <li>is confidential, proprietary, false, fraudulent, libellous, defamatory, obscene, threatening, invasive of privacy or publicity rights, infringing on intellectual property rights, abusive, illegal or otherwise objectionable;&nbsp;</li>
          </ol>
          <ol>
          <li>may constitute or encourage a criminal offence, violate the rights of any person(s) or organization or otherwise give rise to liability or violate any law; or</li>
          </ol>
          <ol>
          <li>may contain software viruses, political campaigning, chain letters, mass mailings, or any form of "spam." You may not use a false email address or other identifying information, impersonate any person or entity or otherwise mislead as to the origin of any content. You may not upload commercial contents onto the Website.&nbsp;</li>
          </ol>
          <p style="text-align: justify;">You represent and warrant that you own or otherwise control all the rights to the Content you post; that the Content is accurate; that use of the Content you supply does not violate any provision of these terms and conditions and will not cause injury to any person; and that you will indemnify Quikstays against all claims that may result from Content you supply.&nbsp;</p>
          <p>You may not use the Website for any of the following purposes:&nbsp;</p>
          <ol>
          <li>in any way which causes, or may cause, damage to the Website or interferes with any other person's use or enjoyment of the Website;&nbsp;</li>
          <li>in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise objectionable or in breach of any applicable law, regulation, governmental order;&nbsp;</li>
          <li>making, transmitting or storing electronic copies of Content protected by copyright without the permission of the Vendor.&nbsp;</li>
          </ol>
          <p style="text-align: justify;"><strong>PAYMENT AND SECURITY</strong></p>
          <p style="text-align: justify;">Access to the website may require you to create an account requiring a username and password. You are required to ensure the safekeeping of your account details created on the website against all third parties. You are required to keep as confidential these details and should not disclose or share with any third parties.&nbsp;</p>
          <p style="text-align: justify;">Where however you suspect that your account details have been tampered with by third-parties for any purpose whatsoever including hacking or fraud, you shall immediately change your access password to the website or where you believe it necessary request to the customer care that your account created on the website be suspended temporarily or permanently.</p>
          <p style="text-align: justify;">Users may make payments for booking of accommodation services with the Vendors using credit card, Debit card, or via other means they may be prompted to follow on the website. Payment services for booking of accommodation services by Users with Vendors on the website is managed by third-party banking services and payment gateways. Quikstays does not store any payment card details on the website. Payments are handled by the payment gateways with secure encryption and under strict banking standards. Users&rsquo; card details are sent directly to them and cannot be read or accessed by anyone. Users must timeously inform Quikstays of any inaccuracies or discrepancies regarding the use of the payment services.&nbsp;</p>
          <p style="text-align: justify;">Promo offers may be made at the sole discretion of Quikstays and are subject to time validity and other conditions Quikstays may determine.&nbsp;</p>
          <p style="text-align: justify;"><strong>NO LIABILITY</strong></p>
          <p style="text-align: justify;"><span style="color: #353535;">Our liability is hereby limited to the fullest extent permissible by applicable laws and under no circumstances shall we bear liability for the content or the services displayed on the website (including without limitation to any damages caused by or resulting from reliance by a User on any information obtained from our platform), or any damages that result from mistakes, omissions, interruptions, deletion of files or emails, errors, defects, viruses, delays in operation or transmission or any failure of performance whether resulting from acts of god, communication failures, theft, destruction, unauthorised access to Quikstays&rsquo;s records, programs or services or otherwise.</span></p>
          <p style="text-align: justify;"><span style="color: #353535;">We shall also not be liable to the users for indirect, special incidental, consequential, punitive or exemplary damages including without damages for loss of goodwill, lost of profits, loss, theft or corruption of user information or the inability to use the websites or any of its features. The User&rsquo;s sole remedy towards Quikstays is to cease use of the website.</span></p>
          <p style="text-align: justify;"><strong>CONDITIONS FOR USE</strong></p>
          <p style="text-align: justify;">Quikstays grants Users free access to wide range of Vendors offering accommodation services displayed and marketed on the website under the conditions herein stated and for the duration of time as the User may wish to make purchases through our platform.</p>
          <p style="text-align: justify;">Although Quikstays ensure with the use of necessary technical support of the safety of the website, the use of the website and the services marketed thereon is at the User's own expense and risk. In the absence of any breach from Quikstays, the User will at all times bear the risk of loss, theft or damage to any of the Users personal data.</p>
          <p style="text-align: justify;">Quikstays will have the right (but not the obligation), at its sole discretion, to review, edit, limit, refuse or remove Content, products or to limit or refuse the User access to our platform. More specifically in the event the use of the Service, according to us, violates these Terms of Use.</p>
          <p style="text-align: justify;">Quikstays may disclose the User's Personal Data or Content, or other data relating to the use of the virtual/online marketplace, to third parties where it believes, in good faith, that it is necessary to comply with a court order, ongoing judicial proceeding, criminal or civil subpoena, or other legal process or to exercise its constitutional rights of defense against legal claims.</p>
          <p style="text-align: justify;"><strong>INTELLECTUAL PROPERTY</strong></p>
          <p style="text-align: justify;"><span style="color: #353535;">All intellectual property rights regarding the software, documentation or information used or developed by or on behalf of Quikstays during the provision of the virtual marketplace under this Agreement, as well as all data and statistics about the successful bookings made through the website, save and to the exclusion of information supplied or the content uploaded by the User, belong to Quikstays. It shall not be copied, modified, adapted, reverse-engineered, decompiled or otherwise discover the source code of the website or any other software used by Quikstays. Quikstays shall maintain the data and statistics about the sales for each reference period for a period of one (1) year after the end of said reference period or for such period of time as may be prescribed by data regulations.</span></p>
          <p style="text-align: justify;"><strong>APPLICABLE LAW, JURISDICTION AND DISPUTE RESOLUTION</strong></p>
          <p style="text-align: justify;"><span style="color: #353535;">If any issue or claims arise resulting from the use of the website, the Party shall first explore amicable resolutions with </span>Quikstays<span style="color: #353535;"> via email or any of our social media platforms. Upon issuing a complaint, </span>Quikstays<span style="color: #353535;"> shall open dialogue with the Party with the hopes of resolving the issue or claim. Where </span>Quikstays<span style="color: #353535;"> is unable to resolve a Party&rsquo;s claim(s) or the Party remain unsatisfied by amicable settlement offered by Quikstays Limiteed, the Party may apply for the resolution of the claim at a court of competent jurisdiction within the territory of the Federal Republic of Nigeria.</span></p>
          <p style="text-align: justify;"><span style="color: #353535;">Parties agree that the laws of the Federal Republic of Nigeria shall govern these terms and conditions.</span></p>
          <p style="text-align: justify;"><strong>DISCLAIMER</strong></p>
          <p style="text-align: justify;">All accommodation services advertised on the website and services include or otherwise made available to the User are without representations or warranties, express or implied except otherwise specified in writing. Quikstays equally does not warrant the constant availability of the website or that all the information and accommodation service advertised on the website are complete, true and not misleading. Quikstays shall equally not assume responsibility for any contents or information acquired afterwards.</p>
          <p style="text-align: justify;">Nothing on the website constitutes, or is meant to constitute, advice of any kind. All the services offer on the website are governed by laws of the Federal Republic of Nigeria and if a Vendor is unable to deliver such Service due to implications of the laws, Quickstay will refund the amount received from the User for the sale of the services.&nbsp;</p>
          <p style="text-align: justify;">You will be required to enter a valid phone number and electronic mail address while booking accommodation services on the website. By registering your valid phone number with us, you consent to be contacted by Quikstays or the Vendor via phone calls, SMS notifications and or electronic mails for purposes related to the accommodation service booking(s). Quikstays may not use your personal information to initiate any promotional phone calls, electronic mails or SMS'.&nbsp;</p>
          <p>Enquiries and questions may be forwarded via email to: <a href="mailto:support@quikstays.com" target="_blank" rel="noopener">support@quikstays.com</a></p>
          <p><strong><span style="color: #353535;">CANCELLATION, NO-USE AND REFUND POLICY</span></strong></p>
          <p style="text-align: justify;"><span style="color: #353535;">Where a Vendor is unable to deliver the service for which a User has made bookings, Quikstays shall refund the amount paid by the User and be entitled to reclaim the sum refunded from the Vendor.</span></p>
          <p style="text-align: justify;"><span style="color: #353535;">Where a User cancels or fails to utilise the services for which bookings had been made by him/her, refund shall be made by Quikstays subject to a deduction of 10% of the sum of money paid for that booking, provided that Quikstays shall be entitled to reclaim the sum refunded from the Vendor.</span></p>
          <p style="text-align: justify;"><span style="color: #353535;">Refund by Quikstays in any of the above circumstances shall not be an admission of liability on its part as it shall only do so to facilitate smooth transaction on the Website. Vendors and Users shall nonetheless be liable for any failures in fulfilling their obligations under their contract for accommodation services.</span></p>
          <p style="text-align: justify;"><strong>MISCELLANEOUS</strong></p>
          <p style="text-align: justify;">The Party may not transfer any of your rights under these terms and conditions to any other person. Quikstays may transfer our rights under these terms and conditions where we reasonably believe your rights will not be affected.</p>
          <p style="text-align: justify;">These terms and conditions may be varied by Quikstays from time to time. Such revised terms will apply to the Website from the date of publication. Users should check the terms and conditions regularly to ensure familiarity with the current version.</p>
          <p style="text-align: justify;">These terms and conditions contain the whole agreement between the parties relating to its subject matter and supersede all prior discussions, arrangements or agreements that might have taken place in relation to the terms and conditions.&nbsp;</p>
          <p style="text-align: justify;">If any court or competent authority finds that any provision of these terms and conditions (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of these terms and conditions will not be affected.</p>
          <p style="text-align: justify;">Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.</p>`,
        }}
      />
    </Container>
  );
};

export default TermsAndConditions;
