import { ADD_HOTEL_BASIC_INFO_REQUEST, ADD_HOTEL_BASIC_INFO_SUCCESS, ADD_HOTEL_BASIC_INFO_FAILURE, ADD_HOTEL_BASIC_INFO_RESET } from '../types/hotelTypes';

const initState = {
    hotelInfo: [],
    hotelId: "",
    loading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: ""
}

const addHotelReducer = (state = initState, action) => {
    switch(action.type){
        case ADD_HOTEL_BASIC_INFO_REQUEST:
            // console.log('GET HOTEL ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        case ADD_HOTEL_BASIC_INFO_SUCCESS:
            // console.log('GET HOTEL SUCCESS ', action.payload);
            return {
                ...state,
                loading: false,
                hotelInfo: action.payload,
                hotelId: action.payload.data._id,
                success: true,
                error: false,
                successMessage:  action.payload.message,
            }
        case ADD_HOTEL_BASIC_INFO_FAILURE:
            // console.log('GET HOTEL FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                errorMessage: action.payload.response.data
            }
        case ADD_HOTEL_BASIC_INFO_RESET:
            // console.log('GET HOTEL FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                error: false,
                success: false,
                errorMessage: false
            }
        default:
            return state;
    }
}

export default addHotelReducer;