import axios from 'axios';
import { getHotel } from './hotelActions';
import { ADD_RATES_REQUEST, ADD_RATES_SUCCESS, ADD_RATES_FAILURE } from '../types/hotelTypes';

const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const addRatesRequest = () => {
    return {
        type: ADD_RATES_REQUEST
    }
}

const addRatesSuccess = rates => {
    return {
        type: ADD_RATES_SUCCESS,
        payload: rates
    }
}
const addRatesFailure = error => {
    return {
        type: ADD_RATES_FAILURE,
        payload: error
    }
}

export const addRates = (formData) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    return async (dispatch, getState) => {
        dispatch(addRatesRequest());
        return axios.post(`${BASE_URL}/room-rate`, formData, {
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(getHotel(formData.hotel_id));
                dispatch(addRatesSuccess(res.data));
            })
            .catch(error => {
                dispatch(addRatesFailure(error));
            })
    }
}