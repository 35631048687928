import React from 'react';
// import axios from 'axios';
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core';
import _ from 'lodash';
// import FormControl from '@material-ui/core/FormControl';
// import TextField from '@material-ui/core/TextField';
// import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
// import InputLabel from '@material-ui/core/InputLabel';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import Paper from '@material-ui/core/Paper';
// import MenuItem from '@material-ui/core/MenuItem';
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import UploadImage from './UploadImage';
import CircularProgress from '@material-ui/core/CircularProgress';
import RoomInfo from './RoomInfo';
import RoomsList from './RoomsList';
import Amenities from './Amenities';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import { getHotel } from '../../../store/actions/hotelActions';
import { addRoom } from '../../../store/actions/addRoomActions';
import { addCompleted } from '../../../store/actions/addCompletedStepActions';


// function TabContainer(props) {
//     return (
//       <Typography component="div" className="p-2">
//         {props.children}
//       </Typography>
//     );
//   }

// const minimumDescriptionCount = 50;

class AddRooms extends React.Component {
    state = {
        activeTabIndex: 1,
        roomType: "",
        noOfRooms: 1,
        amenities: [],
        maximumOccupancy: 1,
        error: "",
        roomImage: [],
        isRequestLoading: null,
        isRequestError: null,
        isRequestSuccess: null,
        possibleOccupancyList: [1, 2, 3, 4, 5],
        roomsList: [],
        isTheSameRoomFound: false,
        isNoOfRoomsEmpty: false,
        isImageEmpty: false,
        isMaximumOccupancyEmpty: false,
        isFormValidationActivated: false,
        isAmenitiesEmpty: false,
        loading: false

    }

    handleClearForm=()=> {
        this.setState({roomType: "", noOfRooms: 1,amenities: [], roomImage: [], /*roomDescription: "",*/ maximumOccupancy: 1, isTheSameRoomFound: false })
    }

    async componentDidMount(){
        if (!this.props.hotel) {
            this.setState({ loading: true });
            await this.props.getHotel(this.props.hotelId);
            this.setState({ loading: false });
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.hotelRooms !== this.props.hotelRooms){
            if(this.props.isRequestSuccess || this.props.isThereError){
                // console.log('new room found', this.props.hotelRooms)
                this.setState({
                    roomsList: this.props.hotelRooms,
                    isRequestLoading: false
                });
            }
        }
        if(prevProps.isRequestSuccess !== this.props.isRequestSuccess){
            if(this.props.isRequestSuccess || this.props.isThereError){
                this.props.getHotel(this.props.hotelId);
                this.setState({
                    isRequestLoading: false
                });
            }
        }
    }

      handleSnackBarClose = () => {
        this.setState({
            isRequestSuccess: false, 
            isTheSameRoomFound: false, 
            isRequestError: false, 
            isNoOfRoomsEmpty: false,
            isRequestLoading: false, 
        })

    }
    
    // handleTabChange = (event, value) => {
    //     this.setState({ activeTabIndex: value });
    // }
    handleRoomData = (e) => {
        if(e.target.id === "noOfRooms" && e.target.value >= 1){
            this.setState({ isNoOfRoomsEmpty: false });
        }
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeAmenities = (e, val) => {
        if (e.target.checked){
            //append to array
            this.setState({ amenities: [...this.state.amenities, val] })
            // setAmenities(
            //     [...this.state.amenities, val]
            // );

            this.setState({ isAmenitiesEmpty: false });
        } else {
            //remove from array
            let filteredAmenities = this.state.amenities.filter(function(v) {return v!==val})
            this.setState({ amenities: filteredAmenities })
            // setAmenities(
            //     this.state.amenities.filter(function(v) {return v!==val})
            // );
            if(this.state.amenities.length <= 0){
                this.setState({ isAmenitiesEmpty: true });
            }
        }
    }

    removeImage = (index) => {
         const  { roomImage } = this.state;
        let newImages = _.cloneDeep(roomImage);
        console.log(newImages[index])
        if (newImages[index]) {
          newImages.splice(index, 1);
          this.setState({ roomImage: newImages });
        }
    };

    uploadRoomImages = (e) => {
        const  { roomImage } = this.state;
        const newImages = _.cloneDeep(roomImage);

         for (let i = 0; i < e.target.files.length; i++) {
            newImages.push(e.target.files[i])
        }
       
        this.setState({ roomImage: newImages}, ()=> {
          this.setState({ isImageEmpty: false });
        });
    }

    handleOnDragOver = e => {
        e.preventDefault();
    }

    handleOnDrop = e => {
          //prevent the browser from opening the image
          const  { roomImage } = this.state;
          const newImages = _.cloneDeep(roomImage);

      
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            newImages.push(e.dataTransfer.files[i])
        }
          
          e.preventDefault();
          e.stopPropagation();
          this.setState({ roomImage: newImages }, ()=> {
            this.setState({ isImageEmpty: false });
          });
    }

    handleDisplayRoom = (roomType) => {
        // console.log(roomType);
    }
    
    handleSubmit = async (e) =>{
        e.preventDefault();

        if(this.state.amenities.length < 1){
            this.setState({ isAmenitiesEmpty: true });
            return;
        }

        if (this.state.roomImage.length === 0){
          this.setState({ isImageEmpty: true });
          return;
        }

        if(this.state.noOfRooms <= 0 || this.state.noOfRooms === ""){
            this.setState({ isNoOfRoomsEmpty: true });
            return;
        }

        if(this.state.roomDescription === ""){
            this.setState({ isDescriptionEmpty: true, isFormValidationActivated: true });
            return;
        }

        if(this.state.maximumOccupancy < 1){
            this.setState({ isMaximumOccupancyEmpty: true, isFormValidationActivated: true });
            return;
        }

        

        this.setState({ isRequestLoading: true });

        await this.props.getHotel(this.props.hotelId);
        
        for(let i = 0; i < this.props.hotel.rooms.length; i++) {
            if(this.props.hotel.rooms[i].type === this.state.roomType){
                this.setState({ isTheSameRoomFound: true })
                return;
            }
        }
        this.setState({ isTheSameRoomFound: false })
        const formData = new FormData();
        formData.append('type', this.state.roomType);
        formData.append('count', this.state.noOfRooms);
        // formData.append('description', this.state.roomDescription);
        formData.append('maxOccupancy', this.state.maximumOccupancy);
        formData.append('amenities', this.state.amenities.toString());
        formData.append('hotel_id', this.props.hotelId);
        for (let i = 0; i < this.state.roomImage.length; i++) {
            formData.append(`images[${i}]`, this.state.roomImage[i])
        }

        await this.props.addRoom(formData);

        if(this.props.isRequestSuccess){
            this.props.addCompleted(2);
            this.setState({
                isRequestLoading: false,
                isRequestSuccess: true,
          
            });
            this.handleClearForm();
        }

        if(this.props.isThereError){
            this.setState({isRequestLoading: false, isRequestError: true})
        }
    }

    render(){
        const { 
            roomImage,
            roomType,
            noOfRooms,
            roomsList,
            isTheSameRoomFound,
            isRequestLoading,
            isNoOfRoomsEmpty,
            isRequestSuccess,
            isRequestError,
            isImageEmpty,
            isFormValidationActivated,
            possibleOccupancyList,
            isMaximumOccupancyEmpty,
            maximumOccupancy,
            amenities,
            isAmenitiesEmpty,
            loading
        } = this.state;
        const { hotelRooms, errorMessage, successMessage } = this.props;

        return (
            <>
                {loading ? <div className='loadingSpinner'><CircularProgress />  </div> :
                    <div className="container px-lg-5">
                        <form onSubmit={this.handleSubmit}>
                            <div className="row mb-4">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <Typography color="secondary" variant="h5">Add Rooms</Typography>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                                    <Button variant="contained" onClick={this.handleClearForm} color="secondary" size="small" disableElevation>Add New Room</Button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <RoomInfo
                                        handleRoomData={this.handleRoomData}
                                        roomType={roomType}
                                        noOfRooms={noOfRooms}
                                        roomsList={roomsList}
                                        isTheSameRoomFound={isTheSameRoomFound}
                                        isNoOfRoomsEmpty={isNoOfRoomsEmpty}
                                        // roomDescription = { roomDescription }
                                        // minimumDescriptionCount = { minimumDescriptionCount }
                                        isFormValidationActivated={isFormValidationActivated}
                                        possibleOccupancyList={possibleOccupancyList}
                                        isMaximumOccupancyEmpty={isMaximumOccupancyEmpty}
                                        maximumOccupancy={maximumOccupancy}
                                    />
                                    <Amenities handleChangeAmenities={this.handleChangeAmenities} selectedAmenities={amenities} isAmenitiesEmpty={isAmenitiesEmpty} />
                                    <UploadImage deleteHotelImage={this.removeImage} uploadRoomImages={this.uploadRoomImages} chosenImages={roomImage} handleOnDragOver={this.handleOnDragOver} handleOnDrop={this.handleOnDrop} isImageEmpty={isImageEmpty} />
                                    <Button variant="contained" disabled={isRequestLoading} fullWidth color="secondary" type="submit" disableElevation>{isRequestLoading && <CircularProgress size={24} />} {isRequestLoading ? "Saving" : "Save Room"}</Button>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    {hotelRooms &&
                                        <React.Fragment>
                                            <RoomsList rooms={hotelRooms} handleDisplayRoom={this.handleDisplayRoom} />
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                            {/* <Paper square>
                        <div className="d-flex justify-content-between align-items-center">
                            <Tabs
                                value={activeTabIndex}
                                onChange={this.handleTabChange}
                                aria-label="Add Rooms Tab"
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                >
                            <Tab label="Room 1" />
                            <Tab label="Room 2" />
                            </Tabs>
                            <div>
                                <Button variant="contained" onClick={this.handleDialogOpen}>Add New Room</Button>
                            </div>
                        </div>
                    </Paper> */}
                            {/* { activeTabIndex === 0 && <TabContainer>Room One</TabContainer> }
                { activeTabIndex === 1 && <TabContainer> */}
                            {/*<RoomInfo handleRoomData = { this.handleRoomData } roomType={ roomType } noOfRooms = { noOfRooms } roomsList = { roomsList } isTheSameRoomFound = { isTheSameRoomFound } />
                    <UploadImage uploadRoomImages = { this.uploadRoomImages } chosenImages = { roomImage } handleOnDragOver = { this.handleOnDragOver } handleOnDrop={ this.handleOnDrop } />*/}
                            {/* </TabContainer> } */}
                            {/*<Button variant="contained" disabled={isRequestLoading} fullWidth color="secondary" type="submit" disableElevation>{isRequestLoading && <CircularProgress size={24} />} { isRequestLoading ? "Saving" : "Save Room" }</Button>*/}
                        </form>
                        <div className="text-right pt-3 pb-5">
                            <Link to="/add-hotel">
                                <Button variant="contained" className="mr-2" disableElevation>Back</Button>
                            </Link>
                            {isRequestSuccess || (hotelRooms && hotelRooms.length > 0) ?
                                <Link to="/add-hotel/add-rates">
                                    <Button variant="contained" color="primary" disableElevation>Continue</Button>
                                </Link> : ''
                            }
                        </div>

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={isRequestError}
                            autoHideDuration={2000}
                            onClose={this.handleSnackBarClose}
                        >
                            <Alert variant="filled" severity="error">{errorMessage}</Alert>
                        </Snackbar>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={isTheSameRoomFound}
                            autoHideDuration={2000}
                            onClose={this.handleSnackBarClose}
                        >
                            <Alert variant="filled" severity="warning">You have already added this same room type.</Alert>
                        </Snackbar>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={isNoOfRoomsEmpty}
                            autoHideDuration={2000}
                            onClose={this.handleSnackBarClose}
                        >
                            <Alert variant="filled" severity="warning">No of Rooms must be at least 1</Alert>
                        </Snackbar>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={isRequestSuccess && (isRequestLoading === false)}
                            autoHideDuration={2000}
                            onClose={this.handleSnackBarClose}
                        >
                            <Alert variant="filled" severity="success">{successMessage}</Alert>
                        </Snackbar>
                    </div>
                }
                </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addRoom: (formData) => dispatch(addRoom(formData)),
        getHotel: (id) => dispatch(getHotel(id)),
        addCompleted: (data) => dispatch(addCompleted(data)),
    }
}
  
const mapStateToProps  = (state) => {
    return {
      hotel: state.hotel.hotelInfo.data,
      hotelRooms: state.hotel.hotelInfo.data && state.hotel.hotelInfo.data.rooms,
      hotelId: state.addHotelInfo.hotelId || (state.hotel.success && state.hotel.hotelInfo.data._id),
      isThereError: state.addRoomInfo.error,
      isLoading: state.addRoomInfo.loading,
      isRequestSuccess: state.addRoomInfo.success,
      successMessage: state.addRoomInfo.successMessage,
      errorMessage: state.addRoomInfo.errorMessage.message
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddRooms);