import { ADD_RATES_REQUEST, ADD_RATES_SUCCESS, ADD_RATES_FAILURE } from '../types/hotelTypes';

const initState = {
    hotelInfo: [],
    loading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: ""
}

const addRatesReducer = (state = initState, action) => {
    switch(action.type){
        case ADD_RATES_REQUEST:
            // console.log('ADD RATES ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        case ADD_RATES_SUCCESS:
            // console.log('ADD RATES SUCCESS ', action.payload);
            return {
                ...state,
                hotelInfo: action.payload,
                success: true,
                error: false,
                successMessage:  action.payload.message,
            }
        case ADD_RATES_FAILURE:
            // console.log('ADD RATES FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default addRatesReducer;