import React from 'react';

import { Typography } from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddRooms from './add-rooms/AddRooms';
import Rates from './rates/Rates';
import General from './general/General';

function getSteps() {
  return ['General', 'Rates'];
}

const AvailabilityDialog =({ isOpen, handleDialogClose, selectedRoom })=> {
	const steps = getSteps();

	const [activeStep, setActiveStep] = React.useState(0);

	const handleNext = () => {
	 // if(values.name === "" || values.streetAddress === "" || values.city === "" || values.country === "" || values.phoneNo === "" || values.description === "" || values.description.length < minimunDescriptionCount){
	 //     setFormFilledState(false);
	 //     setFormValidationState(true);
	 // } else {
	     // setFormFilledState(true);
	     setActiveStep((prevActiveStep) => prevActiveStep + 1);
	 // }
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// const handleReset = () => {
	//  setActiveStep(0);
	// };

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	return (
       <Dialog open={isOpen} fullWidth maxWidth="lg" onClose={handleDialogClose} aria-labelledby="Booking-Confirmation-Dialog">
           <DialogTitle className="primaryBgColor text-white">
               <div className="d-flex justify-content-between align-items-center">
                   <Typography variant="h6">Add New Room Details</Typography>
                   <IconButton onClick={handleDialogClose} color="secondary">
                       <CloseIcon />
                   </IconButton>
               </div>
           </DialogTitle>
           <DialogContent>
           		<div className="container p-0">
           			<div className="row">
	           			<div className="col-12 p-0">
	        				   <Stepper activeStep={activeStep} alternativeLabel>
			                 {steps.map((label, index) => (
			                   <Step key={label}>
			                     <StepButton
			                       onClick={handleStep(index)}
			                       // completed={isStepComplete(index)}
			                       // {...buttonProps}
			                     >
			                       <StepLabel>{label}</StepLabel>
			                     </StepButton>
			                   </Step>
			                 ))}
			               </Stepper>
	           			</div>
           			</div>
           			<div className="row">
           				<div className="col-12 p-0">
			              	<div className="pt-2 mb-3">
			                { activeStep === 0 &&
                        <General selectedRoom={selectedRoom} />
			                }

			                { activeStep === 1 &&
			                  <Rates />
			                }

                      { activeStep === 2 &&
            					   <AddRooms />
                      }
				            </div>
           				</div>
           			</div>
           		</div>


               <div className="d-flex justify-content-end mb-5">
                  <Button
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    >
                    Back
                  </Button>
                  {/*
                    activeStep === steps.length - 1 && 
                    <div>
                      <Button className={classes.wrapper} variant="contained" disabled={isRequestLoading || props.isLoading} disableElevation color="secondary" type="submit">{isRequestLoading && <CircularProgress size={24} className={classes.buttonProgress} />} {(isRequestLoading || props.isLoading) ? "Saving" : "Save" }</Button>
                    </div>
                  */}
                  {/* <Button variant="contained" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button> */}
                  {
                    activeStep < 1 &&
                      <Button variant="contained" disableElevation onClick={handleNext}>
                        <Typography color="primary">Next</Typography>
                      </Button>
                  }
                  {
                    activeStep === 1 &&
                    <Button variant="contained" color="primary" disableElevation onClick={handleDialogClose}>
                      Finished
                    </Button>
                  }
              	</div>
           </DialogContent>
           {/* <DialogActions>
               <Button onClick={this.handleDialogClose} color="primary">
                   Cancel
               </Button>
               <Button onClick={this.handleDialogClose} color="primary">
                   Subscribe
               </Button>
           </DialogActions> */}
       </Dialog>
	)
}

export default AvailabilityDialog;