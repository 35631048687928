import React from 'react';
// import axios from 'axios';
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
// import CloudUploadIcon from '@material-ui/icon/CloudUploadIcon';
// import Paper from '@material-ui/core/Paper';
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import RoomAvailability from './RoomAvailability';
import { connect } from 'react-redux';
import { addAvailability } from '../../../../store/actions/addAvailabilityActions';
import { getHotel } from '../../../../store/actions/hotelActions';

function TabContainer(props) {
    return (
      <Typography component="div" className="py-2">
        {props.children}
      </Typography>
    );
}

class General extends React.Component {
    state = {
        activeTabIndex: 0,
        singleRoomAvailabilityData: "",
        doubleRoomAvailabilityData: "",
        data: []
    }

    componentDidMount = () => {
        this.props.getHotel(this.props.hotelId)
    }

    onGetSingleRoomAvailability=(data)=> {
        this.setState({ singleRoomAvailabilityData: data});
        this.setState(prevState => ({ data: [...prevState.data, data ]}));
    }

    onGetDoubleRoomAvailability=(data)=> {
        this.setState({ doubleRoomAvailabilityData: data});
        this.setState(prevState => ({ data: [...prevState.data, data ]}));
    }

    componentDidUpdate=(prevProps)=> {
        if(prevProps !== this.props){
            // console.log('a');
        }
        // console.log(this.props.location.state.hotelId);
    }

    handleTabChange = (event, value) => {
        this.setState({ activeTabIndex: value });
    }
    // handleSubmit = (e) => {
    //     e.preventDefault();

    //     this.props.history.push('/confirm-details')
    // }

    render(){
        const { activeTabIndex } = this.state;
        const { hotelRooms, selectedRoom } = this.props;

        if(this.props.hotelRooms){
            return (
                <div className="container-fluid px-lg-5">
                    <div className="row">
                        {/*<div className="col-12 mb-2">
                            <Typography variant="h5" color="secondary">Create availability for each room type</Typography>
                        </div>*/}
                        <div className="col-12">
                            {/*<Paper square elevation={0}>
                                <Tabs
                                    value={activeTabIndex}
                                    onChange={this.handleTabChange}
                                    aria-label="Add Rates Tab"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                {hotelRooms.map((item, index) => (
                                    <Tab key={index} label={item.type} />
                                ))}
                                </Tabs>
                            </Paper>*/}
                            { hotelRooms.length === 0 && 
                                <div className="py-3">
                                    <Alert variant="filled" severity="warning">No rooms found for this hotel. Please click <Link to="/add-rooms">here</Link> to add new rooms first.</Alert>
                                </div>
                            }
                            { hotelRooms.map((item, index) => (
                                <div key={index}>{ activeTabIndex === index && 
                                    <TabContainer>
                                        <RoomAvailability selectedRoom={selectedRoom} roomData = {item} onSubmitSingleRoomAvailability={this.onGetSingleRoomAvailability} />
                                    </TabContainer> }
                                </div>
                            ))}
                            {/* { activeTabIndex === 1 && <TabContainer><DoubleRoomAvailability onSubmitDoubleRoomAvailability={this.onGetDoubleRoomAvailability} /></TabContainer> } */}
                        </div>
                    </div>
                    
                    {/*<div className="d-flex justify-content-end pb-4 mb-5">
                        <Link to="/add-hotel/add-rates" className="mr-2">
                            <Button>Back</Button>
                        </Link>
                        { this.props.isRequestSuccess &&
                            <Link to="/add-hotel/confirm-details">
                                <Button variant="contained" color="primary" disableElevation>Continue</Button>
                            </Link>
                        }
                    </div>*/}

                    {/*
                      this.props.isThereError &&
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        open={this.props.isThereError}
                        autoHideDuration={2000}
                        // onClose={this.handleSnackbarClose}
                      >
                        <Alert variant="filled" severity="error">{ this.props.errorMessage }</Alert>
                      </Snackbar>
                    */}
                    {
                      this.props.isRequestSuccess &&
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        open={this.props.isRequestSuccess}
                        autoHideDuration={2000}
                        // onClose={this.handleSnackbarClose}
                      >
                        <Alert variant="filled" severity="success">{ this.props.successMessage }</Alert>
                      </Snackbar>
                    }
                </div>
            )
        } else {
            return (
                <div className="container-fluid px-lg-5">
                    <h4>You have not registered an hotel yet</h4>
                    <Typography variant="body1" component="span">Kindly click</Typography>
                    <Link to="/add-hotel">here</Link> to begin the registration process.
                </div>
            )
        }
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
      addAvailability: (formData) => dispatch(addAvailability(formData)),
      getHotel: (id) => dispatch(getHotel(id))
    }
}
  
const mapStateToProps  = (state) => {
    return {
      hotel: state.hotel.hotelInfo.data,
      hotelId: state.hotel.hotelInfo.data._id,
      hotelRooms: state.hotel.hotelInfo.data.rooms || [],
      isThereError: state.addAvailabilityInfo && state.addAvailabilityInfo.error,
      isLoading: state.addAvailabilityInfo && state.addAvailabilityInfo.loading,
      isRequestSuccess: state.addAvailabilityInfo && state.addAvailabilityInfo.success,
      successMessage: state.addAvailabilityInfo && state.addAvailabilityInfo.successMessage,
      errorMessage: state.addAvailabilityInfo && state.addAvailabilityInfo.errorMessage.message
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(General);




// class DoubleRoomAvailability extends React.Component {
//     state = {
//         daysList: [
//             { id: 1, name: "Mondays" },
//             { id: 2, name: "Tuesdays" },
//             { id: 3, name: "Wednesdays" },
//             { id: 4, name: "Thursday" },
//             { id: 5, name: "Fridays" },
//             { id: 6, name: "Saturdays" },
//             { id: 7, name: "Sundays" },
//         ],
//         timeList: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
//         otherTimeList: [
//             { name: 'All Day', start: '00:00', end: '23:30' },
//             { name: 'Half Day (AM)', start: '00:00', end: '12:00' },
//             { name: 'Half Day (PM)', start: '12:00', end: '23:30' },
//             { name: 'Day Use', start: '8:00', end: '17:00' },
//         ],
//         excludedDays: [],
//         startPeriod: "",
//         endPeriod: "",
//         availabilityStartTime: "",
//         availabilityEndTime: "",
//         data: null
//     }

//     handleAddDays = (e) => {
//         console.log(e.target.value)
//         let val = e.target.value;
//         if (e.target.checked){
//           //append to array
//             if(e.target.value !== ""){
//                 this.setState(prevState => ({ excludedDays: [...prevState.excludedDays, val ]}));
//             }
//         } else {
//             //remove from array
//             let newDays = this.state.excludedDays.filter(function(v) {return v!== e.target.value});
//             this.setState({ excludedDays: newDays });
//         }
//     }

//     handleChangePeriod = (e) => {
//         console.log(e.target.value)
//         this.setState({ [e.target.id]: e.target.value });
//     }

//     handleSelectAvailableTime=(time)=>{
//         console.log(time)
//         this.setState({ availabilityStartTime:time });
//     }
//     handleSubmit =(e)=> {
//         e.preventDefault();

//         let data = {
//             id: "5fa9036a9935db36d567d33e",
//             period_start_data: this.state.startPeriod,
//             period_end_data: this.state.endPeriod,
//             days_excluded: this.state.excludedDays,
//             availability_start_time: this.state.availabilityStartTime,
//             availability_start_time: "8"
//         }
        

//         this.setState({ data: data }, () =>{
//             console.log(data);
//             this.props.onSubmitDoubleRoomAvailability(this.state.data);
//         })
//     }

//     render() { 
//         const { daysList, timeList, otherTimeList, availabilityStartTime } = this.state;
//         return (
//             <div className="container-fluid p-0">
//                 <form onSubmit={this.handleSubmit}>
//                     <div className="row mb-2">
//                         <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
//                             <Typography>Period</Typography>
//                         </div>
//                         <div className="col-sm-12 col-md-10 col-lg-6 col-xl-5 d-flex justify-content-between">
//                             <div className="d-flex flex-column mr-1">
//                                 <Typography variant="caption">From</Typography>
//                                 <input type="date" className="form-control" id="startPeriod" onChange={this.handleChangePeriod} />
//                             </div>
//                             <div className="d-flex flex-column">
//                                 <Typography variant="caption">To</Typography>
//                                 <input type="date" className="form-control" id="endPeriod" onChange={this.handleChangePeriod} />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-4 col-xl-5">
//                             <Alert severity="info">
//                                 <Typography variant="caption">
//                                     These days will be made available for the guests and can always be updated
//                                 </Typography>
//                             </Alert>
//                         </div>
//                     </div>
//                     <div className="row mb-3">
//                         <div className="col-12">
//                             <Typography variant="body1">All days within this period except</Typography>
//                         </div>
//                         <div className="col-12">
//                             <div className="btn-group-toggle" data-toggle="buttons">
//                                 { daysList.map((item, index) => (
//                                     <label className="btn btn-secondary mr-1 mb-1 active" key={index}>
//                                         <input type="checkbox" onChange={this.handleAddDays} value={item.name} /> {item.name}
//                                     </label>
//                                 ))}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="row mb-3">
//                         <div className="col-12">
//                             <Typography variant="body1">Please select available time for each day</Typography>
//                         </div>
//                         <div className="col-12">
//                             <div className="btn-group-toggle" data-toggle="buttons">
//                                 { timeList.map((item, index) => (
//                                     <label className="btn btn-secondary mr-1 mb-1 active" key={index}>
//                                         <input type="checkbox" onChange={()=>this.handleSelectAvailableTime(item)} checked /> {item}
//                                     </label>
//                                 ))}
//                             </div>
//                             {/* <div className="btn-group-toggle mt-3" data-toggle="buttons"> */}
//                             <div className="row">
//                                 { otherTimeList.map((item, index) => (
//                                     <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
//                                         <label className={`btn btn-block btn-secondary mr-1 mb-1 active`} key={index}>
//                                             <input type="checkbox" onChange={()=>this.handleSelectAvailableTime(item)} /> {item.name}
//                                         </label>
//                                         <Typography component="div" className="text-center" variant="caption">{item.start} - {item.end}</Typography>
//                                     </div>
//                                 ))}
//                                 </div>
//                             {/* </div> */}
//                         </div>
//                         <div className="col-12">
//                             <Button variant="contained" fullWidth color="primary" type="submit">Save</Button>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         );
//     }
// }