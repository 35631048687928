import React from 'react';
import { Route, BrowserRouter as Router, /*Redirect,*/ Switch } from 'react-router-dom';
import Login from './auth/SignIn';
import SetPassword from './auth/SetPassword';
import AddHotel from './add-hotel/AddHotel';
import Terms from './TermsAndConditions';
import DashboardIndex from './dashboard/Dashboard';
// import { connect } from 'react-redux';

// import Settings from './Settings';
import ProtectedRoute from './ProtectedRoute';

class Home extends React.Component {
    state = {  }
    render() { 
        return (
            <Router>
                <Switch>
                    <ProtectedRoute exact path="/" component={AddHotel} />
                    {/*<ProtectedRoute exact path="/" component={AddHotel} render={() => {
                        return (
                            <Redirect to="/add-hotel" />
                        )
                    }} >*/}
                    <Route path="/login" component={Login} />
                    <Route path="/set-password/:token" component={SetPassword} />
                    <Route path="/terms" component={Terms} />
                    <ProtectedRoute path="/add-hotel" component={AddHotel} />
                    <ProtectedRoute path="/dashboard" component={DashboardIndex} />

                    <Route path="*">
                        <div>Page Not Found</div>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

// const mapStateToProps = (state) => {
//     console.log(state.auth)
//     return {
//         token: state.auth.token,
//     }
// }

export default Home;