import { CONFIRM_DETAILS_REQUEST, CONFIRM_DETAILS_SUCCESS, CONFIRM_DETAILS_FAILURE } from '../types/hotelTypes';

const initState = {
    hotelInfo: [],
    hotelId: "",
    loading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: ""
}

const confirmDetailsReducer = (state = initState, action) => {
    switch(action.type){
        case CONFIRM_DETAILS_REQUEST:
            // console.log('GET HOTEL ', action.payload);
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            }
        case CONFIRM_DETAILS_SUCCESS:
            // console.log('GET HOTEL SUCCESS ', action.payload);
            return {
                ...state,
                hotelInfo: action.payload,
                hotelId: action.payload.data._id,
                success: true,
                error: false,
                successMessage:  action.payload.message,
            }
        case CONFIRM_DETAILS_FAILURE:
            console.log('GET HOTEL FAILURE ', action.payload);
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default confirmDetailsReducer;