import axios from 'axios';
import { GET_HOTEL_REQUEST, GET_HOTEL_SUCCESS, GET_HOTEL_FAILURE } from '../types/hotelTypes';

const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

// let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));
// console.log(AUTH_TOKEN)
// axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;

// let AUTH_TOKEN = localStorage.getItem("user_token");
// console.log(AUTH_TOKEN)

// const config = {
//     headers: { Authorization: `Bearer ${AUTH_TOKEN}` }
// };

const getHotelRequest = () => {
    return {
        type: GET_HOTEL_REQUEST
    }
}

export const getHotelSuccess = hotel => {
    return {
        type: GET_HOTEL_SUCCESS,
        payload: hotel
    }
}

const getHotelFailure = error => {
    return {
        type: GET_HOTEL_FAILURE,
        payload: error
    }
}

export const getHotel = (hotelId) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    if (!hotelId) {
        hotelId = JSON.parse(localStorage.getItem("QuickstayhotelId"));
    }

    return async (dispatch, getState) => {
        dispatch(getHotelRequest());
        return axios.get(`${BASE_URL}/hotels/${hotelId}`, {
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(getHotelSuccess(res.data));
                return res.data;
            })
            .catch(error => {
                dispatch(getHotelFailure(error));
            })
    }
}