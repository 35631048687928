import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { connect } from 'react-redux';
import { addAvailability, resetAvailability } from '../../../store/actions/addAvailabilityActions';
import { addCompleted } from '../../../store/actions/addCompletedStepActions';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function TabContainer(props) {
  return (
    <Typography component="div" className="p-2">
      {props.children}
    </Typography>
  );
}

const today = new Date();
const daysList= [
    { id: 1, name: "Mondays" },
    { id: 2, name: "Tuesdays" },
    { id: 3, name: "Wednesdays" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Fridays" },
    { id: 6, name: "Saturdays" },
    { id: 7, name: "Sundays" },
];
const timeList= ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];
const otherTimeList= [
    { name: 'All Day', start: '00:00', end: '23:30' },
    { name: 'Half Day (AM)', start: '00:00', end: '12:00' },
    { name: 'Half Day (PM)', start: '12:00', end: '23:30' },
    { name: 'Day Use', start: '08:00', end: '17:00' },
];

class RoomAvailability extends React.Component {
    state = {
        activeTabIndex: 0,
        excludedDays: this.props.roomData.days_excluded || [],
        period_start_date: this.props.roomData.period_start_date || new Date(),
        period_end_date: this.props.roomData.period_end_date || new Date(),
        availabilityTitle: "",
        availabilityStartTime:  this.props.roomData.availability_start_time || '',
        availabilityEndTime:  this.props.roomData.availability_end_time || '',
        data: null,
        dayExcluded: false,
        isRequestLoading: null,
        isQuickOptionsSelected: true,
        includedTimes: [],
        isRequestSuccess: false,
        isRequestFailed: false,
        error: ''
    }

    handleCloseSnackBar = () => {
         this.setState({ isRequestSuccess: false, isRequestFailed:false });
    }
    
    handleTabChange = (event, value) => {
     this.setState({ activeTabIndex: value });
    }

    handleAddDays = (e) => {
        let val = e.target.value;
        if (e.target.checked){
          //append to array
            if(e.target.value !== ""){
                this.setState(prevState => ({ excludedDays: [...prevState.excludedDays, val ]}));
                // this.setState({ dayExcluded: e.target.value })
            }
        } else {
            //remove from array
            let newDays = this.state.excludedDays.filter(function(v) {return v!== e.target.value});
            this.setState({ excludedDays: newDays });
            // this.setState({ dayExcluded: false })
        }
    }
    componentDidUpdate(prevProps){
        // console.log(prevProps)
        // console.log(this.props)
        if(prevProps.hotelRooms !== this.props.hotelRooms){
            if(this.props.isRequestSuccess || this.props.isThereError){
                // console.log('new room found', this.props.hotelRooms)
                // console.log(this.props)
                this.setState({
                    isRequestLoading: false
                });
            }
        }
        if(prevProps.isRequestSuccess !== this.props.isRequestSuccess){
            if(this.props.isRequestSuccess || this.props.isThereError){
                // this.props.getHotel(this.props.hotelId);
                this.setState({
                    isRequestLoading: false
                });
            }
        }
    }

    handleChangePeriod = (e, date) => {
        if (date === 'period_start_date') {
             this.setState({ period_start_date: new Date(e).toISOString()});
        } else {
            this.setState({ period_end_date: new Date(e).toISOString()});
        }
       
    }
    
    handleSelectAvailableStartTime=(time)=>{
        this.setState({
            isQuickOptionsSelected: false,
            availabilityTitle: "",
            availabilityStartTime:time
        });
    }
    handleSelectAvailableEndTime=(time)=>{
        this.setState({
            isQuickOptionsSelected: false,
            availabilityTitle: "",
            availabilityEndTime:time
        });
    }
    handleSelectOtherTime =(item)=> {
        let arr = [];
        let start = "";
        let end = "";

        ///get the index of the start time
        for(let i = 0; i < timeList.length; i++){
            if(timeList[i] === item.start){
                start = i;
                break;
            }
        }
        ///get the index of the end time
        for(let i = 0; i < timeList.length; i++){
            if(timeList[i] === item.end){
                end = i;
                break;
            }
        }
        ///then push new indexes into an array depending on the start and end indexes gotten.
        for(let i = start; i < end; i++){
            arr.push(i);
        }

        this.setState({
            isQuickOptionsSelected: true,
            includedTimes: arr,
            availabilityTitle: item.name,
            availabilityStartTime: item.start,
            availabilityEndTime: item.end
        });
    }

    handleResetTime =()=> {
        this.setState({
            availabilityTitle: "",
            availabilityStartTime: "",
            availabilityEndTime: "",
            isQuickOptionsSelected: false,
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isRequestLoading: true });
        let arrayOfAvailability = [];

        let roomAvailabilityToUpload = {
            id: this.props.roomData._id,
            period_start_date: this.state.period_start_date,
            period_end_date: this.state.period_end_date,
            days_excluded: this.state.excludedDays,
            availability_start_time: this.state.availabilityStartTime,
            availability_end_time: this.state.availabilityEndTime,
        }
        arrayOfAvailability.push(roomAvailabilityToUpload);

        if (this.state.availabilityStartTime && this.state.availabilityEndTime && this.state.availabilityStartTime > this.state.availabilityEndTime ) {
            this.setState({ error:'Availability end time should be higher then availability start time', isRequestFailed: true, isRequestLoading: false  })
            return;
        }

        // console.log(this.props.roomData)
        let data = {
            hotel_id: this.props.hotelId,
            data: arrayOfAvailability,
        };

        await this.props.addAvailability(data);

        if(this.props.isRequestSuccess){
            this.props.addCompleted(4);
            this.props.setIsSuccess();
            this.setState({ isRequestSuccess: true, error: null });

        }
        // console.log(res);
        // console.log(this.props);
        if(this.props.isThereError){
           this.setState({isRequestFailed: true, error: null })
        }

        this.setState({ isRequestLoading: false });

        setTimeout(() => this.props.resetAvailability(), 1000);
        
        // this.setState({ data: data }, () =>{
        //     console.log(data);
        //     this.props.onSubmitSingleRoomAvailability(this.state.data);
        // })
    }

    render() {
        const { successMessage, errorMessage } = this.props;
        const {
            activeTabIndex,
            period_start_date,
            period_end_date,
            availabilityStartTime,
            availabilityEndTime,
            isQuickOptionsSelected,
            includedTimes,
            excludedDays,
            isRequestLoading,
            isRequestSuccess,
            isRequestFailed,
            error
        } = this.state;

        return (
            <div className="container-fluid p-0">
                <form onSubmit={this.handleSubmit}>
                    <div className="row align-items-end mb-4">
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2 mb-2">
                            <Typography variant="h6">Period</Typography>
                        </div>
                        <div className="col-sm-12 col-md-10 col-lg-6 col-xl-5 mb-2 d-flex justify-content-between">
                            <div className="flex-fill d-flex flex-column mr-1">
                                <Typography variant="caption">From</Typography>
                                <DatePicker className="form-control" selected={ new Date(period_start_date)} id="period_start_date" dateFormat="dd/MM/yyyy"  minDate={ new Date(moment(today).add(0, 'days').format("YYYY-MM-DD")) } required onChange={date => this.handleChangePeriod(date, 'period_start_date')}/>
                                {/* <input type="date" className="form-control" value={moment(period_start_date).format("YYYY-MM-DD") } id="period_start_date" onChange={this.handleChangePeriod} min={ moment(today).add(0, 'days').format("YYYY-MM-DD") } placeholder="MM/DD/YYYY" required /> */}
                            </div>
                            <div className="flex-fill d-flex flex-column">
                                <Typography variant="caption">To</Typography>
                                <DatePicker className="form-control" selected={ new Date(period_end_date)} id="period_start_date" dateFormat="dd/MM/yyyy"  minDate={ new Date(moment(today).add(1, 'days').format("YYYY-MM-DD")) } required onChange={date => this.handleChangePeriod(date, 'period_end_date')}/>
                                {/* <input type="date" className="form-control" value={moment(period_end_date).format("YYYY-MM-DD")} id="period_end_date" onChange={this.handleChangePeriod} min={ moment(today).add(1, 'days').format("YYYY-MM-DD") } placeholder="MM/DD/YYYY" required /> */}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-5">
                            <Alert severity="info">
                                <Typography variant="caption">
                                    These days will be made available for the guests and can always be updated
                                </Typography>
                            </Alert>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 mb-2">
                            <Typography variant="h6" color="textSecondary">All days within this period <Typography variant="h6" component="span" className="font-weight-bold">except</Typography></Typography>
                        </div>
                        <div className="col-12">
                            <div className="btn-group-toggle" data-toggle="buttons">
                                { daysList.map((item, index) => (
                                    <label className={`btn ${ excludedDays.includes(item.name) ? 'btn-secondary' : 'secondaryBgColor' } mr-1 mb-1 active`} key={index}>
                                        <input type="checkbox" onChange={this.handleAddDays} value={item.name} /> {item.name}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 d-flex mt-2">
                            <div className="mr-5">
                                <FiberManualRecordIcon color="secondary" /> Included
                            </div>
                            <div>
                                <FiberManualRecordIcon className="text-secondary" /> Not Included
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 mb-2">
                            <Typography variant="h6" color="textSecondary">Please select available time for each day</Typography>
                        </div>
                        <div className="col-12">
                            <Paper square elevation={0}>
                              <Tabs
                                    value={activeTabIndex}
                                    onChange={this.handleTabChange}
                                    aria-label="Availability Time Tab"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                             >
                                <Tab label="Start Time" />
                                <Tab label="End Time" />
                              </Tabs>
                            </Paper>
                            { activeTabIndex === 0 &&
                                <TabContainer>
                                    <div className="btn-group-toggle mb-4" data-toggle="buttons">
                                        { timeList.map((item, index) => (
                                            <label className={`btn ${ isQuickOptionsSelected && (includedTimes.includes(index) ? 'secondaryBgColor' : '') } ${ 
                                                    ((availabilityStartTime === item || availabilityEndTime === item) ? 'secondaryBgColor' : 'btn-secondary') } mr-1 mb-1 active`} key={index}>
                                                <input type="checkbox" onChange={()=>this.handleSelectAvailableStartTime(item)} /> {item}
                                            </label>
                                        ))}
                                    </div>
                                </TabContainer>
                            }
                            { activeTabIndex === 1 &&
                                <TabContainer>
                                    <div className="btn-group-toggle mb-4" data-toggle="buttons">
                                        { timeList.map((item, index) => (
                                            <label className={`btn ${ isQuickOptionsSelected && (includedTimes.includes(index) ? 'secondaryBgColor' : '') } ${ 
                                                    ((availabilityEndTime === item || availabilityStartTime === item) ? 'secondaryBgColor' : 'btn-secondary') } mr-1 mb-1 active`} key={index}>
                                                <input type="checkbox" onChange={()=>this.handleSelectAvailableEndTime(item)} /> {item}
                                            </label>
                                        ))}
                                    </div>
                                </TabContainer>
                            }
                        </div>
                        <div className="col-12">
                            <Button variant="contained" onClick={ this.handleResetTime } disableElevation>Reset</Button>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <Typography variant="h6" color="textSecondary">Or Select a quick option below:</Typography>
                            <div className="row mt-2">
                            { otherTimeList.map((item, index) => (
                                <div className="col-md-6 col-lg-3 col-xl-3 col-6 mb-3" key={index}>
                                    <label className={`btn btn-block ${ this.state.availabilityTitle === item.name ? 'secondaryBgColor' : 'btn-secondary'} mr-1 mb-1 active`}>
                                        <input type="radio" value={item.name} onChange={()=>this.handleSelectOtherTime(item, index)} checked={this.state.availabilityTitle === item.name} /> {item.name}
                                    </label>
                                    <Typography component="div" className="text-center" variant="caption">{item.start} - {item.end}</Typography>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>

                    <Button variant="contained" disabled={isRequestLoading} fullWidth color="secondary" type="submit" disableElevation>{isRequestLoading && <CircularProgress size={24} />}{ isRequestLoading ? "Saving" : "Save" }</Button>
                </form>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={isRequestSuccess}
                    autoHideDuration={2000}
                    onClose={this.handleCloseSnackBar}
                    // onClose={()=> this.setState({ isRequestLoading: false })}
                    >
                    <Alert variant="filled" severity="success">{ successMessage }</Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={isRequestFailed}
                    autoHideDuration={2000}
                    onClose={this.handleCloseSnackBar}
                    // onClose={()=> this.setState({ isRequestLoading: false })}
                    >
                    <Alert variant="filled" severity="error">{ errorMessage || error }</Alert>
                </Snackbar>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addAvailability: (formData) => dispatch(addAvailability(formData)),
      addCompleted: (data) => dispatch(addCompleted(data)),
      resetAvailability: (data) => dispatch(resetAvailability()),
    }
}

const mapStateToProps  = (state) => {
    return {
      hotel: state.hotel.hotelInfo.data,
      hotelId: state.hotel.hotelInfo.data._id,
      hotelRooms: state.hotel.hotelInfo.data.rooms || [],
      isThereError: state.addAvailabilityInfo && state.addAvailabilityInfo.error,
      isLoading: state.addAvailabilityInfo && state.addAvailabilityInfo.loading,
      isRequestSuccess: state.addAvailabilityInfo && state.addAvailabilityInfo.success,
      successMessage: state.addAvailabilityInfo && state.addAvailabilityInfo.successMessage,
      errorMessage: state.addAvailabilityInfo && state.addAvailabilityInfo.errorMessage.message
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomAvailability)