import axios from 'axios';
import { CONFIRM_DETAILS_REQUEST, CONFIRM_DETAILS_SUCCESS, CONFIRM_DETAILS_FAILURE } from '../types/hotelTypes';

const BASE_URL = "https://quikstays-partner-prd.azurewebsites.net/api/v1";

const confirmDetailsRequest = () => {
    return {
        type: CONFIRM_DETAILS_REQUEST
    }
}

const confirmDetailsSuccess = hotel => {
    return {
        type: CONFIRM_DETAILS_SUCCESS,
        payload: hotel
    }
}
const confirmDetailsFailure = error => {
    return {
        type: CONFIRM_DETAILS_FAILURE,
        payload: error
    }
}

export const confirmDetails = (hotelId) => {
    let AUTH_TOKEN = JSON.parse(localStorage.getItem("user_token"));

    return async (dispatch, getState) => {
        dispatch(confirmDetailsRequest());
        // console.log(formData.entries());
        return axios.post(`${BASE_URL}/complete-hotel/${hotelId}`, {}, {
            headers: {
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        })
            .then(res => {
                dispatch(confirmDetailsSuccess(res.data));
            })
            .catch(error => {
                dispatch(confirmDetailsFailure(error));
            })
    }
}